<template>
    <v-card height="100%">
        <v-card-text>
            <v-row class="center" justify="space-around">
                <v-col cols="12" sm="8" align-self="center">
                    <label :style="themeFont" :class="$vuetify.breakpoint.smAndDown ? 'body-1 font-weight-medium' : 'subtitle'">{{title}}</label>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-select :items="filters" label="Duration" v-model="filter" @change="filterChanged($event)"></v-select>
                </v-col>
            </v-row>
            <v-data-table class="mt-2" :headers="employeePerformance" :items="employeePerformanceStats">
                <template v-slot:item="{ item }">
                    <tr v-if="item.name">
                        <td @click="findTaskFor(item._id)" style="cursor: pointer">{{ item.name }}</td>
                        <td align="right">{{ item.total }}</td>
                        <td align="right">{{ item.completed }}</td>
                        <td align="right">{{ item.pending }}</td>
                        <td align="right">{{ item.onTime }}</td>
                        <td align="right">{{ item.beforeTime }}</td>
                        <td align="right">{{ item.overDue }}</td>
                        <td align="right">{{ item.completed > 0 ?
                                Math.round(((item.onTime + item.beforeTime) / item.total) * 100) + "%" : 0
                        }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import moment from "moment";
export default {
    data() {
        return {
            employeePerformanceStats: [],
            title: "Employee Overall Performance",
            filter: "currentMonth",
            filters: [
                { text: 'Overall', value: "overall" },
                { text: "Current month", value: "currentMonth" },
                { text: "Last month", value: "lastMonth" },
                { text: "Last 3 months", value: "last3Months" },
                { text: "Last 6 months", value: "last6Months" }
            ],
            employeePerformance: [{
                text: "Name",
                value: "name",
            },
            {
                text: "Total",
                value: "total",
                align: "right"
            },
            {
                text: "Completed",
                value: "completed",
                align: "right"
            },
            {
                text: "Pending",
                value: "pending",
                align: "right"
            },
            {
                text: "On Time",
                value: "onTime",
                align: "right"
            },
            {
                text: "Before Time",
                value: "beforeTime",
                align: "right"
            },
            {
                text: "Over Due",
                value: "overdue",
                align: "right"
            },
            {
                text: "Efficiency",
                align: "right"
            }]
        }
    },
    mounted() {
        this.filterChanged(this.filter);
    },
    methods: {
        async init() {
            this.employeePerformanceStats = await this.getItem(
                appConstants.STATS_API + "/employee-performance")
        },
        async filterChanged(event){
            let dateRange = {}
            switch (event) {
                case "currentMonth":
                    dateRange = this.computeDateRange(0, 0, 'month');;break
                case "lastMonth":
                    dateRange = this.computeDateRange(-1, -1, 'month');break
                case "last3Months":
                    dateRange = this.computeDateRange(-3, -1, 'month');break
                case "last6Months":
                    dateRange = this.computeDateRange(-6, -1, 'month');break
                default:
                    dateRange = undefined;break
            }
            let query = ""
            if(dateRange){
                this.title = `Emplooyee performance from ${dateRange.startDateFormatted} to ${dateRange.endDateFormatted}`
                query = `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
            }
            else{
                this.title = "Employee Overall Performance"
                query = undefined
            }
            this.employeePerformanceStats = await this.getItem(
                `${appConstants.STATS_API}/employee-performance${query ? query : ''}`)
        },
        findTaskFor(empId){
            this.$router.push({name:"Tasks",query:{empId:empId}})
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
