<template>
<v-card height="100%">
        <v-card-text>
            <v-row class="center" justify="space-around">
                <v-col cols="8" align-self="center">
                    <center><label :style="themeFont" class="subtitle">{{title}}</label></center>
                </v-col>
                <v-col cols="2">
                    <v-select :items="filters" label="Standard" v-model="filter" @change="initComponent()"></v-select>
                </v-col>
            </v-row>
            <v-data-table class="mt-2" :headers="employeePerformance" :items="employeeTaskCount">
                <template v-slot:item="{ item }">
                    <tr>
                        <td @click="findTaskFor(item._id)" style="cursor: pointer">{{ item.name }}</td>
                        <td align="right">{{ item.completed }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import { GChart } from "vue-google-charts";
export default {
    components: {
        GChart,
    },
    data() {
        return {
            employeeTaskCount: [],
            filters: [
                { text: 'Today', value: "today" },
                { text: "Yesterday", value: "yesterday" }
            ],
            title: "Task Completed Count",
            employeePerformance: [{
                text: "Name",
                value: "name",
            },
            {
                text: "Completed count",
                value: "completed",
                align: "right"
            }],
            filter: "today",
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.employeeTaskCount=await this.getItem(`${appConstants.STATS_API}/daywise-task-stats?statsFor=${this.filter}`)
        },
    },
};
</script>
