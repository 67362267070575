<template>
<v-menu ref="menu" v-model="menu1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="computedTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="computedTime" :label="title" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
    </template>
    <v-time-picker v-if="menu1" v-model="computedTime" full-width :disabled="disabled || false" @click:minute="$refs.menu.save(computedTime)"></v-time-picker>
</v-menu>
</template>

 
<script>
export default {
    props: ["title", "value", "disabled"],
    data() {
        return {
            menu1: false,
        };
    },
    computed: {
        computedTime: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
};
</script>

    
<style>
</style>
