<template>
<div class="text-center">
    <v-divider/>
    <h2 class="pa-4">{{title}}</h2>
    <v-divider/>
</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
    },
}
</script>