import Vue from 'vue'
import Store from '@/store/index.js'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import ViewOrganization from '@/views/organizations/ViewOrganization.vue'
import Organizations from '@/views/organizations/Organizations.vue'
import AddOrganization from '@/views/organizations/AddOrganization.vue'
import Processes from '@/views/processes/Processes.vue'
import AddProcess from '@/views/processes/AddProcess.vue'
import ViewProcess from '@/views/processes/ViewProcess.vue'
import Roles from '@/views/roles/Roles.vue'
import ViewRole from '@/views/roles/ViewRole.vue'
import AddRole from '@/views/roles/AddRole.vue'
import ViewWorkspace from '@/views/workspaces/ViewWorkspace.vue'
import Workspaces from '@/views/workspaces/Workspaces.vue'
import AddWorkspace from '@/views/workspaces/AddWorkspace.vue'
import ViewService from '@/views/services/ViewService.vue'
import Services from '@/views/services/Services.vue'
import AddService from '@/views/services/AddService.vue'
import ViewLabel from '@/views/labels/ViewLabel.vue'
import Labels from '@/views/labels/Labels.vue'
import AddLabel from '@/views/labels/AddLabel.vue'
import Customers from '@/views/customers/Customers.vue'
import AddCustomer from '@/views/customers/AddCustomer.vue'
import ViewCustomer from '@/views/customers/ViewCustomer.vue'
import ViewOperation from '@/views/operations/ViewOperation.vue'
import Operations from '@/views/operations/Operations.vue'
import AddOperation from '@/views/operations/AddOperation.vue'
import Tasks from '@/views/tasks/Tasks.vue'
import UpdateTask from '@/views/tasks/UpdateTask.vue'
import Employees from '@/views/employees/Employees.vue'
import AddEmployee from '@/views/employees/AddEmployee.vue'
import ViewEmployee from '@/views/employees/ViewEmployee.vue'
import Groups from '@/views/groups/Groups.vue'
import AddGroup from '@/views/groups/AddGroup.vue'
import ViewGroup from '@/views/groups/ViewGroup.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import Documents from '@/views/documents/Documents.vue'
import KanbanView from '@/views/KanbanView.vue'
import ViewProfile from "@/views/user/ViewProfile.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import ViewSetting from '@/views/settings/ViewSetting.vue';
import ModifySetting from '@/views/settings/ModifySetting.vue';
import ScheduleData from '@/components/ScheduleData.vue'
import ViewProject from '@/views/projects/ViewProject.vue'
import Projects from '@/views/projects/Projects.vue'
import AddProject from '@/views/projects/AddProject.vue'
import Axios from 'axios'
import ViewCustomField from '@/views/customField/ViewCustomField'
import CustomField from '@/views/customField/CustomFields.vue'
import AddCustomField from '@/views/customField/AddCustomField.vue'
import ImportFile from '@/components/ImportFile'
import Registration from '@/views/Registration'
import Plans from '@/views/plans/Plan.vue'
import AddPlan from '@/views/plans/AddPlan.vue'
import ViewPlan from '@/views/plans/ViewPlan'
import BillingAccount from '@/views/billingAccount/Billing.vue'
import ViewBillingAccount from '@/views/billingAccount/ViewBilling'
import Template from '@/views/template/Templates'
import AddTemplate from '@/views/template/AddTemplate'
import ViewTemplate from '@/views/template/ViewTemplate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:slug/home',
    name: '/',
    component: Dashboard
  },
  {
    path: '/:slug/kanban',
    name: 'KanBan',
    component: KanbanView
  },
  {
    path: '/:slug/documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: "/:slug/viewProfile",
    name: "ViewProfile",
    component: ViewProfile,
  },
  {
    path: "/:slug/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },

  {
    path: '/:slug/organization/:id',
    name: 'ViewOrganization',
    component: ViewOrganization
  },
  {
    path: '/:slug/organizations',
    name: 'Organizations',
    component: Organizations
  },
  {
    path: '/:slug/organizations/:id',
    name: 'AddOrganization',
    component: AddOrganization
  },
  {
    path: '/:slug/settings',
    name: 'Settings',
    component: ViewSetting
  },
  {
    path: '/:slug/settings/:id',
    name: 'ModifySetting',
    component: ModifySetting
  },
  {
    path: '/:slug/billings',
    name: 'BillingAccount',
    component: BillingAccount
  },
  {
    path: '/:slug/billing/:id',
    name: 'ViewBillingAccount',
    component: ViewBillingAccount
  },
  {
    path: '/:slug/plans',
    name: 'Plans',
    component: Plans
  },
  {
    path: '/:slug/plans/:id',
    name: 'AddPlan',
    component: AddPlan
  },
  {
    path: '/:slug/plan/:id',
    name: 'ViewPlan',
    component: ViewPlan
  },
  {
    path: '/:slug/roles',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/:slug/role/:id',
    name: 'ViewRole',
    component: ViewRole
  },
  {
    path: '/:slug/roles/:id',
    name: 'AddRole',
    component: AddRole
  },
  {
    path: '/:slug/processes',
    name: 'Processes',
    component: Processes
  },
  {
    path: '/:slug/processes/:id',
    name: 'AddProcess',
    component: AddProcess
  },
  {
    path: '/:slug/process/:id',
    name: 'ViewProcess',
    component: ViewProcess
  },
  {
    path: '/:slug/workspaces',
    name: 'Workspaces',
    component: Workspaces
  },
  {
    path: '/:slug/workspaces/:id',
    name: 'AddWorkspace',
    component: AddWorkspace
  },
  {
    path: '/:slug/workspace/:id',
    name: 'ViewWorkspace',
    component: ViewWorkspace
  },
  {
    path: '/:slug/operations',
    name: 'Operations',
    component: Operations
  },
  {
    path: '/:slug/operations/:id',
    name: 'AddOperation',
    component: AddOperation
  },
  {
    path: '/:slug/operation/:id',
    name: 'ViewOperation',
    component: ViewOperation
  },
  {
    path: "/schdule",
    name: "/RecursiveOperation",
    component: ScheduleData,
  },
  {
    path: '/:slug/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/:slug/tasks/:id',
    name: 'UpdateTask',
    component: UpdateTask
  },
  {
    path: '/:slug/groups',
    name: 'Groups',
    component: Groups
  },
  {
    path: '/:slug/group/:id',
    name: 'ViewGroup',
    component: ViewGroup
  },
  {
    path: '/:slug/groups/:id',
    name: 'AddGroup',
    component: AddGroup
  },
  {
    path: '/:slug/employees',
    name: 'Employees',
    component: Employees
  },
  {
    path: '/:slug/employees/:id',
    name: 'AddEmployee',
    component: AddEmployee
  },
  {
    path: '/:slug/employee/:id',
    name: 'ViewEmployee',
    component: ViewEmployee
  },
  {
    path: '/:slug/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/:slug/customers/:id',
    name: 'AddCustomer',
    component: AddCustomer
  },
  {
    path: '/:slug/customer/:id',
    name: 'ViewCustomer',
    component: ViewCustomer
  },
  {
    path: '/:slug/importFile',
    name: 'ImportFile',
    component: ImportFile
  },
  {
    path: '/:slug/labels',
    name: 'Labels',
    component: Labels
  },
  {
    path: '/:slug/labels/:id',
    name: 'AddLabel',
    component: AddLabel
  },
  {
    path: '/:slug/label/:id',
    name: 'ViewLabel',
    component: ViewLabel
  },
  {
    path: '/:slug/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/:slug/services/:id',
    name: 'AddServices',
    component: AddService
  },
  {
    path: '/:slug/service/:id',
    name: 'ViewService',
    component: ViewService
  },
  {
    path: '/:slug/login',
    name: 'Login',
    meta: { layout: 'blank' },
    component: Login
  },
  {
    path: '/:slug/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/:slug/projects/:id',
    name: 'AddProject',
    component: AddProject
  },
  {
    path: '/:slug/project/:id',
    name: 'ViewProject',
    component: ViewProject
  },
  {
    path: '/:slug/customFields',
    name: 'CustomField',
    component: CustomField
  },
  {
    path: '/:slug/customFields/:id',
    name: 'AddCustomField',
    component: AddCustomField
  },
  {
    path: '/:slug/customField/:id',
    name: 'ViewCustomField',
    component: ViewCustomField
  },
  {
    path: '/:slug/templates',
    name: 'Templates',
    component: Template
  },
  {
    path: '/:slug/template/:id',
    name: 'ViewTemplate',
    component: ViewTemplate
  },
  {
    path: '/:slug/templates/:id',
    name: 'AddTemplate',
    component: AddTemplate
  },
  {
    path: '/error',
    name: 'Error',
    meta: { layout: 'blank' },
    component: ErrorPage
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: { layout: 'blank' },
    component: Registration
  },
  {
    path: '/',
    name: 'Home',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://easyprocess.in")
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  var nextPath = (Array.from(new Set(to.path.split('/'))).join("/"));//Remove duplicate slugs
  var slug = to.params.slug
  var localSlug = localStorage.getItem("orgDetails") ? JSON.parse(localStorage.getItem("orgDetails")).slug : ''
  let rewrite = false
  if (['/error', '/registration', '/'].includes(nextPath)) {
    return next()
  }
  try {
    if (!slug && localSlug) {// navigating between pages in same vendor
      if (!nextPath.startsWith(`/${localSlug}`))
        nextPath = appendSlug(nextPath, localSlug)
      rewrite = true
    } else if (slug && !localSlug || (slug != localSlug)) { //switching vendor
      let org = await findOrg(slug)
      localStorage.setItem("orgDetails", JSON.stringify(org))
      buildManifestFile(org)
      nextPath = nextPath.replace(localSlug, slug)
      rewrite = true
    } else if (slug == localSlug) {
      let org = await findOrg(slug)
      localStorage.setItem("orgDetails", JSON.stringify(org))
      Store.dispatch("actionUpdateOrgSetting", org)
      document.title = JSON.parse(localStorage.getItem("orgDetails")).orgName // successfull navigation
      rewrite = false
    }
    if (nextPath != from.path && rewrite) {
      return router.push(nextPath)
    } else {
      if(nextPath.startsWith(`/${localSlug}`) && nextPath != from.path){
        return next()
      }
    }
  } catch (error) {
    console.error("error: ", error);
    return next("/error")
  }
})
function appendSlug(nextPath, localSlug) {
  localSlug = !localSlug.startsWith("/") ? "/" + localSlug : localSlug
  if (!nextPath.startsWith(localSlug)) {
    return localSlug + nextPath
  }
  return nextPath;
}
async function findOrg(slug) {
  try {
    console.log("Reading organization details");
    let org = await Axios.get(process.env.VUE_APP_API_URL + '/api/organizations/slug/' + slug)
    return org.data
  } catch (error) {
    throw error
  }
}

/**
* Dynamically build the manifest.json and set in index.html
* @param {*} vendor 
*/
function buildManifestFile(vendor) {
  console.log("Building manifest");
  let host = `${window.location.protocol}//${window.location.host}`
  var logoImage = (vendor.logo ? process.env.VUE_APP_API_URL + vendor.logo.path : `${host}/img/icons/android-chrome-512x512.png`)
  var dimensions = vendor.logo ? vendor.logo.height + "x" + vendor.logo.width : '512x512'
  var type = vendor.logo ? vendor.logo.mimetype : 'image/png'
  var myDynamicManifest = {
    "name": vendor.orgName,
    "start_url": `${host}/${vendor.slug}`,
    "background_color": "#14A2F3",
    "theme_color": "#0f4a73",
    "display": "standalone",
    "icons": [{
      "src": logoImage,
      "sizes": dimensions,
      "type": type
    }]
  }
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const googleFontURL = new URL(window.location.protocol + "//fonts.googleapis.com/css?family=" + vendor.setting?.theme?.font_family)
  document.querySelector('#dynamic-manifest-placeholder').setAttribute('href', manifestURL);
  document.querySelector('#dynamic-font-placeholder').setAttribute('href', googleFontURL);
}

export default router
