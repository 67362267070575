<template>
<v-row v-if="isProd && isNotLoginOrErrorPage">
    <v-col sm="8" md="4" offset-sm="2" offset-md="4">
        <v-alert elevation="15" color="green" v-if="showSubscribeNotificationBanner">
            <v-row align="center">
                <v-col>
                    <v-icon dark large>mdi-bell-circle</v-icon>
                    <label class="white--text font-weight-bold ml-1">Enable Notifications</label>
                </v-col>
                <v-col>
                    <div class="dflex text-right">
                        <v-btn small @click="enableNotifications" class="mr-1">Enable</v-btn>
                        <v-btn outlined small dark @click="showLater=true">Later</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-alert>
    </v-col>
</v-row>
</template>

<script>
import appConstants from '@/utils/appConstants';
export default {
    data() {
        return {
            alreadySubscribed: false,
            swRegistration: undefined,
            initialized: false,
            showLater: false
        }
    },
    computed: {
        showSubscribeNotificationBanner() {
            let isLoggedIn = this.getProfile() !== ""
            if (this.initialized) {
                return 'serviceWorker' in navigator && 'PushManager' in window && isLoggedIn && !this.alreadySubscribed
            }
            if (this.showLater) {
                return false
            } else {
                return true
            }
        },
        isNotLoginOrErrorPage(){
            return !(window.location.href.endsWith('/login') || window.location.href.endsWith('/error'))
        }
    },
    created() {
        this.checkExistingPushSubscription()
    },
    methods: {
        enableNotifications() {
            Notification.requestPermission(result => {
                if (result == "granted") {
                    try {
                        if ("PushManager" in window) {
                            navigator.serviceWorker.ready.then(swreg => {
                                let msg = this.getMessageBody(`Congratulations! You have successfully subscribed to push notification on this device`, this.logoImage)
                                swreg.showNotification("Subscribed successfully", msg)
                                this.showSubscribeNotificationBanner()
                            })
                        }
                        if (!this.alreadySubscribed) {
                            this.createSubscription(this.swRegistration)
                        }
                    } catch (error) {
                        this.showSubscribeNotificationBanner()
                        console.error(error);
                    }
                }
            })
        },
        getMessageBody(body, image, actions) {
            return {
                body: body,
                icon: image,
                badge: image,
                vibrate: [100, 50, 200],
                actions: actions || []
            }
        },
        checkExistingPushSubscription() {
            navigator.serviceWorker.ready.then(swreg => {
                this.swRegistration = swreg
                return swreg.pushManager.getSubscription()
            }).then(subscription => {
                if (subscription) {
                    this.alreadySubscribed = true
                    console.log("Already subscribed");
                }
                this.initialized = true
            })
        },
        createSubscription(reg) {
            let vapidPublicKey = process.env.VUE_APP_VAPID_KEY
            reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey
            }).then(async newsub => {
                await this.postItem(appConstants.NOTIFICATIONS_API + "/subscribe", newsub)
                this.alreadySubscribed = true
            })
        },
        
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
    .v-alert{
        font-size: 14px !important;
        white-space: nowrap !important;
    }
    .v-alert{
        padding-inline: 8px !important;
    }
}
</style>
