<template>
  <div>
    <v-chip class="ma-1" v-for="(item, index) in attachments" :key="index" label
      >{{ item.fileName }}
      <v-icon class="mx-2" @click="download(item)">mdi-download</v-icon>
    </v-chip>
  </div>
</template>

<script>
import axios from "axios"
export default {
   props: {
        attachments: {
            type: Array,
            default: () => []
        },
    },
  methods: {
    download(item) {
      axios({
        url: process.env.VUE_APP_API_URL + item.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>