<template>
<v-tooltip top>
    <template v-slot:activator="{ on }">
        <v-btn :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" :style="themeSidebar" v-if="isAllowed(permission)" v-on="on" @click="$emit('click')">
            <v-icon :small="$vuetify.breakpoint.xsOnly" :style="themeSidebar">mdi-plus</v-icon>
        </v-btn>
    </template>
    <span :style="themeSidebar">{{ tooltip }}</span>
</v-tooltip>
</template>

<script>
export default {
    props: {
        permission: {
            type: String
        },
        tooltip:{
            type:String
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
