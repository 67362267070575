<template>
<v-container>
    <center>
        <v-card flat class="login-box">
            <v-layout column>
                <v-flex text-xs-center>
                    <v-img :src="showLogo()" max-height="170" max-width="180"></v-img>
                </v-flex>
                <v-card-text>
                    <center class="org-name">
                        <h1>{{orgName}}</h1>
                    </center>
                    <v-form v-model="valid" ref="login">
                        <v-text-field prepend-inner-icon="mdi-account" label="Enter Email" :rules="notEmpty" v-model="email" clearable></v-text-field>
                        <v-text-field :append-icon="showPassword?'mdi-eye':'mdi-eye-off'" :type="showPassword?'text':'password'" label="Enter password" :rules="notEmpty" prepend-inner-icon="mdi-lock" v-model="password" clearable @keypress.enter="login()" @click:append="showPassword = !showPassword"></v-text-field>
                        <v-btn class="base-inverted" :disabled="!valid" block @click="login()">Login</v-btn>
                    </v-form>
                    <br />
                    <alert-message :message="msg" :messageType="msgType" />
                    <a @click="showDialog=true">Forgot password</a>
                    <dialog-box :dialog="showDialog" title="Forgot Password" :errorMessage="msg" @close="showDialog=!showDialog">
                        <v-text-field prepend-inner-icon="mdi-account" label="Enter username" :rules="notEmpty" v-model="userName" clearable></v-text-field>
                        <v-btn class="base-inverted" block @click="forgotPassword()">Reset Password</v-btn>
                    </dialog-box>
                </v-card-text>
                <h5>{{ appVersion }}</h5>
            </v-layout>
        </v-card>
    </center>
</v-container>
</template>

<script>
import appConstants from "../utils/appConstants";
import {
    version
} from "../../package";
import AlertMessage from "../components/AlertMessage.vue";
export default {
    components: {
        AlertMessage
    },
    data() {
        return {
            notEmpty: [(v) => !!v || "Required"],
            valid: false,
            logoUrl:"",
            email: "",
            password: "",
            orgId: "",
            msg: "",
            orgName: "",
            appVersion: version,
            userName:'',
            showDialog:false,
            showPassword:false
        };
    },
    mounted() {
        this.orgId = JSON.parse(localStorage.getItem("orgDetails"))._id;
        this.orgName = JSON.parse(localStorage.getItem("orgDetails")).orgName;
        this.logoUrl=  JSON.parse(localStorage.getItem("orgDetails")).setting?.logo?.url  
    },
    methods: {
        showLogo(){
            return this.logoUrl?process.env.VUE_APP_API_URL+this.logoUrl:require("@/assets/default-logo.png")
        },
        async login() {
            this.msg = ''
            try {
                var token = await this.postItem(appConstants.AUTHENTICATE_API, {
                    username: this.email,
                    password: this.password,
                    orgId: this.orgId,
                });
                localStorage.setItem("token", token.token);
                var res = await this.getItem(
                    appConstants.AUTHENTICATE_API + "/profile"
                );
                this.setDataInLocalStorage(res, token.token);
                if(this.$store.getters.profile.subscription=='SUBSCRIBED'){
                    if(this.$store.getters.profile.type!='EMPLOYEE')
                        this.$router.push("/operations")
                    else
                        this.$router.push("/home");
                }
                else
                    this.$router.push({name:'Settings',params:{tab:2}})
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.details;
            }
        },
        async forgotPassword() {
            try{
                if(!this.userName)
                    alert("Username Required")
                else{
                    await this.postItem(appConstants.AUTHENTICATE_API+"/forgotPassword",{userName:this.userName,org:this.$store.getters.org}) 
                    alert("Password for "+ this.userName+ " has been updated. Please check the Registered email")
                    this.showDialog=false
                }
            }catch(error){
                console.log(error);
                this.handleError(error);
                this.msg = error.response.data.details;
            }
        },

        setDataInLocalStorage(res, token) {
            localStorage.setItem("profile", JSON.stringify(res.profile));
            this.$store.state.token = token;
            this.$store.state.profile = res.profile;
            this.$store.state.orgDetails = JSON.parse(
                localStorage.getItem("orgDetails")
            );
        },
    },
};
</script>

<style scoped>
.login-box {
    max-width: 500px;
    max-height: 600px;
}
.login-box,.org-name {
    padding: 1rem;
    color: gray;
}
</style>
