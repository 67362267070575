<template>
<v-card class="form-background" flat wrap>
    <v-card-text>
        <v-form ref="contactPersonForm" lazy-validation>
            <alert-message :message="msg" />
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="contactPerson.firstName" :rules="notEmpty" required label="First Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="contactPerson.middleName" required label="Middle Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="contactPerson.lastName" :rules="notEmpty" required label="Last Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="contactPerson.designation" label="Designation"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="contactPerson.email" label="E-mail"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="contactPerson.phoneNo" label="Mobile No." :rules="notEmpty"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" v-if="allowLogin && addFlag">
                    <v-text-field type="password" :rules="passwordRule" label="Password" v-model="contactPerson.password" required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <!-- <v-flex v-if="parent == 0" text-right>
                    <v-btn @click="addContactPerson()"> Add</v-btn>
                </v-flex>
                <v-flex v-else text-right>
                    <v-btn @click="updateContactPerson()"> Update</v-btn>
                </v-flex> -->
                <v-flex text-right>
                    <v-btn @click="saveContactPerson()">Save</v-btn>
                </v-flex>
            </v-row>
        </v-form>
    </v-card-text>
</v-card>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
import appConstants from "@/utils/appConstants.js";
export default {
    props: {
        value: {
            type: Object,
            default: () => this.defaultContactPerson(),
        },
        allowLogin: {
            type: Boolean,
            default: () => false,
        },
        parent: {
            type: [String, Number],
            default: () => 0
        }
    },
    components: {
        AlertMessage
    },
    computed: {
        contactPerson: {
            get() {
                return this.value || this.defaultContactPerson();
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    data() {
        return {
            addFlag: true,
            editFlag: false,
            notEmpty: [(v) => !!v || "Cannot be Empty"],
            emailRules: [
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            msg: ''
        };
    },

    methods: {
        defaultContactPerson() {
            return {
                firstName: appConstants.INITIAL_VALUE,
                middleName: appConstants.INITIAL_VALUE,
                lastName: appConstants.INITIAL_VALUE,
                designation: appConstants.INITIAL_VALUE,
                email: appConstants.INITIAL_VALUE,
                phoneNo: appConstants.INITIAL_VALUE,
                password: appConstants.INITIAL_VALUE,
            };
        },
        saveContactPerson(){
            if (this.$refs.contactPersonForm.validate()) {
                if(!this.contactPerson.roles){
                    this.contactPerson.roles= ["CUSTOMER"]
                }
                this.contactPerson['type'] = appConstants.ACCOUNT.TYPE.AGENT
                this.$emit('save', this.contactPerson)
            }
        },
        resetForm() {
            this.contactPerson = this.defaultContactPerson()
        }
    },
};
</script>

<style scoped>
.form-background {
    margin: 0 0;
    padding: 1%;
    margin-left: -23px;
    background-color: rgb(245, 245, 245);
}
</style>
