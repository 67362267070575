<template>
    <v-list-item :class="priorityClass">
            <v-list-item-content @click="$router.push('tasks/'+task._id)">
                <v-list-item-title class="text--primary body-1 wrap-text">{{task.name}}</v-list-item-title>
                <v-list-item-subtitle class="caption wrap-text">{{task.parent.displayName}}</v-list-item-subtitle>
                <v-flex>
                    <user-card :user="task.assignedTo" :short="false" :size="20"></user-card>
                </v-flex>
                <v-layout align-center>
                    <v-flex>
                        <label class="caption">Action Date</label><br>
                        <show-date :date="task.actionDate"></show-date>
                    </v-flex>
                    <v-flex>
                        <label class="caption">Due Date</label><br>
                        <show-date :date="task.dueDate"></show-date>
                    </v-flex>
                </v-layout>
            </v-list-item-content>
            <v-list-item-action>
                <Status :label="task.completed ? 'Completed' : 'Pending'"></Status>
                <assign-employee v-model="task.assignedTo" @change="$emit ('change',task.assignedTo)" :empList="employeeList" v-if="(isAllowed('assignEmployeeToTask')&& !task.completed)"></assign-employee>
            </v-list-item-action>
    </v-list-item>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import AssignEmployee from './AssignEmployee.vue';
import ShowDate from './ShowDate.vue';
import Status from './Status.vue';
import UserCard from './UserCard.vue';
export default {
    props: {
        task: {
            type: Object,
            require: true
        },
    },
    data() {
        return {
            employeeList: []
        }
    },
    components: { Status, ShowDate, UserCard, AssignEmployee },
    computed: {
        priorityClass() {
            if(this.task.priority == 'HIGH') return 'priority-high'
            if(this.task.priority == 'MEDIUM') return 'priority-medium'
            if(this.task.priority == 'LOW') return 'priority-low'
        }
    },
    mounted () {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            let employees = (await this.getItem(appConstants.EMPLOYEES_API + "?fields=_id,email,firstName,lastName&size=-1")).data;
            employees.forEach((element) => {
                let employee = this.createReference(element._id,element.firstName + " " + element.lastName,element.email);
                this.employeeList.push(employee);
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.v-list-item{
    color: gray !important;
}
</style>