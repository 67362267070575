<template>
<v-list-item>
    <v-list-item-content>
        <v-text-field v-model="item" v-if="editMode"> </v-text-field>
        <v-list-item-title class="body-1" v-else>{{item}}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
        <div v-if="editMode" align="center">
            <action-button icon="mdi-check" class="mr-1" @click="saveValue()"></action-button>
            <action-button icon="mdi-close" @click="cancelEdit()"></action-button>
        </div>
        <div v-else align="center">
            <action-button icon="mdi-pencil" class="mr-1" @click="editValue()"></action-button>
            <action-button icon="mdi-delete" @click="$emit('remove')"></action-button>
        </div>
    </v-list-item-icon>
</v-list-item>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: () => ""
        }
    },
    computed: {
        item: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    data() {
        return {
            oldValue: undefined,
            editMode: false
        }
    },
    methods: {
        editValue() {
            this.oldValue = this.item
            this.toggleEdit()
        },
        cancelEdit(){
            this.item = this.oldValue
            this.toggleEdit()
        },
        saveValue(){
            this.toggleEdit()
        },
        toggleEdit(){
            this.editMode = !this.editMode
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
