<template>
<v-container>
    <div class="center text-center">
        <v-avatar height="700px" width="auto">
            <img src="@/assets/invalid-address.png" height="auto" alt="Invalid address">
        </v-avatar>
    </div>
    <center>
        <v-layout column>
            <v-flex text-center>

            </v-flex>
        </v-layout>
        <back-button @click="$router.go(-1)" />
    </center>
</v-container>
</template>

<script>
import BackButton from '../components/BackButton.vue'
export default {
    components: {
        BackButton
    },
}
</script>
