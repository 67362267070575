<template>
<v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
            <v-switch v-model="computedValue" :label="label" :value="code"></v-switch>
        </div>
    </template>
    <span>{{tooltip}}</span>
</v-tooltip>
</template>

<script>
export default {
    props: ["value", "label", "code", "tooltip"],
    data() {
        return {
            data: ""
        };
    },
    computed: {
        computedValue: {
            get() {
                this.data = this.value;
                return this.data;
            },
            set(newValue) {
                this.data = newValue;
                this.$emit("input", this.data);
            },
        },
    },
}
</script>
