<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Services"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addService" tooltip="Add Services"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="serviceList" sort-by="fname" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Service'" @apply-filters="$refs.pagination.filterApi($event, 'Service')" @clear-filters="readServices(true)" />
        </template>
        <template align="right" v-slot:item.action="{ item }">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission)" @click="
              icon.name == 'mdi-delete'
                ? deleteService(item)
                : performAction(item._id, icon)
            "></action-button>
            </template>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="serviceList" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from '../../components/Pagination.vue';
export default {
    components: {
        Pagination
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/service/",
                    permission: "viewServiceDetails",
                },
                {
                    name: "mdi-pencil",
                    path: "/services/",
                    permission: "updateService"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteService"
                },
            ],
            headers: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Description",
                    value: "description",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            serviceList: [],
            api: appConstants.SERVICES_API,
        };
    },
    methods: {
        async deleteService(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this service ?",
                        appConstants.SERVICES_API + "/" + item._id
                    )
                )
                    this.serviceList.splice(this.serviceList.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/services/0");
        },
        async readServices(checkFilter) {
            this.serviceList = [];
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.serviceList = await this.$refs.pagination.init(this.api)
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
</style>
