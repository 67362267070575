<template>
  <div>
    <v-flex xs12 sm4>
      <template>
        <v-row justify="center">
          <dialog-box
            :dialog="dialog"
            title="Recursive Operation"
            :errorMessage="msg"
            @close="$emit('close')"
            ><v-form ref="form" v-model="valid" lazy-validation>
              <v-card ref="form" flat>
                <v-card-text>
                  <v-text-field outlined :value="recursiveOp" readonly>
                  </v-text-field>
                  <v-row>
                    <v-col cols="12" md="6"
                      ><v-select
                        :items="items"
                        label="Repeat"
                        v-model="freq"
                        required
                      ></v-select
                    ></v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="number"
                        label="Repeat Every"
                        min="1"
                        max="31"
                        v-model="interval"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    ><v-col>
                      <!-- <v-text-field
                        type="date"
                        label="START DATE"
                        v-model="dtstart"
                        required
                      ></v-text-field>  -->
                      <!-- <date
                        title="Start date *"
                        v-model="dtstart"
                        required
                      />  -->
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="dtstart"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dtstart"
                            label="Start Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="freq == 'DAILY'">
                      <v-row>
                        <v-col md="5">
                          <v-select
                            :items="Product"
                            label="Choose"
                            v-model="choice"
                          ></v-select>
                        </v-col>
                        <v-col v-if="choice == 'Until'">
                          <!-- <v-text-field type="datetime-local" v-model="until"
                            >Date</v-text-field
                          > -->
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="until"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="until"
                                label="End Date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col v-if="choice == 'Count'">
                          <v-text-field
                            type="number"
                            min="1"
                            label="Number"
                            required
                            v-model="count"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="freq == 'WEEKLY'">
                      <v-row>
                        <v-col>
                          <p>Repeat On</p>
                          <v-chip-group
                            multiple
                            active-class="primary--text"
                            v-model="byweekday"
                          >
                            <v-chip class="ma-2" :value="days[0]"> S </v-chip>
                            <v-chip class="ma-2" :value="days[1]"> M </v-chip>
                            <v-chip class="ma-2" :value="days[2]"> T </v-chip>
                            <v-chip class="ma-2" :value="days[3]"> W </v-chip>
                            <v-chip class="ma-2" :value="days[4]"> T </v-chip>
                            <v-chip class="ma-2" :value="days[5]"> F </v-chip>
                            <v-chip class="ma-2" :value="days[6]"> S </v-chip>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <p>End</p>
                          <v-select
                            :items="Product"
                            v-model="choice"
                          ></v-select>
                        </v-col>

                        <v-col v-if="choice == 'Until'" md="6" class="mt-8">
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="until"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="until"
                                label="End Date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col v-if="choice == 'Count'" md="6" class="mt-8">
                          <v-text-field
                            type="number"
                            min="1"
                            label="Number"
                            v-model="count"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="freq == 'MONTHLY'">
                      <v-row>
                        <v-col class="mt-5"> <p>DAY</p></v-col>
                        <v-col cols="12" md="10">
                          <v-text-field
                            type="number"
                            min="1"
                            v-model="bymonthday"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="5"
                          ><v-select
                            :items="Product"
                            label="End"
                            v-model="choice"
                          ></v-select
                        ></v-col>

                        <v-col v-if="choice == 'Until'" md="7">
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="until"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="until"
                                label="End Date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col v-if="choice == 'Count'" md="7">
                          <v-text-field
                            type="number"
                            min="1"
                            label="Number"
                            v-model="count"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="freq == 'YEARLY'">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="months"
                            label="Month"
                            v-model="bymonth"
                          ></v-select>
                        </v-col>
                        <v-col>
                          <v-select
                            :items="Product"
                            label="End"
                            v-model="choice"
                          ></v-select>
                        </v-col>
                        <v-col v-if="choice == 'Until'">
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="until"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="until"
                                label="End Date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col v-if="choice == 'Count'">
                          <v-text-field
                            type="number"
                            min="1"
                            label="Number"
                            v-model="count"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  $emit('submit', str), $emit('close'), $emit('editForm', edit)
                "
                color="primary"
                >Submit</v-btn
              >
              <v-btn @click="$emit('close')" color="grey">Cancel</v-btn>
            </v-card-actions>
          </dialog-box>
        </v-row>
      </template>
    </v-flex>
    <br />
  </div>
</template>
<script>
import DialogBox from "./DialogBox.vue";
import { RRule, rrulestr } from "rrule";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: String,
      default: () => String,
    },
    newschedule: {
      type: String,
      default: () => String,
    },
    opId: {
      type: String,
      default: () => String,
    },
  },
  components: {
    DialogBox,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      modal1: false,
      str: "",
      choice: "Never",
      freq: "DAILY",
      interval: 1,
      byweekday: [],
      byday: 2,
      bymonth: null,
      bymonthday: null,
      dtstart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      until: null,
      count: 1,
      schedule: "",
      edit: true,
      days: [
        RRule.SU,
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA,
      ],
      items: ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"],
      Product: ["Never", "Until", "Count"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      interval: "1",
      day: "Daily",
      choose: "Never",
      valid: true,
    };
  },
  methods: {
    freqNumberToSting(n) {
      switch (n) {
        case 0:
          return "YEARLY";
        case 1:
          return "MONTHLY";
        case 2:
          return "WEEKLY";
        case 3:
          return "DAILY";
      }
    },
  },
  computed: {
    operationSchedule: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    // newschedule: {
    //   get() {
    //     return this.value1;
    //   },
    //   set(newValue1) {
    //     this.$emit("input", newValue1);
    //   },
    // },
    recursiveOp() {
      //console.log("OLD schedule =", this.operationSchedule);
      // console.log("New schedule =", rrulestr(this.newschedule));
      //console.log(RRule.parseString(this.newschedule));
      //console.log("New schedule =", this.newschedule);
      if (this.newschedule && this.opId != 0) {
        var options = RRule.parseString(this.newschedule);
        this.freq = this.freqNumberToSting(options.freq);
        if (options.count) {
          this.choice = "Count";
          this.count = options.count;
        } else if (options.until) {
          this.choice = "Until";
          var endDate = new Date();
          endDate = options.until;
          this.until =
            endDate.getFullYear() +
            "-" +
            (endDate.getMonth() + 1) +
            "-" +
            endDate.getDate();
        } else {
          this.choice = "Never";
        }
        var startDate = new Date();
        startDate = options.dtstart;
        this.dtstart =
          startDate.getFullYear() +
          "-" +
          (startDate.getMonth() + 1) +
          "-" +
          startDate.getDate();
        this.byweekday = options.byweekday;
        this.interval = options.interval;
        this.bymonth = options.bymonth;
        this.bymonthday = options.bymonthday;
        var Rule = new RRule(options);
        this.newschedule = options.toString();
        return Rule.toText();
      } else {
        var rRule = new RRule({
          freq: RRule[this.freq],
          interval: this.interval,
          byweekday: this.byweekday,
          dtstart: this.dtstart ? new Date(this.dtstart) : null,
          until: this.choice == "Until" ? new Date(this.until) : null,
          count: this.choice == "Count" ? this.count : null,
          bymonthday: this.bymonthday,
          bymonth:
            this.bymonth == "January"
              ? 1
              : this.bymonth == "February"
              ? 2
              : this.bymonth == "March"
              ? 3
              : this.bymonth == "April"
              ? 4
              : this.bymonth == "May"
              ? 5
              : this.bymonth == "June"
              ? 6
              : this.bymonth == "July"
              ? 7
              : this.bymonth == "August"
              ? 8
              : this.bymonth == "September"
              ? 9
              : this.bymonth == "October"
              ? 10
              : this.bymonth == "November"
              ? 11
              : this.bymonth == "December"
              ? 12
              : null,
        });
        this.operationSchedule = rRule.toString();
        // console.log("OPERATION SCHEDULE =", this.operationSchedule);
        this.str = rRule.toText();
        return this.str;
      }
    },
  },
};
</script>