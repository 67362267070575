<template>
<v-btn fab v-if="show" x-small class="base-inverted" @click="$emit('click')">
    <v-icon>{{icon.name || icon}}</v-icon>
</v-btn>
</template>

<script>
export default {
    props: {
      icon:{
        type: [Object, String],
      },
      show:{
        type: Boolean,
        default: () => true
      }
    }
};
</script>
