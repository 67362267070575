<template>
<v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
        <center>
            <h2>{{ formTitle }}</h2>
        </center>
        <alert-message :message="msg" :messageType="msgType" />
        <v-row>
            <v-col cols="12" sm="12">
                <v-text-field class="mx-3" v-model="item.name" label="Project Name*" single-line :rules="notEmpty" required></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-autocomplete class="mx-3 my-2" v-model="item.workspace" :items="workspaces" dense chips item-text="displayName" label="Workspace" return-object></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
                <customer-dropdown class="mx-3" v-model="item.customer"></customer-dropdown>
            </v-col>
        </v-row>
        <v-row class="mt-n8">
            <v-col>
                <v-switch v-model="item.completed" label="Mark Completed"></v-switch>
            </v-col>
        </v-row>
        <alert-message :message="msg" :messageType="msgType" />
        <v-card flat>
            <form-buttons :valid="valid" @submit="save()" @cancel="goBack()"></form-buttons>
        </v-card>
    </v-container>
</v-form>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import CustomerDropdown from "../../components/CustomerDropdown.vue";
export default {
    components: {
        FormButtons,
        AlertMessage,
        CustomerDropdown
    },
    data() {
        return {
            valid: true,
            users: [],
            item: {
                name:'',
                workspace:{},
                customer:{}
            },
            notEmpty: [(v) => !!v || "Required"],
            formTitle: "",
            workspaces:[]
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            let workspaceList = (await this.getItem(appConstants.WORKSPACES_API)).data
            this.workspaces = this.getReferenceList(workspaceList)
            if (this.id != 0) {
                this.formTitle = "Edit Project ";
                try {
                    this.item=await this.getItem(appConstants.PROJECT_API + "/" + this.id)
                } catch (error) {
                    this.handleError(error);
                }
            } else {
                this.formTitle = "Add New Project";
            }
        },
        async save() {
            if (this.$refs.form.validate()) {
                if (this.id == 0) {
                    try {
                        this.item = await this.postItem(appConstants.PROJECT_API,this.item);
                        this.$router.go(-1);
                    } catch (error) {
                        console.log(error);
                        this.handleError(error);
                    }
                } else {
                    try {
                        await this.putItem(appConstants.PROJECT_API + "/" + this.item._id,this.item);
                        this.$router.go(-1);
                    } catch (error) {
                        this.handleError(error);
                    }
                }
            }
        },
        getReferenceList(data) {
            let referenceList = [];
            data.forEach((element) => {
                referenceList.push(
                    this.createReference(
                        element._id,
                        element.name,
                        element.description
                    )
                );
            });
            return referenceList;
        },
    },
};
</script>
