<template>
<v-container>
    <v-card flat>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <alert-message :message="msg" :messageType="msgType" />
                <center>
                    <h2>{{ formTitle }}</h2>
                </center>
                <v-card flat>
                    <v-card-text>
                        <h3>Personal Information</h3>
                        <v-row>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field :rules="notEmpty" label="First Name *" v-model="employee.firstName" required clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field label="Middle Name" v-model="employee.middleName" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field :rules="notEmpty" label="Last Name *" v-model="employee.lastName" required clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <label hidden>{{computedUserName}}</label>
                                <v-text-field @change="updateUserName=false" :rules="numRules" label="Mobile No.*" v-model="employee.phoneNo" required clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field label="E-mail" v-model="employee.email" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <app-date v-model="employee.dob" title="DOB" dateFormat="DD/MM/YYYY"></app-date>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Addresses :<br />
                                <address-card v-model="address" @add="addAddress()" />
                                <address-table :addresses="employee.addresses" @delete="removeAddress" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Official Information</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field label="Emp.id" :disabled="id != 0" v-model="employee.empId" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field label="Designation" v-model="employee.designation" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4" v-if="id != 0">
                                <v-autocomplete v-model="employee.roles" :items="roles" dense chips item-text="name" label="Roles" multiple clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <label>Status</label>
                                <v-radio-group v-model="employee.active" mandatory row>
                                    <v-radio label="Active" :value="true"></v-radio>
                                    <v-radio label="Inactive" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <div>
                            <v-row>
                                <v-col>
                                    <h3>Login Details</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" lg="4" sm="3">
                                    <v-text-field :disabled="!employee.allowLogin" label="Username" v-model="employee.userName"/>
                                </v-col>
                                <v-col v-if="id == 0" cols="12" md="4" lg="4" sm="3">
                                    <v-text-field :disabled="!employee.active" type="password" :rules="passwordRule" label="Password" v-model="employee.password" required clearable></v-text-field>
                                </v-col>
                                <v-col v-if="id == 0" cols="12" md="4" lg="4" sm="3">
                                    <v-text-field :disabled="!employee.active" type="password" label="Confirm Password" v-model="confirmPassword" :rules="passwordRule" clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-checkbox :disabled="!employee.active" v-model="employee.allowLogin" label="Allow Login"></v-checkbox>
                                </v-col>
                            </v-row>
                        </div>
                        <alert-message :message="msg" :messageType="msgType" />
                        <form-buttons :valid="valid" @submit="validate()" @cancel="goBack()"></form-buttons>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import AlertMessage from "@/components/AlertMessage.vue";
import AppDate from "@/components/Date.vue";
import FormButtons from "@/components/FormButtons.vue";
import AddressCard from "@/components/AddressCard.vue";
import AddressTable from "@/components/AddressTable.vue";
import RowItem from '../../components/RowItem.vue';

export default {
    components: {
        AppDate,
        FormButtons,
        AlertMessage,
        AddressCard,
        AddressTable,
        RowItem
    },
    data: () => ({
        valid: true,
        notEmpty: [(v) => !!v || "Required"],
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRule: [
            (v) => (v && v.length > 7) || "Password should be min 8 characters.",
        ],
        employee: {},
        id: 0,
        formTitle: "",
        roles: [],
        confirmPassword: appConstants.INITIAL_VALUE,
        address: {
            addressType: appConstants.INITIAL_VALUE,
            address: appConstants.INITIAL_VALUE,
        },
        default: {
            addressType: appConstants.INITIAL_VALUE,
            address: appConstants.INITIAL_VALUE,
        },
        userprofile:[{userName:''}],
        updateUserName:true
    }),
    mounted() {
        this.initialize();
    },
    computed: {
        computedUserName() {
            if(this.id==0)
                this.employee['userName']=this.updateUserName?this.employee.phoneNo:this.employee['userName'] 
        }
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            this.employee = {
                type: appConstants.INITIAL_VALUE,
                firstName: appConstants.INITIAL_VALUE,
                lastName: appConstants.INITIAL_VALUE,
                email: appConstants.INITIAL_VALUE,
                phoneNo: appConstants.INITIAL_VALUE,
                displayName: appConstants.INITIAL_VALUE,
                addresses: [],
                designation: appConstants.INITIAL_VALUE,
                roles: ["EMPLOYEE"],
                allowLogin: true,
                active:true
            };
            this.roles = (await this.getItem(appConstants.ROLES_API)).data;
            if (this.id != 0) {
                this.formTitle = "Edit Employee";
                try {
                    this.userprofile = await this.getItem(
                        appConstants.EMPLOYEES_API + "/" + this.id+"/userProfile"
                    )||[];
                    this.employee = await this.getItem(
                        appConstants.EMPLOYEES_API + "/" + this.id
                    );
                    this.employee['userName']=this.userprofile[0].userName
                } catch (error) {
                    console.log(error);
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
            } else {
                this.formTitle = "Add New Employee";
            }
        },
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    if (
                        this.id == 0 &&
                        this.employee.allowLogin &&
                        this.employee.password !== this.confirmPassword
                    ) {
                        this.msg = "Confirm Password doesn't match.";
                        this.msgType = "error";
                    } else {
                        if (this.id == 0) {
                            this.employee.orgId = JSON.parse(
                                localStorage.getItem("orgDetails")
                            )._id;
                            this.employee.type = "EMPLOYEE";

                            this.employee = await this.postItem(
                                appConstants.EMPLOYEES_API,
                                this.employee
                            );
                        } else {
                            this.employee = await this.putItem(
                                appConstants.EMPLOYEES_API + "/" + this.id,
                                this.employee
                            );
                        }
                        this.$router.push({
                            path: "/employees",
                        });
                    }
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
        addAddress() {
            let addExist = this.employee.addresses.find(
                (add) => add.addressType == this.address.addressType
            );
            if (!addExist) {
                this.employee.addresses.push(this.address);
                this.address = Object.assign({}, this.default);
            } else {
                let message = `AddressType '${this.address.addressType}' is already added`;
                alert(message);
            }
        },
        removeAddress(i) {
            this.employee.addresses.splice(i, 1);
        },
    },
};
</script>
