<template>
<v-list-item @click="$router.push('/operation/'+operation._id)" :class="`${opStatusCss}`" class="bottom-border">
    <v-list-item-content>
        <v-layout>
            <v-flex xs7 mt-2>
                <v-list-item-title class="body-2 font-weight-bold wrap-text">{{operation.name}}</v-list-item-title>
                <v-list-item-subtitle class="text--primary caption">{{operation.process.displayName}}</v-list-item-subtitle>
                <user-card :user="operation.account" :short="false" :size="24"></user-card>
            </v-flex>
            <v-flex xs5 mt-2 class="text-right">
                <v-menu left v-if="icons.length>0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <action-button key="Edit" icon="mdi-pencil" :show="isAllowed('updateOperation')" @click="$router.push('/operations/'+operation._id)"></action-button>
                        </v-list-item>
                        <v-list-item>
                            <action-button key="Delete" icon="mdi-delete" :show="isAllowed('deleteOperation')" @click="$emit('delete',operation._id)"></action-button>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-list-item-subtitle v-if="operation.workspace">{{operation.workspace.displayName}}</v-list-item-subtitle>
                <status :label="operation.currentTask.displayName" v-if="operation.currentTask" :block="false"></status>
            </v-flex>
        </v-layout>
        <v-layout class="mt-2" v-if="operation.actionDate">
            <v-flex xs4>
                Action Date
                <show-date :date="operation.actionDate"></show-date>
            </v-flex>
        </v-layout>
    </v-list-item-content>
</v-list-item>
</template>

<script>
import ShowDate from './ShowDate.vue';
import Status from "@/components/Status.vue";
import UserCard from './UserCard.vue';
export default {
    props: {
        icons: {
            type: Array,
            default: () => []
        },
        operation: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        Status,
        ShowDate,
        UserCard
    },
    data() {
        return {
            opStatusCss: 'INPROCESS'
        }
    },
    mounted() {
        this.initcomponent();
    },
    methods: {
        initcomponent() {
            if (this.operation.completed)
                this.opStatusCss = 'COMPLETED'
        },
        completeAction(icon, id) {
            if (icon.name == "mdi-delete")
                this.$emit('delete', id)
            else
                this.performAction(icon, id)
        }
    },
};
</script>

<style>
.COMPLETED {
    border-left: 5px solid green;
}

.INPROCESS {
    border-left: 5px solid orange;
    margin: 1px 0px;
}

.bottom-border {
    border-bottom: 1px solid gray;
}
</style>
