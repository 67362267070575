import moment from "moment";
export function dateColor(el, model){
    let diff = model.arg == 'date' ? moment(model.value).diff(moment(), 'days') : model.arg == 'days' ? parseInt(model.value) : 0
    if(diff > 0){
        el.style =  `color:green;font-size:16px`
    }else if (diff < 0){
        el.style =  `color:red;font-size:16px`
    }else{
        el.style =  `color:orange;font-size:16px`
    }
}