<template>
<v-container>
    <div>
        <v-card flat>
            <v-card-text>
                <center>
                    <h2>Billing Account details</h2>
                </center>
                <v-layout class="mt-8" wrap>
                    <v-flex sm8 xs12>
                        <row-view label="Organization" v-bind:text="bill.organization.name"></row-view>
                        <row-view label="Plan" v-bind:text="plan.displayName"></row-view>
                        <row-view label="Cost" v-bind:text='`₹ ${plan.value}`'></row-view>
                        <row-view label="Expiry" v-bind:text='bill.validTill|dateFormat'></row-view>
                        <row-view label="Status">
                            <status :label="bill.status=='SUBSCRIBED' ? 'SUBSCRIBED' : 'UNSUBSCRIBED'" :block="false"></status>
                        </row-view>
                        <view-meta-data colClass="col-sm-12" :item="bill" class="pr-6"></view-meta-data>
                    </v-flex>
                    <v-flex sm4 xs12>
                        <v-card flat :class="$vuetify.breakpoint.smAndUp?'':'mt-4'">
                            <v-card-item-title class="title">History</v-card-item-title>
                            <v-list style="max-height: 500px" class="overflow-y-auto">
                                <simple-card v-for="(item,index) in bill.historyLog.slice().reverse()" :key="index" :title="item.planName" :subtitle="item.date|dateFormat" :description="`₹ ${item.cost}`"></simple-card>
                            </v-list>
                            <v-card-content style="">
                            </v-card-content>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-text align="right">
                <back-button @click="goBack()" />
            </v-card-text>
        </v-card>
    </div>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import SimpleCard from "../workspaces/SimpleCard.vue";
import Status from "../../components/Status.vue";

export default {
    components: {
        BackButton,
        SimpleCard,
        Status
    },
    data: () => ({
        bill: {
            organization: '',
            historyLog: [],
            validTill:''
        },
        plan:{
            displayName:'',
            value:0
        }
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.bill = await this.getItem(appConstants.BILLING_ACCOUNTS_API + "/" + this.id)
                this.bill.activePlans.forEach(rec=>{
                    this.plan.displayName += `${rec.displayName}, `,
                    this.plan.value += rec.value  
                })  
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
