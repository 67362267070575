<template>
<v-card flat wrap>
    <v-card-text>
        <contact-person-form ref="contactPersonForm" v-model="person" @save="saveContactPerson($event)"></contact-person-form>
        <alert-message :message="msg" :messageType="msgType" />
        <v-data-table :headers="headers" :items="contactPersons" sort-by="firstName">
            <template v-slot:item.action="{ item }">
                <v-icon @click="editContactPerson(item)"> mdi-pencil </v-icon>
                <v-icon @click="removeContactPerson(item)"> mdi-delete </v-icon>
                <v-icon v-if="isAllowed('updateCustomer') && item._id" @click="openPasswordResetDialog(item._id)">mdi-lock-reset</v-icon>
            </template>
        </v-data-table>
    </v-card-text>
    <dialog-box title="Reset Password" :dialog="showPasswordResetForm" @close="toggleResetPasswordDialog()">
        <password-reset :forceReset="true" :api="api" ref="passwordResetForm" :userId="selectedId" :userName="selectedUsername" @close="toggleResetPasswordDialog()"></password-reset>
        <alert-message :message="msg" :messageType="msgType" />
    </dialog-box>
</v-card>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
import appConstants from "@/utils/appConstants.js";
import PasswordReset from "@/components/PasswordReset.vue";
import ContactPersonForm from './ContactPersonForm.vue';
export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        allowLogin: {
            type: Boolean,
            default: () => false,
        },
        parent: {
            type: [Number, String],
            default: () => 0
        }
    },
    components: {
        PasswordReset,
        AlertMessage,
        ContactPersonForm
    },
    computed: {
        contactPersons: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    data() {
        return {
            addFlag: true,
            editFlag: false,
            notEmpty: [(v) => !!v || "Cannot be Empty"],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            passwordRule: [
                (v) => (v && v.length > 7) || "Password should be min 8 characters.",
            ],
            person: this.defaultContactPerson(),
            i: 0,
            headers: [{
                    text: "FirstName",
                    value: "firstName",
                },
                {
                    text: "MiddleName",
                    value: "middleName",
                },
                {
                    text: "LastName",
                    value: "lastName",
                },
                {
                    text: "Designation",
                    value: "designation",
                },
                {
                    text: "E-mail",
                    value: "email",
                },
                {
                    text: "Mobile No.",
                    value: "phoneNo",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            api: appConstants.CUSTOMERS_API,
            showPasswordResetForm: false,
            selectedUsername: appConstants.INITIAL_VALUE,
            selectedId: appConstants.INITIAL_VALUE,
        };
    },

    methods: {
        defaultContactPerson() {
            return {
                firstName: appConstants.INITIAL_VALUE,
                middleName: appConstants.INITIAL_VALUE,
                lastName: appConstants.INITIAL_VALUE,
                designation: appConstants.INITIAL_VALUE,
                email: appConstants.INITIAL_VALUE,
                phoneNo: appConstants.INITIAL_VALUE,
                password: appConstants.INITIAL_VALUE,
            };
        },
        async removeContactPerson(item) {
            try {
                await this.deleteItem(`Are you sure you want to delete ${item.firstName} ${item.lastName}?`, appConstants.CUSTOMERS_API+'/'+item._id)
                this.contactPersons.splice(this.contactPersons.indexOf(item), 1);
            } catch (error) {
                this.msg = error.message
            }
        },
        async editContactPerson(item) {
            this.editFlag = true
            this.person = this.contactPersons.splice(
                this.contactPersons.indexOf(item),
                1
            )[0];
            this.addFlag = false
        },
        async saveContactPerson(contactPerson) {
            let repeatedContact = this.contactPersons.filter(
                (rec) => rec.phoneNo == contactPerson.phoneNo
            );
            if (repeatedContact.length > 0) {
                this.msg = "Please provide unique mobile number";
                this.msgType = "error";
                return;
            }
            try {
                if (this.parent != 0 && contactPerson._id) {
                this.person = (await this.putItem(`${appConstants.CUSTOMERS_API}/${contactPerson._id}`, contactPerson)).data
                }else if(this.parent != 0 && contactPerson._id !== 0){
                    this.person = await this.postItem(`${appConstants.CUSTOMERS_API}/agent/${this.parent}`, contactPerson)
                }else{
                    this.person = contactPerson
                }
                this.contactPersons.push(this.person);
                this.$refs.contactPersonForm.resetForm()
            } catch (error) {
                this.msg = error.response.data.details
            }
            
        },

        async openPasswordResetDialog(id) {
            let userName = (await this.getItem(appConstants.EMPLOYEES_API + "/" + id + "/userProfile"))[0].userName
            this.selectedUsername = userName;
            this.selectedId = id;
            this.toggleResetPasswordDialog();
        },
        toggleResetPasswordDialog() {
            this.showPasswordResetForm = !this.showPasswordResetForm;
            if (this.$refs.passwordResetForm) {
                this.$refs.passwordResetForm.resetForm();
            }
        },

    },
};
</script>
