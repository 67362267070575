<template>
<!-- <v-list-item two-line>
    <v-list-item-content>
        <v-list-item-subtitle class="apply-font" :style="themeFont">{{ label }}</v-list-item-subtitle>
        <slot>
            <v-list-item-title v-if="chip">
                <v-chip label class="ma-2 wrap-text" :color="color">{{ text }}</v-chip>
            </v-list-item-title>
            <v-list-item-title v-else-if="html" v-html="html"></v-list-item-title>

            <v-list-item-title v-else class="wrap-text">{{ text }}</v-list-item-title>
        </slot>
    </v-list-item-content>
</v-list-item> -->
<v-row align="center" dense>
  <slot name="parent">
    <v-col cols="4"><strong>{{label}}</strong></v-col>
    <v-col cols="6" class="wrap-text">
        <slot>
            <label v-if="chip">
                <v-chip label class="ma-2 wrap-text" :color="color">{{ text }}</v-chip>
            </label>
            <label v-else-if="html" v-html="html"></label>
            <label v-else>{{text}}</label>
        </slot>
    </v-col>
  </slot>
</v-row>
</template>

<script>
export default {
    props: ["label", "text", "chip", "color", "html"],
};
</script>

<style scoped>
.wrap-text {
    white-space: normal;
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
}
strong{
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 500px) {
    strong{
        font-size: 0.9rem !important;
    }
    .wrap-text {
        font-size: 1rem !important;
    }
}
</style>
