<template>
<v-form ref="passwordResetForm" v-model="valid" lazy-validation>
    <alert-message :message="msg" :messageType="msgType" />
    <v-layout column style="padding: 4%">
        <row-view label="Username" :text="userName"></row-view>
        <v-text-field v-if="!forceReset" type="password" label="Old Password*" v-model="payload.oldPassword" :rules="notEmpty" clearable></v-text-field>
        <v-text-field type="password" label="New Password*" v-model="payload.newPassword" :rules="notEmpty" clearable></v-text-field>
        <v-text-field type="password" label="Confirm Password*" clearable v-model="payload.confirmPassword" :rules="notEmpty"></v-text-field>
        <alert-message :message="msg" :messageType="msgType" />
        <v-flex text-right>
            <v-btn class="mr-2" @click="resetPassword()">Reset</v-btn>
            <v-btn @click="$emit('close')">Cancel</v-btn>
        </v-flex>
    </v-layout>
</v-form>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
import appConstants from "../utils/appConstants";
import RowItem from "./RowItem.vue";
export default {
    props: {
        forceReset: {
            type: Boolean,
            default: () => false,
        },
        userName: {
            type: String,
            default: () => undefined,
        },
        userId: {
            type: String,
            default: () => undefined,
        },
        api: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        RowItem,
        AlertMessage,
    },
    data() {
        return {
            payload: {},
            valid: true,
        };
    },
    methods: {
        async resetPassword() {
            try {
                if(!this.api)
                  this.api=appConstants.EMPLOYEES_API
                this.payload["userName"] = this.userName;
                await this.putItem(this.api + "/" + this.userId + "/forceReset", this.payload);
                this.$emit('close')
                this.resetForm();
                alert("Password reset successfully!!");
            } catch (error) {
                this.handleError(error);
                this.msg = "user not registered";
            }
        },
        resetForm() {
            (this.payload.oldPassword = appConstants.INITIAL_VALUE),
            (this.payload.newPassword = appConstants.INITIAL_VALUE),
            (this.payload.confirmPassword = appConstants.INITIAL_VALUE);
        },
    },
};
</script>

<style>
</style>
