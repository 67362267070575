<template>
<div v-if="process?.steps?.length>0">
    <div class="center graph-wrapper">
        <div class="graph-container" :style="{ transform: `scale(${zoom})`}">
            <label v-html="graph" @click="handleNodeClick"></label>
        </div>
    </div>
    <dialog-box :dialog="showInfoDialog && showBtn" :title="selectedNode?.name" @close="showInfoDialog=false">
        <label v-html="selectedNode?.description"></label>
        <label>Timeline: {{selectedNode?.turnAroundTime?.number}} {{selectedNode?.turnAroundTime?.type}}</label>
        <v-divider></v-divider>
        <label>Priority: {{selectedNode?.priority}}</label>
        <v-divider></v-divider>
        <label>Assign to: {{selectedNode?.assignedTo?.displayName}}</label>
    </dialog-box>
    <div v-if="showBtn">
        <v-btn @click="zoomIn">Zoom In</v-btn>
        <v-btn @click="zoom=1">Reset</v-btn>
        <v-btn @click="zoomOut">Zoom Out</v-btn>
    </div>
</div>
</template>

<script>
import mermaidAPI from 'mermaid'
import DialogBox from './DialogBox.vue'
import RowItem from './RowItem.vue'
export default {
    components: {
        DialogBox,
        RowItem
    },
    props: {
        process: {
            type: Object
        },
        showBtn:{
            type:Boolean,
            default:()=>true
        }
    },
    data() {
        return {
            zoom: 1,
            selectedNode: undefined,
            showInfoDialog: false
        }
    },
    created() {
        mermaidAPI.initialize({
            startOnLoad: false,
            // Set the zoom level (e.g., 1.5 for 150% zoom)
            flowchart: {
                useMaxWidth: true,
                htmlLabels: true
            },
            zoom: {
                enabled: true,
                level: 1.5
            }
        });
        mermaidAPI.init();
    },
    computed: {
        graph() {
            let str = mermaidAPI.render('diagram', this.drawProcessDiagramString(this.process))
            return str
        },
    },
    methods: {
        zoomIn() {
            this.zoom += 0.1; // Increase the zoom level by 0.1
        },
        zoomOut() {
            if (this.zoom > 0.1) {
                this.zoom -= 0.1; // Decrease the zoom level by 0.1, but keep it above 0.1
            }
        },
        drawProcessDiagramString(process) {
            let graph = `graph LR\n`
            if (!process.steps)
                process.steps = []
            if (!process.actions)
                process.actions = []
            if (process?.steps?.length > 0 || process?.actions?.length > 0) {
                process.steps.forEach((step, i) => {
                    if (step && step._id && step.name) {
                        graph =
                            i == 0 ?
                            `graph LR \n${step._id}(${step.name})` :
                            graph + `\n${step._id}(${step.name})`
                    }
                })
                if (process.actions && process.actions.length > 0) {
                    process.actions.forEach((action) => {
                        if (action && action.source && action.source) {
                            graph =
                                action && action.source && action.destination ?
                                graph +
                                `\n${action.source._id}--"${action.name}(${action.weight})"-->${action.destination._id}` :
                                graph
                        }
                    })
                }
            }
            return graph
        },
        handleNodeClick(event) {
            this.selectedNode = this.process.steps.find(rec => rec.name == event.target.textContent)
            this.showInfoDialog = true
        },
    },
}
</script>

<style lang="scss" scoped>
.graph-wrapper {
    padding: 10% 5%;
    width: auto;
    overflow: auto !important;
}
</style>
