<template>
<v-card @click="$emit('click')" class="mb-2" :class="priorityClass" :max-width="$vuetify.breakpoint.mdAndUp ? '344' : ''" outlined elevation="10">
    <v-list-item >
        <v-list-item-content>
            <v-list-item-title class="body-2 font-weight-medium" v-text="operation.name"></v-list-item-title>
            <v-list-item-subtitle style="display:flex" class="mt-1">
                <v-row align="center">
                    <v-col title="Action Date">
                        <v-icon small class="mr-1" v-date-color:date="operation.actionDate">mdi-timer</v-icon>
                        <show-date :date="operation.actionDate"></show-date>
                    </v-col>
                    <v-col title="Expiry Date">
                        <v-icon v-date-color:date="operation.expiryDate" small class="mr-1" v-if="operation.expiryDate">mdi-timer-off</v-icon>
                        <show-date :date="operation.expiryDate"></show-date>
                    </v-col>
                    <v-col v-if="operation.expiryDate" title="days left">
                        <v-icon v-date-color:days="daysLeft" small class="mr-1">mdi-timer-sand</v-icon>
                        <label v-date-color:days="daysLeft" class="text-body-2">{{ daysLeft }}</label>
                    </v-col>
                </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</v-card>
</template>

<script>
import ShowDate from './ShowDate.vue';
import moment from 'moment';
export default {
    components: {
        ShowDate
    },
    props: {
        operation: {
            type: Object,
            require: true
        }
    },
    computed: {
        daysLeft() {
            return this.operation.expiryDate ? moment(this.operation.expiryDate).diff(moment(), 'days') : 0
        },
        priorityClass() {
            if(this.daysLeft < 0) return 'expired'
            if(this.daysLeft > 0) return 'inProgress'
            if(this.daysLeft == 0) return 'dueToday'
        }
    },
};
</script>
<style scoped>
.expired{
    border-right: 5px solid var(--high-priority) !important;
    background-color: #fdd9df;
  }
  .dueToday{
    border-right: 5px solid var(--low-priority) !important;

  }
  .inProgress{
    border-right: 5px solid var(--normal-priority) !important;
  }
</style>
