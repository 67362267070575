<template>
    <span>
        <action-button icon="mdi-account-edit" @click="toggleDialog()"></action-button>
        <dialog-box :dialog="showDialog" title="Assign Employee" :errorMessage="msg" @close="toggleDialog()">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card ref="form" flat>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-autocomplete :rules="notEmpty" v-model="assignedTo" :items="empList"
                                    :return-object="true" item-text="displayName" item-value="id"
                                    label="Select Employee">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!valid" @click="submit()">
                            Submit
                        </v-btn>
                        <v-btn @click="toggleDialog()">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </dialog-box>
    </span>
</template>

<script>
import ActionButton from './ActionButton.vue';
import DialogBox from './DialogBox.vue'
export default {
    components: { DialogBox, ActionButton },
    props: {
        value: {
            type: Object,
            default: () => null
        },
        empList:{
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            msg: "",
            showDialog: false,
            valid: false,
            assignedTo: null,
            employeeList: []
        }
    },
    methods: {
        toggleDialog() {
            this.showDialog = !this.showDialog;
            !this.showDialog ? this.$emit('close') : this.assignedTo = this.value
        },
        submit() {
            this.$emit('input', this.assignedTo)
            this.$emit('change', this.assignedTo)
            this.assignedTo = null
            this.toggleDialog()
        }
    },

}
</script>

<style lang="scss" scoped>
</style>