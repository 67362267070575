<template>
<div>
    <v-row>
        <v-col cols="5" sm="10">
            <heading title="Customers"></heading>
        </v-col>
        <v-col cols="6" sm="2" :class="$vuetify.breakpoint.smAndUp?'text-right mt-4 ml-n4':'text-right ml-6 mt-4'">
            <import-file-button class="mr-2" importData='customers'></import-file-button>
            <add-btn @click="add()" permission="addCustomer" tooltip="Add customer"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="customerList" sort-by="firstName" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Customer'" @apply-filters="$refs.pagination.filterApi($event,'Customer')" @clear-filters="readCustomers(true)" />
        </template>
        <template v-slot:item.displayName="{item}">
            <label class="text-capitalize">{{item.displayName.toLowerCase()}}</label>
        </template>
        <template v-slot:item.type="{item}">
            <div class="text-center" :title="item.type">
                <v-icon v-if="item.type=='B2B'">mdi-domain</v-icon>
                <v-icon v-else>mdi-account</v-icon>
            </div>
        </template>
        <template align="right" v-slot:item.action="{ item }" style="display:flex">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission) ? true : false" @click="
              icon.name == 'mdi-delete'
                ? deleteCustomer(item)
                : performAction(item._id, icon)
            "></action-button>
            </template>
            <action-button class="mr-2" :show="isAllowed('updateCustomer') && item.type=='B2C'" icon="mdi-lock-reset" @click="openPasswordResetDialog(item._id)"></action-button>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="customerList" :api="api" />
    <dialog-box title="Reset Password" :dialog="showPasswordResetForm" @close="closePasswordResetDialog()">
        <password-reset :forceReset="true" api="/api/customers" ref="passwordResetForm" :userId="selectedId" :userName="selectedUsername" @close="closePasswordResetDialog()"></password-reset>
        <alert-message :message="msg" :messageType="msgType" />
    </dialog-box>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import AlertMessage from "@/components/AlertMessage.vue";
import Pagination from "../../components/Pagination.vue";
import DialogBox from "../../components/DialogBox.vue";
import PasswordReset from "../../components/PasswordReset.vue";
import ImportFileButton from '@/components/ImportFileButton'
import OperationDialog from '../../components/OperationDialog.vue';
export default {
    components: {
    Pagination,
    DialogBox,
    PasswordReset,
    AlertMessage,
    ImportFileButton,
    OperationDialog,
        OperationDialog
},
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/customer/",
                    permission: "viewCustomerDetails",
                },
                {
                    name: "mdi-pencil",
                    path: "/customers/",
                    permission: "updateCustomer",
                },
                {
                    name: "mdi-delete",
                    permission: "deleteCustomer",
                },
            ],
            headers: [
                {
                    text: "Name",
                    value: "displayName",
                },
                {
                    text: "E-mail",
                    value: "email",
                },
                {
                    text: "Mobile No.",
                    value: "phoneNo",
                },
                {
                    text: "Account Type",
                    value: "type",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            customerList: [],
            api: appConstants.CUSTOMERS_API,
            selectedId: appConstants.INITIAL_VALUE,
            showPasswordResetForm: false,
            selectedUsername: appConstants.INITIAL_VALUE,
        };
    },
    mounted() {
        this.readCustomers();
    },

    methods: {
        async deleteCustomer(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this customer ?",
                        appConstants.CUSTOMERS_API + "/" + item._id
                    )
                )
                    this.customerList.splice(this.customerList.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/customers/0");
        },
        async readCustomers(checkHistory) {
            if(checkHistory)
                this.$refs.filter.checkHistory()
            this.customerList = [];
            try {
                this.customerList = await this.$refs.pagination.init(appConstants.CUSTOMERS_API + "?fields=displayName,email,phoneNo,type")
            } catch (error) {
                console.log(error);
            }
        },
        async openPasswordResetDialog(id) {
            let userName = (await this.getItem(appConstants.CUSTOMERS_API + "/" + id + "/userProfile"))[0].userName
            this.selectedUsername = userName;
            this.selectedId = id;
            this.showPasswordResetForm = true;
        },
        closePasswordResetDialog() {
            this.showPasswordResetForm = false;
            this.$refs.passwordResetForm.resetForm();
        }
    },
};
</script>

<style scoped></style>
