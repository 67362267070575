<template>
<div>
    <v-card flat>
        <v-card-text>
            <center>
                <p class="text-h4">Template Details</p>
            </center>
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <div class="col-xs-12 col-sm-4">
                            <row-view label="Name" v-bind:html="`<h3>${template.title}<h3>`"></row-view>
                            <row-view label="Type" v-bind:text="template.type"></row-view>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <label class="text-h6">Default Fields</label>
                            <v-list style="max-height: 500px" class="overflow-y-auto" v-if="template.selectedFields?.length>0">
                                <simple-card v-for="(field,index) in template.selectedFields" :key="index" :title="field.defaultField"></simple-card>
                            </v-list>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <label class="text-h6">Custom Fields</label>
                            <v-list style="max-height: 500px" class="overflow-y-auto" v-if="template.fields.length>0">
                                <simple-card v-for="(field,index) in template.fields" :key="index" :title="field.field.label"></simple-card>
                            </v-list>
                            <h3 v-else>No custom field added</h3>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
    <v-card flat>
        <v-card-text align="right">
            <back-button @click="goBack()" />
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import SimpleCard from "../workspaces/SimpleCard.vue";
export default {
    components: {
        appConstants,
        BackButton,
        SimpleCard
    },
    data: () => ({
        template: {
            title: "",
            type: "",
            fields: []
        },
        id: 0,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.template=await this.getItem(appConstants.TEMPLATE_API + "/" + this.id)
            } catch (error) {
                this.handleError(error);
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
