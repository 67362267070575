<template>
<v-card v-if="addresses.length > 0" flat>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Address Type</th>
                    <th class="text-left">Address</th>
                    <th v-if="!readOnly" class="text-right">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in addresses" :key="item.addressType">
                    <td>{{ item.addressType }}</td>
                    <td>{{ item.address }}</td>
                    <td v-if="!readOnly" class="text-right">
                        <v-icon small @click="$emit('delete', i)"> mdi-delete </v-icon>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</v-card>
</template>

<script>
export default {
    props: {
        addresses: {
            type: Array,
            default: Array,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
