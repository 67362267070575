import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import '@/styles/style.css'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import TitleBar from './components/TitleBar.vue' 
import commonFunctions from './mixins/commonFunctions'
import RowItem from './components/RowItem.vue' 
import SearchBar from './components/SearchBar'
import DefaultLayout from './layouts/DefaultLayout.vue'
import Blank from './layouts/Blank.vue'
import ActionButton from '@/components/ActionButton.vue'
import AlertMessage from "@/components/AlertMessage.vue";
import DialogBox from "@/components/DialogBox.vue";
import FontPicker from 'font-picker-vue';
import wysiwyg from "vue-wysiwyg";
import Notifications from 'vue-notification'
import ViewMetaData from '@/components/ViewMetaData'
import Filters from '@/components/Filters'
import AddBtn from '@/components/AddBtn'
import Heading from '@/components/Heading'
import { dateColor } from './utils/customDirectives'
import JsonExcel from "vue-json-excel";
Vue.use(Notifications)
Vue.use(wysiwyg, {});

Vue.mixin(commonFunctions),
Vue.component("font-picker",FontPicker)
Vue.component("action-button",ActionButton)
Vue.component('search-bar',SearchBar)
Vue.component('default-layout',DefaultLayout)
Vue.component('blank-layout',Blank)
Vue.component("title-bar",TitleBar)
Vue.component("row-view",RowItem)
Vue.component("alert-message",AlertMessage),
Vue.component("dialog-box",DialogBox)
Vue.component("view-meta-data",ViewMetaData)
Vue.component("filters",Filters)
Vue.component("add-btn",AddBtn)
Vue.component("heading",Heading)
Vue.component("downloadExcel", JsonExcel);

Vue.directive('date-color', dateColor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
