<template>
<div>
    <operation-template :id="$route.params.id||0" @success="goBack()" @cancel="goBack()" :changeTemplate="true"/>
</div>
</template>

<script>
import OperationTemplate from '@/components/OperationTemplate.vue'
export default {
    components: {
        OperationTemplate
    },
    data() {
        return {
        };
    },
};
</script>
