<template>
<div>
    <v-card flat>
        <v-card-text>
            <center>
                <p class="text-h4">Group Details</p>
            </center>
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <div class="col-xs-12 col-sm-4">
                            <row-view label="Name" v-bind:html="`<h3>${group.name}<h3>`"></row-view>
                            <row-view label="Description" v-bind:text="group.description"></row-view>
                            <v-row>
                            <v-col cols="12">
                                <view-meta-data colClass="col-sm-12" :item="group"></view-meta-data>
                        </v-col></v-row>
                        </div>
                        <div class="col-xs-12 col-sm-4 pl-5">
                            <label class="text-h6">Members</label>
                                <v-list style="max-height: 500px" class="overflow-y-auto">
                                    <user-card :user="member" v-for="member in group.members" :key="member._id" class="px-2 mt-2"></user-card>
                                </v-list>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <label class="text-h6">Process</label>
                                <v-list style="max-height: 500px" class="overflow-y-auto">
                                    <simple-card v-for="(item,index) in process" :key="index" :title="item.name" :subtitle="`Steps => ${item.displaySteps}`"></simple-card>
                                </v-list>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
    <v-card flat>
        <v-card-text align="right">
            <back-button @click="goBack()" />
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import UserCard from '../../components/UserCard.vue';
import SimpleCard from '../workspaces/SimpleCard.vue';
export default {
    components: {
        BackButton,
        UserCard,
        SimpleCard
    },
    data() {
        return {
            group: {},
            id: 0,
            process:[{displayStep:''}]
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            try {
                this.group = await this.getItem(
                    appConstants.GROUPS_API + "/" + this.id
                );
                this.process=(await this.getItem(appConstants.PROCESSES_API+`?conditions=steps.assignedTo._id=${this.group._id}`)).data
                this.checkProcessStep()
            } catch (error) {
                this.handleError(error);
            }
        },
        checkProcessStep(){
            this.process.forEach(process=>{
                let stepName=''
                process.steps.forEach(step=>{
                    if(step.assignedTo?._id==this.group._id)
                        stepName+=step.name+', '
                })
                process['displaySteps']=stepName
            })
        }
    },
};
</script>
