<template>
<v-flex>
    <title-bar @toggle="toggleDrawer"></title-bar>
    <app-drawer v-model="drawer" />
    <div :class="$vuetify.breakpoint.mdAndUp ? 'page-container' : ''">
        <slot></slot>
    </div>
</v-flex>
</template>

<script>
import AppDrawer from '@/components/AppDrawer.vue'
import socketio from 'socket.io-client'
export default {
    components: {
        AppDrawer
    },
    data: () => ({
        drawer: false,
        socket: null
    }),
    created() {
        this.initSocketConnection()
    },
    destroyed() {
        this.socket.disconnect();
    },
    mounted() {
        this.listenForEvent();
        this.drawer = this.$vuetify.breakpoint.mdAndUp
    },
    methods: {
        initSocketConnection() {
            this.socket = socketio(process.env.VUE_APP_API_URL, {
                rejectUnauthorized: false
            })
        },
        listenForEvent() {
            this.socket.on('updateTaskRecord', data => {
                if (data.task.assignedTo._id == this.$store.getters.profile.accountId) {
                    this.playSound()
                    if (window.location.pathname.includes('/tasks'))
                        window.task.list.push(data.task)
                    else
                        this.$store.dispatch("actionUpdateTaskFlag", true);
                    this.$notify({
                        title: 'New Task Assigned',
                        text: 'Task ' + data.task.name + ' has been assigned to you'
                    })
                }
            });
            this.socket.on('updateUserProfile', data => {
                if (data.user.accountId == this.$store.getters.profile.accountId) {
                    let user=JSON.parse(localStorage.getItem('profile'))
                    user['name']=data.user.displayName
                    this.$store.dispatch("actionUpdateProfile", user);
                }
            });
        },
        toggleDrawer($event) {
            console.log('Toggling: ' + $event)
            this.drawer = $event
        },
    },
}
</script>
<style scoped>
.page-container {
    padding: 0 0px 0px 5% !important;
}
</style>
