<template>
<div class="text-center">
    <v-overlay v-if="loading && enableActivate" :model-value="loading" class="align-center justify-center">
            <v-progress-circular indeterminate :size="78" :width="7"></v-progress-circular>
    </v-overlay>
    <h2 v-if="trialPlans.length>0 && !org?.activatedTrialPlan">Trial Plan</h2>
    <plan-card @update="checkCart()" v-if="trialPlans.length>0 && !org?.activatedTrialPlan && !enableActivate" :plans="trialPlans" @activate="activatePlan" :enableActivate="true"></plan-card>
    <h2 v-if="basicPlans.length>0">{{enableActivate?'':'Upgrade'}} Subscription Plan</h2>
    <plan-card :sm="enableActivate?5:3" @update="checkCart()" v-if="basicPlans.length>0" :plans="basicPlans" @activate="activatePlan" :enableActivate="enableActivate" :enableAddToCart="!enableActivate" :activePlanIds="activePlanIds"></plan-card>
    <h2 v-if="addOnPlans.length>0">Add On Plans</h2>
    <plan-card v-if="addOnPlans.length>0" @update="checkCart()" :plans="addOnPlans" :enableAddToCart="!enableActivate" :activePlanIds="activePlanIds"></plan-card>
    <h3 v-else-if="plans.length==0">No Subscription Plan found</h3>
    <cart v-if="show" :loading="loading" :drawer="show" @close="$emit('close')" @update="checkPlans()"></cart>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import PlanCard from '@/components/PlanCard'
import Cart from '@/components/Cart.vue'
export default {
    props: {
        showDrawer: {
            type: Boolean,
            default:()=> false
        },
        org:{
            type:Object,
            default:()=>{}
        },
        enableActivate:{
            type:Boolean,
            default:()=>false
        }
    },
    components: {
        PlanCard,
        Cart
    },
    computed: {
        show: {
            get(){
                return this.showDrawer
            },
            set(newValue){
                return newValue
            }
        }
    },
    data() {
        return {
            plans: [],
            api: appConstants.WEBSTORE_API+"/subscription",
            razorPayload: {
                key: '',
                cost: 0,
                currency: "INR",
                name: "",
                description: "",
                notes: {},
                "handler": (response) => {
                    this.addBillingData()
                },
                "modal": {
                    "ondismiss": () => {
                        alert('Payment Cancelled')
                    }
                },
                subscription_id: '',
                remember_customer: false,
                prefill: {},
            },
            selectedPlan: {},
            subscription: {
                plan: {},
                user: {},
                id: '',
                orgId:''
            },
            activePlan: {},
            bill: {},
            loading: false,
            activePlanIds:[],
            basicPlans:[],
            addOnPlans:[],
            trialPlans:[]
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            try {
                this.plans = (await this.getItem(this.api)).data
                this.basicPlans=this.plans.filter(rec=>(rec.type=='BASIC'&&!rec.trialPlan))
                if(!this.enableActivate)
                    this.addOnPlans=this.plans.filter(rec=>rec.type!='BASIC')
                this.razorPayload.key = appConstants.RAZORPAY_KEY
                this.bill = (await this.getItem(appConstants.WEBSTORE_API+ "/billingAccount/org/" + this.org?._id))[0]
                if(this.bill?.activePlans?.length>0 && this.bill?.status=='SUBSCRIBED')
                    this.bill.activePlans.forEach(rec => {this.activePlanIds.push(rec._id)});
                else if(!this.bill)
                    this.trialPlans=this.plans.filter(rec=>rec.trialPlan)

            } catch (error) {
                console.log(error);
                this.handleError(error);
                this.msg = error.response;
            }
        },
        async pay(plan) {
            this.selectedPlan = plan
            this.subscription.plan = plan
            this.subscription.user = this.getProfile()
            this.subscription.organizationId = this.org._id
            this.loading = true
            let subscribedData = await this.postItem(this.api, this.subscription)
            this.subscription.id = subscribedData.id
            if (!subscribedData.updated)
                this.checkout()
            else{
                this.loading = false
                this.deleteFromCart(plan)
                this.checkAddOns()
                if(this.$store.getters.planCart.length==0){
                    this.initComponent()
                    alert("Subscription Updated")
                    alert("Please Log in again to activate subscription")
                    this.logout()
                }
            }
        },
        checkout() {
            this.updateRazorPayload(this.subscription.id, this.selectedPlan)
            var razorCheckout = new Razorpay(this.razorPayload)
            this.loading = false
            razorCheckout.open()
            razorCheckout.on('payment.failed', async function (response) {
                alert(response.error.description);
            })
        },
        async updateRazorPayload() {
            this.razorPayload.name = this.org.orgName
            this.razorPayload.description = `Subscription for ${this.getProfile().name} with subscription id ${this.subscription.id}`
            let cost = this.selectedPlan.cost * 100
            this.razorPayload.subscription_id = this.subscription.id
            this.razorPayload.cost = parseInt(cost)
            this.razorPayload.prefill['name'] = this.getProfile().displayName
            this.razorPayload.prefill['contact'] = this.getProfile().phoneNo
            this.razorPayload.prefill['email'] = this.getProfile().username
        },
        async addBillingData() {
            let billingData = {}
            billingData['organization'] = {_id:this.org._id,name:this.org.orgName},
            billingData['plan'] = this.selectedPlan,
            billingData['subscriptionId'] = this.subscription.id
            await this.postItem(appConstants.WEBSTORE_API+"/billingAccount", billingData)
            if(!this.selectedPlan.trialPlan)
                this.checkAddOns()
            this.deleteFromCart(this.selectedPlan)
            this.initComponent()
            if(this.enableActivate)
                this.$emit('update')
            else{
                alert("Subscription Updated")
                alert("Please Log in again to activate subscription")
                this.logout()
            }
        },
        async unsubscribe() {
            this.loading = true
            await this.postItem(appConstants.SUBSCRIPTION_API + "/unsubscribe/" + this.org._id, {})
            this.loading = false
            alert("You have been unsubscribed from easy-process")
            this.initComponent()
            alert("Please Log in again to activate subscription")
            this.logout()
        },
        async checkPlans() {
            let basicPlans=this.$store.getters.planCart.filter(rec=>rec.type=='BASIC')
            if(basicPlans.length>1)
                alert("Only one Basic Plan can be activated at a time")
            else if(basicPlans.length==0 && this.bill.status!='SUBSCRIBED')
                alert("One Basic Plan needed to activate account")
            else if(basicPlans.length==1)
                await this.pay(basicPlans[0])
            else
                this.checkAddOns()
        },
        checkAddOns() {
            let addOnPlans = this.$store.getters.planCart.filter(rec => rec.type != 'BASIC')
            if(addOnPlans.length>0){
                addOnPlans.forEach(async rec => {
                    await this.pay(rec)
                })
            }
        },
        async activatePlan(plan){
            if (plan.trialPlan) {
                this.selectedPlan = plan
                this.deleteFromCart(plan)
                await this.addBillingData()
            }
            else
                await this.pay(plan)
        },
        async activateTrialPlan(){
            if(this.trialPlans.length>0)
                await this.activatePlan(this.trialPlans[0])
        },
        checkCart(){
            this.show=true
            this.$emit('update',true)
        }
    },
}
</script>

<style lang="scss" scoped>
.subscribe-btn {
    color: green
}

.word-wrap {
    white-space: nowrap;
}
</style>
