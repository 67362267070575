<template>
<v-container>
    <v-card flat>
        <v-card-text>
            <section-header title="Operation Details" />
            <br>
            <v-row>
                <v-col cols="12" sm="9">
                    <label class="text-h5">{{operation.name}}</label>
                    <p class="body-1 pt-2" v-if="operation.description" v-html="operation.description"></p>
                </v-col>
                <v-col cols="12" sm="3">
                    <div class="text-right text-h5">
                        <v-chip :class="operation.completed ? 'success' : 'base-inverted'" label>{{operation.completed ? 'Completed' : 'In process'}}</v-chip>
                    </div>
                </v-col>
            </v-row>
            <v-row dense>
                <row-view class="col-xs-12 col-sm-6" label="Customer Name">
                    <label class="link" @click="showAccount()">{{operation.account ? operation.account.displayName : ""}}</label>
                </row-view>
                <row-view class="col-xs-12 col-sm-6" label="Process"><label class="link" @click="showProcess()">{{operation.process ? `${operation.process.displayName} (v${operation.process.value})` : ''}}</label></row-view>
                <row-view class="col-xs-12 col-sm-6" label="Project" v-if="operation.project" :text="operation.project.displayName"></row-view>
                <row-view class="col-xs-12 col-sm-6" label="Current Task" v-bind:text="operation.currentTask ? operation.currentTask.displayName : ''" />
                <row-view class="col-xs-12 col-sm-6" label="Start Date" v-bind:text="operation.actionDate | dateFormat" />
                <row-view class="col-xs-12 col-sm-6" v-if="operation.expiryDate" label="Expiry Date" v-bind:text="operation.expiryDate | dateFormat" />
                <row-view class="col-xs-12 col-sm-6" label="Type" v-bind:text="operation.type" />
                <row-view class="col-xs-12 col-sm-6" label="Owner" v-if="operation.owner">
                    <v-row>
                        <v-col sm="8">
                            <user-card :user="operation.owner" />
                        </v-col>
                        <v-col sm="2">
                            <assign-employee v-model="operation.owner" @change="updateOperation(operation)" :empList="employeeList" v-if="(isAllowed('updateOperation')&& !operation.completed)"></assign-employee>
                        </v-col>
                    </v-row>
                </row-view>
                <row-view class="col-xs-12 col-sm-6" label="Execution Type" v-bind:text="operation.executionType == 'MANUAL' ? 'Manual' : 'Automatic'" />
                <row-view class="col-xs-12 col-sm-6" label="Executor" v-if="operation.executor">
                    <v-row>
                        <v-col sm="8">
                            <user-card :user="operation.executor" />
                        </v-col>
                        <v-col sm="2">
                            <assign-employee v-model="operation.executor" @change="updateOperation(operation)" :empList="employeeList" v-if="(isAllowed('updateOperation')&& !operation.completed)"></assign-employee>
                        </v-col>
                    </v-row>
                </row-view>
                <row-view class="col-xs-12 col-sm-6" v-if="operation.service" label="Service" v-bind:text="operation.service ? operation.service.displayName : ''" />
                <row-view class="col-xs-12 col-sm-6" @click="$router.push('/project/'+operation.project._id)" v-if="operation.project" label="Project">
                    <label class="link" @click="$router.push('/project/'+operation.project._id)">{{operation.project ? operation.project.displayName : ''}}</label>
                </row-view>
                <row-view class="col-xs-12 col-sm-6" v-if="!operation.expiryDate"></row-view>
                <row-view class="col-xs-12 col-sm-6" label="Attachments" />
                <div class="col-xs-12">
                    <file-download :attachments="operation.attachments"></file-download>
                </div>
                <row-view class="col-xs-12 col-sm-12" label="Links" />
                <div class="col-xs-12">
                    <file-url v-model="operation.attachmentUrls" :readOnly="true" />
                </div>
                <row-view class="col-xs-12 col-sm-6" v-for="(item,index) in operation.customFields" :key="index" :label="item.field.label" v-bind:text="item.value"/>
                <view-meta-data :item="operation" class="pr-6"></view-meta-data>
            </v-row>
            <v-card class="my-8" flat>
                <v-card-text>
                    <template>
                        <section-header title="History" />
                        <br />
                        <v-timeline align-top dense>
                            <v-timeline-item small v-for="(item, i) in completedTasks" :key="i" :color="`${ item?.action?.value == 'action_assigned_task' ? 'orange' : item.completed ? 'grey' : 'blue' }`">
                                <v-card elevation="3">
                                    <div class="panel-header px-3 py-1">
                                        <v-row>
                                            <v-col>
                                                <label class="subtitle-1" @click="showTask(item._id)">
                                                    {{ item.name }}
                                                </label>
                                                <label v-if="!item.action || item.action.value != 'action_assigned_task'">
                                                    <user-card :size="22" :user="item.assignedTo" v-if="item.assignedTo"></user-card>
                                                </label>
                                            </v-col>
                                            <v-col class="text-right mt-2">
                                                <assign-employee v-model="item.assignedTo" @change="assignEmployeeToTask(item, $event)" :empList="employeeList" v-if="(isAllowed('assignEmployeeToTask')&& !item.completed)"></assign-employee>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-card-text style="background-color:var(--base-inverted)" v-if="item.action && item.action.value == 'action_assigned_task'">
                                        <h4>{{item.note}} on {{item.updatedAt | dateFormat}}</h4>
                                    </v-card-text>
                                    <v-card-text class="text--primary" v-else>
                                        <div>
                                            <v-row dense v-if="item.note">
                                                <v-col>Note : {{ item.note }} </v-col>
                                            </v-row>
                                            <v-row dense v-if="item.attachments.length> 0">
                                                <v-col>Attachments :
                                                    <file-download :attachments="item.attachments" />
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" dense>
                                                <v-col cols="12" sm="4">
                                                    <label class="caption">Action Date: </label><br>
                                                    <show-date :date="item.actionDate"></show-date>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <label class="caption">Due Date: </label><br>
                                                    <show-date :date="item.dueDate"></show-date>
                                                </v-col>
                                                <v-col cols="12" sm="4" v-if="item.completed">
                                                    <label class="caption">Completed on: </label><br>
                                                    <show-date :date="item.updatedAt"></show-date>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </template>
                </v-card-text>
                <v-card flat>
                    <v-card-text align="right">
                        <back-button @click="goBack()" />
                    </v-card-text>
                </v-card>
            </v-card>
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import axios from "axios";
import appConstants from "@/utils/appConstants.js";
import FileDownload from "@/components/FileDownload.vue";
import BackButton from "../../components/BackButton.vue";
import UserCard from "../../components/UserCard.vue";
import FileUrl from '@/components/FileUrl'
import RowItem from '../../components/RowItem.vue';
import SectionHeader from '../../components/SectionHeader.vue';
import ShowDate from '../../components/ShowDate.vue';
import AssignEmployee from "../../components/AssignEmployee.vue";
export default {
    components: {
        BackButton,
        FileDownload,
        UserCard,
        FileUrl,
        RowItem,
        SectionHeader,
        ShowDate,
        AssignEmployee
    },
    data() {
        return {
            operation: {
                attachmentUrls: '',
                customFields:[]
            },
            completedTasks: [],
            id: 0,
            editIcon: {
                name: "mdi-pencil",
                path: "/operations/",
                permission: "updateOperation",
            },
            employeeList:[]
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        showProcess() {
            this.$router.push("/process/" + this.operation.process._id);
        },
        showAccount() {
            this.$router.push("/customer/" + this.operation.account._id);
        },
        showTask(id) {
            this.$router.push("/tasks/" + id);
        },
        async initComponent() {
            this.id = this.$route.params.id;
            try {
                this.employeeList=await this.getEmployees()
                this.operation = await this.getItem(
                    appConstants.OPERATIONS_API + "/" + this.id
                );
                let tasks = await this.getItem(
                    appConstants.TASKS_API + "/operation/" + this.id + "?sort=-createdAt"
                );
                this.completedTasks = tasks.data;
            } catch (error) {
                this.handleError(error);
            }
        },
        async updateOperation(operation){
            try{
                await this.putItem(appConstants.OPERATIONS_API + "/" + operation._id,operation);
            }catch{
                this.handleError(error);
                this.msg = error.response.data.details;
            }
        }
    },
};
</script>

<style scoped>
.panel-header {
    background-color: #363535;
    color: white;
}
</style>
