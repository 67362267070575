<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Groups"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addGroup" tooltip="Add Group"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="groups" sort-by="name" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Group'" @apply-filters="$refs.pagination.filterApi($event, 'Group')" @clear-filters="initComponent(true)" />
            <alert-message class="mx-4" :message="msg" :messageType="msgType" />
        </template>
        <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mr-2 base-inverted" fab x-small @click="$router.push('/group/' + item._id)">
                <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn class="mr-2 base-inverted" fab x-small @click="$router.push('/groups/' + item._id)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mr-2 base-inverted" fab x-small @click="deleteGroup(item)">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="groups" :api="api"/>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from "../../components/Pagination.vue";
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Group",
                    value: "name",
                },
                {
                    text: "Description",
                    value: "description",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            groups: [],
            api: appConstants.GROUPS_API + "?fields=name,description",
        };
    },
    methods: {
        add() {
            this.$router.push("/groups/0");
        },
        async deleteGroup(item) {
            try {
                await this.deleteItem(
                    "Are you sure you want to delete?",
                    appConstants.GROUPS_API + "/" + item._id
                );
                // this.groups.splice(this.groups.indexOf(item), 1);
                this.initComponent();
            } catch (error) {
                this.handleError(error);
            }
        },
        async initComponent(checkFilter) {
            try {
                    if(checkFilter)
                        this.$refs.filter.checkHistory()
                    this.groups = await this.$refs.pagination.init(this.api)
                }
                catch (error) {
                    this.handleError(error);
                }
        },
    },
};
</script>
