<template>
<v-container>
    <div>
        <v-card flat>
            <v-card-text>
                <center>
                    <h2>Organization Details</h2>
                </center>
                <div>
                    <h3 class="my-4">Organization</h3>
                    <v-row>
                        <v-col>
                            <row-view label="Name" v-bind:text="organization.orgName"></row-view>
                        </v-col>
                        <v-col>
                            <row-view label="Description" v-bind:text="organization.description"></row-view>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <row-view label="Contact number" v-bind:text="organization.contactNumber"></row-view>
                        </v-col>
                        <v-col>
                            <row-view label="Email" v-bind:text="organization.orgEmail"></row-view>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <row-view label="Slug" v-bind:text="organization.slug"></row-view>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                    <view-meta-data :item="organization"></view-meta-data>
                </div>
                <h3 class="my-4">Admins</h3>
                <br />
                <v-data-table :headers="headers" :items="organization.admins" class="elevation-1">
                    <template v-slot:item.name="{ item }">
                        {{ item.firstName }} {{ item.lastName }}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-text align="right">
                <back-button @click="goBack()" />
            </v-card-text>
        </v-card>
    </div>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";

export default {
    components: {
        BackButton
    },
    data: () => ({
        headers: [{
                text: "Name",
                value: "name"
            },
            {
                text: "Email",
                value: "email"
            },
        ],
        organization: {},
        id: 0,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.organization = await this.getItem(
                    appConstants.ORGANIZATIONS_API + "/" + this.id
                );
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
