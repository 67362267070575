<template>
<v-row align="center" justify="center" v-if="plans.length>0">
    <v-col v-for="plan in plans" :key="plan._id" :sm="sm" class="ma-2">
        <v-card max-width="300">
            <v-card-text class=text-center>
                <v-card-title class="word-wrap justify-center">{{ plan.name }}</v-card-title>
                <v-divider></v-divider>
                <label class="text-h4">{{ '₹ '+plan.cost }}</label>/
                <label>per Month</label>
                <v-divider></v-divider>
                <br>
                <v-list class="my-n4">
                    <v-list-item-subtitle>
                        {{ plan.userSize }} Users
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        {{ plan.diskSpace }}Gb Storage
                    </v-list-item-subtitle>
                    <v-list-item-title class="my-4">
                    <label class="font-weight-bold">Modules Included</label>
                    </v-list-item-title>
                    <v-list-item-subtitle class="mt-1" v-for="(item,index) in plan.modules" :key="index">
                        {{ item }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="my-6" v-if="plan.trialPlan">
                        Try for {{ plan.validity||7 }} days
                    </v-list-item-title>
                </v-list>
                <v-card-actions class="justify-center mt-6">
                    <v-btn v-if="enableActivate" class="base-inverted" @click="$emit('activate',plan)">Activate</v-btn>
                    <v-btn v-else-if="enableAddToCart" :disabled="activePlanIds.includes(plan._id)" class="base-inverted" @click="addToCart(plan)">Upgrade</v-btn>
                    <v-btn v-else @click="deleteFromCart(plan)">Remove <v-icon small>mdi-delete</v-icon></v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
export default {
    props: {
        plans: {
            type: Array,
            default:()=>[] 
        },
        activePlanIds:{
            type:Array,
            default:()=>[]
        },
        enableAddToCart:{
            type:Boolean,
            default:()=>false
        },
        enableActivate:{
            type:Boolean,
            default:()=>false
        },
        sm:{
            type:Number,
            default:()=>3
        }
    },
    methods: {
        addToCart(plan) {
            let plans=this.$store.getters.planCart
            let index=plans.indexOf(plan)
            if(index==-1){
                plans.push(plan)
                this.$store.dispatch("actionUpdatePlanCart",plans);
            }
            this.$emit('update')
        },
        deleteFromCart(plan){
            let plans=this.$store.getters.planCart
            plans.splice(plans.indexOf(plan),1)
            this.$store.dispatch("actionUpdatePlanCart",plans);
        }
    },
}
</script>

<style lang="scss" scoped>
.subscribe-btn {
    color: green
}

.word-wrap {
    white-space: nowrap;
}
</style>
