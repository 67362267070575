<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Labels"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addLabel" tooltip="Add label"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="labelList" sort-by="fname" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Label'" @apply-filters="$refs.pagination.filterApi($event, 'Label')" @clear-filters="readLabels(true)" />
        </template>
        <template align="right" v-slot:item.action="{ item }">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission)" @click="
              icon.name == 'mdi-delete'
                ? deleteLabel(item)
                : performAction(item._id, icon)
            "></action-button>
            </template>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="labelList" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from '../../components/Pagination.vue';
export default {
    components: {
        Pagination
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/label/",
                    permission: "viewLabelDetails"
                },
                {
                    name: "mdi-pencil",
                    path: "/labels/",
                    permission: "updateLabel"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteLabel"
                },
            ],
            headers: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Description",
                    value: "description",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            labelList: [],
            api: appConstants.LABELS_API,
        };
    },
    methods: {
        async deleteLabel(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this label ?",
                        appConstants.LABELS_API + "/" + item._id
                    )
                )
                    this.labelList.splice(this.labelList.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/labels/0");
        },
        async readLabels(checkFilter) {
            this.labelList = [];
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.labelList = await this.$refs.pagination.init(this.api)
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>

<style scoped>
</style>
