<template>
<v-container>
    <div>
        <v-card flat>
            <v-card-text>
                <center>
                    <h2>Plan details</h2>
                </center>
                <v-layout wrap class="mt-8">
                    <v-flex sm8 xs12>
                        <row-view label="Name" v-bind:text="plan.name"></row-view>
                        <row-view label="Total Users" v-bind:text="plan.userSize"></row-view>
                        <row-view label="Disk Space (in Gb)" v-bind:text="plan.diskSpace"></row-view>
                        <row-view label="Type" v-bind:text="plan.type"></row-view>
                        <row-view v-if="plan.validityInDays" label="Validity (in Days)" v-bind:text="plan.validityInDays"></row-view>
                        <row-view v-if="plan.frequency" label="Frequency" v-bind:text="plan.frequency.toUpperCase()"></row-view>
                        <row-view v-if="plan.interval" label="Interval" v-bind:text="plan.interval"></row-view>
                        <row-view label="Cost" v-bind:text='`₹ ${plan.cost}`'></row-view>
                        <row-view v-if="plan.trialPlan" label="Trial Plan" v-bind:text="plan.trialPlan?'Yes':'No'"></row-view>
                        <view-meta-data colClass="col-sm-12" :item="plan" class="pr-6"></view-meta-data>
                    </v-flex>
                    <v-flex sm4 xs12>
                        <v-card flat :class="$vuetify.breakpoint.smAndUp?'':'mt-4'">
                            <v-card-item-title class="title">Modules</v-card-item-title>
                            <v-list style="max-height: 500px" class="overflow-y-auto">
                                <simple-card v-for="(item,index) in plan.modules" :key="index" :title="item"></simple-card>
                            </v-list>
                            <v-card-content style="">
                            </v-card-content>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-text align="right">
                <back-button @click="goBack()" />
            </v-card-text>
        </v-card>
    </div>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import SimpleCard from "../workspaces/SimpleCard.vue";

export default {
    components: {
        BackButton,
        SimpleCard
    },
    data: () => ({
        plan: {
            name: "",
            modules: []
        },
        id: 0,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.plan = await this.getItem(appConstants.PLANS_API + "/" + this.id)
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
