<template>
<div style="display: flex;" class="align-center" v-if="user" :title="`${user.displayName}\n${user.value}`">
    <div class="pr-1">
        <v-avatar :size="size" class="base-inverted">
            <!-- <v-icon large dark>mdi-account</v-icon> -->
            <span class="white--text caption">{{initials}}</span>
        </v-avatar>
    </div>
    <div class="pa-1">
        <label v-if="!short" class="body-2">{{user.displayName}}</label><br>
    </div><br>
</div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object
        },
        short: {
            type: Boolean,
            default: () => false
        },
        size:{
            type: Number,
            default: 30
        }
    },
    computed: {
        initials() {
            if(this.user){
                let name = this.user.displayName.split(' ')
                if(name[1])
                    return name[0][0] + "" + name[1][0]
                else    
                    return name[0][0]
            }else{
                return ""
            }

        }
    },
}
</script>

<style lang="scss" scoped>
</style>
