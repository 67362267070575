<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Organizations"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addOrg" tooltip="Add Organization"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="organizationList" sort-by="orgName" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Organization'" @apply-filters="$refs.pagination.filterApi($event, 'Organization')" @clear-filters="readOrganizations(true)" />
        </template>
        <template align="right" v-slot:item.action="{ item }">
            <v-btn class="mr-2" fab x-small dark @click="$router.push('/organization/' + item._id)" v-if="isAllowed('viewOrgDetails')">
                <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn class="mr-2" fab x-small dark @click="$router.push('/organizations/' + item._id)" v-if="
            isAllowed('updateOrg') && item.orgName != 'Default Organization'
          ">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mr-2" fab x-small dark @click="deleteOrganization(item)" v-if="
            isAllowed('deleteOrg') && item.orgName != 'Default Organization'
          ">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="organizationList" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from '../../components/Pagination.vue';
export default {
    components: {
        Pagination
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Name",
                    value: "orgName",
                },
                {
                    text: "Description",
                    value: "description",
                },
                {
                    text: "Contact No.",
                    value: "contactNumber",
                },
                {
                    text: "Email",
                    value: "orgEmail",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            organizationList: [],
            api: appConstants.ORGANIZATIONS_API +
                "?fields=orgName,description,contactNumber,orgEmail",
        };
    },
    methods: {
        async deleteOrganization(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this organization ?",
                        appConstants.ORGANIZATIONS_API + "/" + item._id
                    )
                )
                    this.organizationList.splice(this.organizationList.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/organizations/0");
        },
        async readOrganizations(checkFilter) {
            this.organizationList = [];
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.organizationList = await this.$refs.pagination.init(this.api)
            } catch (error) {
                this.handleError(error);
            }
        }
    },
};
</script>

<style scoped>
</style>
