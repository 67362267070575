<template>
    <div>
        <v-select chips item-disabled="disabled" @change="$emit('update',selectedHeaders)" v-model="selectedHeaders" label="Select Columns to view" :items="items" multiple return-object clearable></v-select>
    </div>
</template>

<script>
import appConstants from '../utils/appConstants';
    export default {
        props: {
            headers: {
                type: Array,
                default: ()=>[]
            },
        },
        data() {
            return {
                items: [],
                selectedHeaders:[],
                excludeHeaders:['Operation','Customer','Process','Action','CreatedAt']
            }
        },
        mounted () {
            this.initComponent();
        },
        methods: {
            async initComponent() {
                this.headers.forEach(rec=>{
                    if(this.excludeHeaders.includes(rec.text))
                        rec.disabled=true
                    this.items.push(rec)
                })
                let customFields=(await this.getItem(appConstants.CUSTOM_FIELD_API)).data
                customFields.forEach(rec => {
                    this.items.push({text:rec.label,value:rec.field})
                });
                this.checkLocalHeader()
            },
            checkLocalHeader(){
                let headers=JSON.parse(localStorage.getItem('selectedOperationHeaders'))
                if(headers?.length>0){
                    headers.forEach(rec=>{
                        if(!this.excludeHeaders.includes(rec.text))
                            this.selectedHeaders.push(rec)
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>