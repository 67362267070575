<template>
    <div>
        <customer-form @success="goBack()" :id="$route.params.id" @cancel="goBack()"></customer-form>
    </div>
</template>

<script>
import CustomerForm from '../../components/CustomerForm.vue'
    export default {
        components: {
            CustomerForm,
        },
    }
</script>

<style lang="scss" scoped>

</style>