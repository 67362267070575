<template>
<div>
    <v-card height="100%">
        <v-card-text>
            <v-row justify="space-around">
                <v-col cols="8" align-self="center">
                    <center><label :style="themeFont" class="subtitle">User Task Update Count</label></center>
                </v-col>
                <v-col cols="4">
                    <v-select :items="filters" v-model="filter" @change="filterChanged($event)"></v-select>
                </v-col>
            </v-row>
            <v-data-table class="mt-2" :headers="userTaskUpdate" :items="taskStat">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.name }}</td>
                        <td align="right">{{ item.count }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import WidgetStatsCount from "../components/WidgetStatsCount.vue";
import appConstants from "@/utils/appConstants.js";
export default {
    components: {
        WidgetStatsCount,
    },
    data() {
        return {
            taskCount: 0,
            filter: "yesterday",
            filters: [{
                    text: 'Yesterday',
                    value: "yesterday"
                },
                {
                    text: 'Today',
                    value: "today"
                },
                {
                    text: "This Week",
                    value: "thisWeek"
                },
                {
                    text: "This Month",
                    value: "thisMonth"
                }
            ],
            userTaskUpdate: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Count",
                    value: "count",
                    align: "right"
                }
            ],
            taskStat:[]
        }
    },
    mounted() {
        this.filterChanged(this.filter);
    },
    methods: {
        async filterChanged(event) {
            let dateRange = {}
            switch (event) {
                case "yesterday":
                    dateRange = this.computeDateRange(-1, 0, 'day');;
                    break
                case "today":
                    dateRange = this.computeDateRange(0, 1, 'day');
                    break
                case "thisWeek":
                    dateRange = this.computeDateRange(0, 0, 'week');
                    break
                case "thisMonth":
                    dateRange = this.computeDateRange(0, 0, 'month');
                    break
                default:
                    dateRange = undefined;
                    break
            }
            let query = `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
            this.taskStat = await this.getItem(`${appConstants.STATS_API}/updated-task-count${query}`)
            //this.taskCount = taskStat.length > 0 ? taskStat[0].count : 0
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
