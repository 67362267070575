<template>
<v-row>
    <v-col v-for="item in customFields" :key="item._id" cols="12" :sm="customFields.length>1?'6':'12'">
        <v-text-field v-model="item['value']" v-if="item.field.type=='TEXT'" :label="item.field.label" :rules="item.required?fieldRequired:[]" :requried="item.required" />
        <v-text-field v-model="item['value']" v-else-if="item.field.type=='NUMERIC'" :label="item.field.label" type="number" :rules="item.required?fieldRequired:[]" :requried="item.required" />
        <v-autocomplete :multiple="item.multiSelect" v-model="item['value']" v-else :label="item.field.label" :items="item.field.values" :rules="item.required?fieldRequired:[]" :requried="item.required" />
    </v-col>
</v-row>
</template>

<script>
export default {
    props: {
        customFields: {
            type: Array,
            default: () => []
        }
    },

}
</script>

<style lang="scss" scoped>

</style>
