<template>
<div>
    <v-dialog v-model="dialog" persistent min-width="400px" :width="width">
        <v-card>
            <v-card-title>
                <center>
                    <span class="headline">
                        {{ title }}
                    </span>
                </center>
                <v-spacer></v-spacer>
                <div class="text-right">
                    <v-icon class="margin" @click="$emit('close')"> mdi-close </v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <alert-message :message="errorMessage" messageType="error" />
                <slot></slot>
                <alert-message :message="errorMessage" messageType="error" />
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import AlertMessage from "./AlertMessage.vue";
export default {
    components: {
        AlertMessage
    },
    props: {
        title: {
            type: String,
            default: () => ""
        },
        dialog: {
            type: Boolean,
            default: () => false
        },
        errorMessage: {
            type: String,
            default: () => ""
        },
        width: {
            type: String,
            default: () => "450px"
        }
    },
};
</script>

<style scoped>
.margin {
    float: right !important;
}
</style>
