<template>
<div>
    <v-card flat>
        <v-card-text>
            <center>
                <p class="text-h4">Role details</p>
            </center>
            <v-layout wrap>
                <v-flex sm6 xs12>
                    <row-view label="Name" v-bind:html="`<h3>${role.name}</h3>`"></row-view>
                    <row-view label="Description" v-bind:text="role.description"></row-view>
                    <row-view label="Level" v-bind:text="role.level"></row-view>
                    <view-meta-data colClass="col-sm-12" class="pr-6" :item="role"></view-meta-data>
                </v-flex>
                <v-flex sm6 xs12>
                    <v-card flat>
                        <v-card-item-title class="title pl-3">Permissions</v-card-item-title>
                        <v-list style="max-height: 500px" class="overflow-y-auto">
                            <simple-card v-for="(item,index) in permissions" :key="index" :title="item.name" :subtitle="item.description"></simple-card>
                        </v-list>
                        <v-card-content style="">
                </v-card-content>
                </v-card>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
    <v-card flat>
        <v-card-text align="right">
            <back-button @click="goBack()" />
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import SimpleCard from "../workspaces/SimpleCard.vue";

export default {
    components: {
    BackButton,
    SimpleCard
},
    data: () => ({
        role: {
            name: "",
            description: "",
            permissions: []
        },
        permissions:[],
        id: 0,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                await this.getItem(appConstants.ROLES_API + "/" + this.id).then(
                    (responce) => {
                        this.role = responce;
                    }
                );
                this.filterPermission()
            } catch (error) {
                this.handleError(error);
            }
        },
        async filterPermission(){
            let rolePermissions = this.role.permissions;
            let allPermissions=(await this.readPermissons(this.role)).allPermission
            let permissionSet=allPermissions.map(rec=>rec=rec.code)
            rolePermissions.forEach(rec=>{
                let index=permissionSet.indexOf(rec);
                if(index > -1)
                    this.permissions.push(allPermissions[index])
            })
        }
    },
};
</script>
