<template>
<v-navigation-drawer width="420" :temporary="showDrawer" right fixed clipped v-model="showDrawer">
    <center>
        <v-col class="text-right pr-8 my-2">
            <v-icon large @click="$emit('close')">mdi-close</v-icon>
        </v-col>
        <h2>Cart Items</h2>
        <plan-card class="my-4" v-if="$store.getters.planCart.length>0" :plans="$store.getters.planCart" :sm="8"></plan-card>
        <h3 class="my-4" v-else>Nothing to show here</h3>
        <v-btn v-if="$store.getters.planCart.length>0" class="text-center" @click="$emit('update')">Checkout</v-btn>
        <v-overlay v-if="loading" :model-value="loading" class="align-center justify-center">
            <v-progress-circular indeterminate :size="78" :width="7"></v-progress-circular>
        </v-overlay>
    </center>
</v-navigation-drawer>
</template>

<script>
import PlanCard from '@/components/PlanCard'
export default {
    props: {
        drawer: {
            type: Boolean,
            default: () => false
        },
        loading: {
            type: Boolean,
            default: () => false
        },
    },
    components: {
        PlanCard
    },
    computed: {
        showDrawer: {
            get(){
                return this.drawer
            },
            set(newValue){
                return newValue
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
