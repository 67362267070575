<template>
<div>
    <center>
        <h2>{{formTitle}}</h2>
    </center>
    <v-card flat>
        <v-card-text>
            <alert-message :message="msg" :messageType="msgType" />
            <v-form ref="form" v-model="valid" :lazy-validation="true">
                <v-row class="ma-2">
                    <v-col>
                        <v-text-field label="Template Title" v-model="template.title" :rules="notEmpty" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Default Fields</th>
                                    <th>Required</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody v-if="operationFields.length>0">
                                <tr v-for="(fields, index) in operationFields" :key="index">
                                    <td>{{ fields.defaultField }}</td>
                                    <td>
                                        <v-icon v-if="fields.required" class="active-icon">mdi-check-circle-outline</v-icon>
                                        <v-icon v-if="!fields.required" class="deactive-icon">mdi-minus</v-icon>
                                    </td>
                                    <td>
                                        <v-icon v-if="fields.required">mdi-minus</v-icon>
                                        <v-icon @click="removeOptionalfield(fields)" v-if="!fields.required">mdi-close</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row class="mt-10" v-if="optionalFields.length>0">
                    <v-col>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Removed Fields</th>
                                    <th></th>
                                    <th>Add</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(fields, index) in optionalFields" :key="index">
                                    <td>{{ fields.defaultField }}</td>
                                    <td></td>
                                    <td>
                                        <v-icon v-if="fields.required">mdi-minus</v-icon>
                                        <v-icon @click="addOptionalfield(fields)" v-if="!fields.required">mdi-plus</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row class="ma-2" v-if="showCustomField">
                    <v-col>
                        <v-autocomplete v-model="selectedFields" @change="updateFields()" :items="customFields" label="Custom Fields" item-text="label" multiple return-object />
                    </v-col>
                </v-row>
                <v-row class="ma-2" v-if="template.fields?.length>0">
                    <v-col>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Field Name</th>
                                    <th>Required</th>
                                    <th>Multiple values</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody v-if="template.fields.length>0">
                                <tr v-for="(item, index) in template.fields" :key="index">
                                    <td>{{ item.field.label }}</td>
                                    <td>
                                        <v-checkbox v-model="item.required"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-if="item.field.type=='PREDEFINED'" v-model="item.multiSelect"></v-checkbox>
                                        <v-icon v-else>mdi-minus</v-icon>
                                    </td>
                                    <td>
                                        <v-icon @click="removeField(index)">mdi-close</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-form>
            <alert-message :message="msg" :messageType="msgType" />
            <div align="right">
                <form-buttons :valid="valid" @cancel="goBack()" @submit="validate()" :loading="loading"></form-buttons>
            </div>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '../../utils/appConstants';
import FormButtons from "@/components/FormButtons.vue";
export default {
    components: {
        FormButtons
    },
    data() {
        return {
            showCustomField: false,
            customFields: [],
            selectedFields: [],
            template: {
                title: '',
                type: ''
            },
            loading: false,
            valid: true,
            formTitle: 'Add Template',
            optionalFields:[]
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            this.template.type = appConstants.TEMPLATE.TYPE.OPERATION
            this.showCustomField = this.checkModule('CustomFields') != null
            this.customFields = (await this.getItem(appConstants.CUSTOM_FIELD_API)).data
            if (this.id != 0) {
                this.formTitle = "Edit Template";
                this.template = await this.getItem(appConstants.TEMPLATE_API + "/" + this.id)
                this.optionalFields=this.operationFields.filter((rec) => !this.template.selectedFields.some((item) => item.defaultField === rec.defaultField))
                this.operationFields=this.template['selectedFields']
                this.template.fields.forEach(rec => {
                    this.customFields.forEach(data => {
                        if (data._id == rec.field._id)
                            this.selectedFields.push(data)
                    })
                })
            }
        },
        updateFields() {
            this.template['fields'] = []
            this.selectedFields.forEach(rec => {
                this.template.fields.push({
                    field: rec,
                    required: false
                })
            })
        },
        removeField(index) {
            if (this.selectedFields.length > 0)
                this.selectedFields.splice(index, 1)
            this.template.fields.splice(index, 1)
        },
        async validate() {
            this.msg = ''
            this.loading = true
            try {
                if (this.$refs.form.validate()) {
                    this.template['selectedFields']=this.operationFields
                    if (this.id == 0)
                        this.template = await this.postItem(appConstants.TEMPLATE_API, this.template)
                    else
                        this.template = await this.putItem(appConstants.TEMPLATE_API + "/" + this.template._id, this.template)
                }
                this.loading = false
                this.$router.push({
                    path: "/templates"
                });
            } catch (error) {
                this.loading = false
                this.handleError(error);
            }
        },
        removeOptionalfield(field) {
            this.optionalFields.push(field)
            this.operationFields.splice(this.operationFields.findIndex(rec => rec.defaultField == field.defaultField), 1)
        },
        addOptionalfield(field){
            this.operationFields.push(field)
            this.optionalFields.splice(this.optionalFields.findIndex(rec => rec.defaultField == field.defaultField), 1)
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
