<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Plans"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="$router.push('/plans/0')" permission="addPlan" tooltip="Add Plans"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="plans" sort-by="name" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Plan'" @apply-filters="$refs.pagination.filterApi($event, 'Plan')" @clear-filters="initComponent(true)" />
        </template>
        <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission) ? true : false" @click="icon.name == 'mdi-delete'
                    ? deletePlan(item)
                    : performAction(item._id, icon)">
                </action-button>
            </template>
        </template>
        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
            <simple-card @view="$router.push('/plan/'+item._id)" @update="$router.push('/plans/'+item._id)" 
                @delete="deletePlan(item)" :title="item.name" :subtitle="`Users: ${item.userSize}`" :description="`Disk Space: ${item.diskSpace}`" :actions="icons">
            </simple-card>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="plans" :api="api" />
</div>
</template>

    
<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from '../../components/Pagination.vue';
import SimpleCard from '../workspaces/SimpleCard.vue'
export default {
    components: {
        Pagination,
        SimpleCard
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/plan/",
                    permission: "viewPlan",
                    event: 'view'
                },
                {
                    name: "mdi-pencil",
                    path: "/plans/",
                    permission: "updatePlan",
                    event: 'update'
                },
                {
                    name: "mdi-delete",
                    permission: "deletePlan",
                    event: 'delete'
                },
            ],
            headers: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Users",
                    value: "userSize",
                    align: "right",
                },
                {
                    text: "Disk Space (Gb)",
                    value: "diskSpace",
                    align: "right",
                },
                {
                    text: "Type",
                    value: "type",
                },
                {
                    text: "Cost (₹)",
                    value: "cost",
                    align: "right",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            plans: [],
            api: appConstants.PLANS_API,
        };
    },
    methods: {
        async initComponent(checkFilter) {
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.plans = await this.$refs.pagination.init(this.api)
            } catch (error) {
                this.handleError(error);
                this.msg = error.response;
            }
        },
        async deletePlan(item) {
            try {
                await this.deleteItem("Are you sure you want to delete?",appConstants.PLANS_API + "/" + item._id);
                this.plans.splice(this.plans.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
