<template>
    <div :class="$vuetify.breakpoint.smAndUp?'mx-8 my-2 center-align':''">
        <v-row align="center" v-if="$vuetify.breakpoint.smAndUp">
            <v-col class="px-1" cols="3" sm="1" v-for="(item, index) in computedField" :key="index">
                <v-autocomplete :prepend-icon="index==0?'mdi-filter-variant':''" :items="selectedList[item.text]" v-model="selectedFilter[item.value]" dense filled :label="item.text" multiple></v-autocomplete>
            </v-col>
            <v-col cols="3" class="mt-n2 d-flex" sm="1">
                <v-btn dark @click="createFilter()" class="base-inverted">Apply</v-btn>
                <v-btn text @click="clearFilter()" outlined class="ml-2 base">
                    Clear Filters
                </v-btn>
            </v-col>
            <v-col cols="1" class="ml-n10">
            </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.xsOnly">
            <v-col class="text-right">
                <v-btn @click="dialog=true" text class="my-3">
                    <v-badge :value="$store.getters.filter" color="pink" dot offset-y="8" offset-x="5">
                        <v-icon medium>mdi-filter-variant</v-icon>
                    </v-badge>
                </v-btn>
            </v-col>
        </v-row>
        <dialog-box :dialog="dialog" title="Filters" @close="dialog=false">
            <v-row>
                <v-col class="px-1" cols="6" sm="1" v-for="(item, index) in computedField" :key="index">
                    <v-autocomplete :items="selectedList[item.text]" v-model="selectedFilter[item.value]" dense filled :label="item.text" multiple></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-right">
                    <v-btn dark @click="createFilter()" small class="mr-1 base-inverted">Apply</v-btn>
                    <v-btn text @click="clearFilter()" small outlined class="base">Clear Filters</v-btn>
                </v-col>
            </v-row>
        </dialog-box>
    </div>
    </template>
    
    <script>
    import appConstants from '../utils/appConstants'
    export default {
        props: {
            fields: {
                type: Array,
                default: () => []
            },
            entity: {
                type: String,
                default: () => ''
            },
            textFilterLabel: {
                type: String
            },
            preset: {
                type: Object
            }
        },
        data() {
            return {
                list: {},
                selectedFilter: {},
                selectedList: {},
                dialog: false
            }
        },
        computed: {
            computedField() {
                let excludedFields = ['Action','Sr.No']
                this.fields.map(rec=>{
                    if(rec.type==Object && !rec.value.includes('.displayName'))
                        rec.value=rec.value+'.displayName'
                })
                return this.fields.filter(rec=> (rec.type!=Date && !excludedFields.includes(rec.text)))
            },
        },
        async mounted() {
            await this.checkHistory()
        },
        methods: {
            async loadData() {
                let payload = {}
                payload['entity'] = this.entity
                payload['fields'] = this.fields
                if(this.entity=='Employee'||this.entity=='Customer')
                    payload['entity']='Account'
                if(this.entity=='Document')
                    payload['entity']='Media'
                payload['accountType']=this.entity=='Employee'?appConstants.ACCOUNT.TYPE.EMPLOYEE:this.entity=='Customer'?{$in:[appConstants.ACCOUNT.TYPE.B2B,appConstants.ACCOUNT.TYPE.B2C,appConstants.ACCOUNT.TYPE.AGENT]}:undefined
                let disctictFields = await this.postItem(appConstants.FILTER_API + "/listData", payload)
                disctictFields.forEach(rec => {
                    this.list[rec.text] = rec.value
                })
                this.selectedList = this.list
            },
            async checkHistory() {
                let filterHistory = this.$store.getters.filter
                if(filterHistory?.length>0)
                    filterHistory=filterHistory.filter(rec=>rec.entity==this.entity)
                if (filterHistory[0] ?.entity == this.entity)
                    this.selectedFilter = filterHistory[0].appliedFilters
                else if (this.preset && Object.entries(this.selectedFilter).length==0)
                    this.selectedFilter = this.preset
                await this.loadData();
                if (Object.entries(this.selectedFilter).length > 0)
                    this.createFilter()
            },
            createFilter() {
                if(this.dialog)
                    this.dialog=false
                let filters = this.createFilterPayload(this.selectedFilter)
                this.updateFilterHistory()
                this.$emit('apply-filters', filters)
            },
            updateFilterHistory(){
                let filterHistory=this.$store.getters.filter
                let index=filterHistory.findIndex(rec=>rec.entity==this.entity)
                if(index>-1){
                    let oldFilter=JSON.parse(localStorage.getItem('filterHistory'))||[]
                    filterHistory[index]['newFilter']=oldFilter.length>0?(JSON.stringify(oldFilter[index].appliedFilters)!=JSON.stringify(this.selectedFilter)):true
                    filterHistory[index]['appliedFilters']=this.selectedFilter
                }
                else
                    filterHistory.push({entity: this.entity,appliedFilters: this.selectedFilter,presetFilter: this.preset,pageNo:filterHistory.pageNo||1,newFilter:true})
                this.$store.dispatch("actionUpdateFilter",filterHistory)
            },
            clearFilter() {
                if(this.dialog)
                    this.dialog=false
                let filters=this.$store.getters.filter
                let index=filters.findIndex(rec=>rec.entity==this.entity)
                if(index>-1){
                    filters.splice(index,1)
                    this.$store.dispatch("actionUpdateFilter",filters);
                }
                this.selectedFilter = {}
                this.$emit('clear-filters')
            }
        },
    }
    </script>
    
    <style lang="scss" scoped>
    </style>
    