<template>
<v-app>
    <component :is="layout">
        <v-main>
            <update-notification></update-notification>
            <enable-notification></enable-notification>
            <router-view />
        </v-main>
    </component>
    <notifications :duration="5000" width="450" position="top right">
        <template slot="body" slot-scope="props">
            <div class="custom-template">
                <div class="mx-2">
                    <v-img height="50px" width="50px" :src="logoImage"></v-img>
                </div>
                <div class="custom-template-content">
                    <div class="custom-template-title">
                        {{props.item.title}}
                    </div>
                    <div v-html="props.item.text"></div>
                </div>
                <div class="custom-template-close" @click="props.close">
                    <v-icon>mdi-close</v-icon>
                </div>
            </div>
        </template>
    </notifications>
</v-app>
</template>

<script>
import EnableNotification from "./components/EnableNotification.vue"
import UpdateNotification from './components/UpdateNotification.vue'

export default{
    name: 'App',
    components: { EnableNotification, UpdateNotification },
    computed: {
        layout() {
            return (this.$route.meta.layout || 'default') + '-layout'
        },
    },
    data: () => ({
        //
    }),
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans&display=swap');

.container-padding {
    padding: 1% 20px;
}

.custom-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #C5C5C5;
    border: 2px solid #fcfdfc;
}

.custom-template-content {
    padding: 10px;
    flex: 1 0 auto;
}

.custom-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
}

.custom-template-close {
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.2;
    cursor: pointer;
}
</style>
