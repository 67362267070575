<template>
<div>
    <heading title="Tasks" >
        <v-btn-toggle v-model="taskPriority" class="elevation-2">
            <v-btn small>
                <v-icon>mdi-table-of-contents</v-icon>
            </v-btn>
            <v-btn small>
                <v-icon>mdi-priority-high</v-icon>
            </v-btn>
        </v-btn-toggle>
        <export-to class="ml-2" entity="Task" apiCondition="completed=false" :apiCall="api" :fields="headers" fileName="Tasks"/>
    </heading>
    <filters ref="filter" :fields="headers" :entity="'Task'" :textFilterLabel="'Search Tasks'" :textFilter="'name'" :preset="defaultPreset()" @apply-filters="$refs.pagination.filterApi($event, 'Task')" @clear-filters="$refs.filter.checkHistory()" />
    <v-chip v-if="chipFilter" class="ma-2" close @click:close="removeFilter()">
        {{ chipFilter }}
    </v-chip>
    <template v-if="taskPriority">
        <v-expansion-panels class="ma-4">
            <v-expansion-panel v-for="(priority,i) in priorityList" :key="i">
                <v-expansion-panel-header>
                    <v-row>
                        <v-col sm="11">
                            {{priority}} 
                        </v-col>
                        <v-col sm="1">{{ (list.filter(rec=>rec.priority==priority)).length}}</v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :loading="loading" :headers="headers" :items="list" sort-by="actionDate" :search="search" :items-per-page="-1" hide-default-footer :hide-default-header="$vuetify.breakpoint.smAndDown">
                        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.mdAndUp">
                            <template v-if="item.priority==priority">
                                <tr>
                                    <td :class="priorityClass(item)" class="link" @click="$router.push('tasks/'+item._id)"> {{ item.name }}</td>
                                    <td> {{ item.parent.displayName }}</td>
                                    <td>
                                        <user-card :user="item.assignedTo" :short="false"></user-card>
                                    </td>
                                    <td>
                                        <show-date :date="item.actionDate"></show-date>
                                    </td>
                                    <td>
                                        <show-date :date="item.dueDate"></show-date>
                                    </td>
                                    <td> {{ item.type }}</td>
                                    <td align="right">{{item.complted}}
                                        <status :label="item.completed ? 'Completed' : 'Pending'" :block="false"></status>
                                    </td>
                                    <td align="center">
                                        <assign-employee v-model="item.assignedTo" @change="assignEmployeeToTask(item, $event)" :empList="employeeList" v-if="(isAllowed('assignEmployeeToTask')&& !item.completed)"></assign-employee>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <template v-slot:item="{ item }" v-else>
                            <template v-if="item.priority==priority">
                                <task-card @change="assignEmployeeToTask(item, $event)" :task="item" style="margin:1%"></task-card>
                                <v-divider></v-divider>
                            </template>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </template>
    <v-container fluid v-else>
        <v-data-table :loading="loading" :headers="headers" :items="list" sort-by="actionDate" class="elevation-10" :search="search" :items-per-page="-1" hide-default-footer :hide-default-header="$vuetify.breakpoint.smAndDown">
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.mdAndUp">
                <tr>
                    <td :class="priorityClass(item)" class="link" @click="$router.push('tasks/'+item._id)"> {{ item.name }}</td>
                    <td> {{ item.parent.displayName }}</td>
                    <td>
                        <user-card :user="item.assignedTo" :short="false"></user-card>
                    </td>
                    <td>
                        <show-date :date="item.actionDate"></show-date>
                    </td>
                    <td>
                        <show-date :date="item.dueDate"></show-date>
                    </td>
                    <td> {{ item.type }}</td>
                    <td align="right">{{item.complted}}
                        <status :label="item.completed ? 'Completed' : 'Pending'" :block="false"></status>
                    </td>
                    <td align="center">
                        <assign-employee v-model="item.assignedTo" @change="assignEmployeeToTask(item, $event)" :empList="employeeList" v-if="(isAllowed('assignEmployeeToTask')&& !item.completed)"></assign-employee>
                    </td>
                </tr>
            </template>
            <template v-slot:item="{ item }" v-else>
                <task-card @change="assignEmployeeToTask(item, $event)" :task="item" style="margin:1%"></task-card>
                <v-divider></v-divider>
            </template>
        </v-data-table>
    </v-container>
    <pagination ref="pagination" v-model="list" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import DialogBox from "../../components/DialogBox.vue";
import Heading from "../../components/Heading.vue";
import SearchBar from "../../components/SearchBar.vue";
import Pagination from "../../components/Pagination.vue";
import Status from "../../components/Status.vue";
import ShowDate from "../../components/ShowDate.vue";
import TaskCard from "../../components/TaskCard.vue";
import AssignEmployee from "../../components/AssignEmployee.vue";
import UserCard from '../../components/UserCard.vue';
import moment from 'moment'
import ExportTo from '../../components/ExportTo.vue'
export default {
    components: {
        DialogBox,
        Heading,
        SearchBar,
        Pagination,
        Status,
        ShowDate,
        TaskCard,
        AssignEmployee,
        UserCard,
        ExportTo
    },
    data() {
        return {
            chipFilter: "",
            taskPriority: false,
            filterURL: false,
            notEmpty: [(v) => !!v || "Cannot be Empty"],
            dialog: false,
            valid: false,
            search: "",
            tableHeader: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Operation",
                    value: "parent.displayName",
                },
                {
                    text: "Assigned To",
                    value: "assignedTo.displayName",
                },
                {
                    text: "Action Date",
                    value: "actionDate",
                    type: Date
                },
                {
                    text: "Due Date",
                    value: "dueDate",
                    type: Date
                },
                {
                    text: "Type",
                    value: "type",
                },
                {
                    text: "Status",
                    value: "completed",
                    align: "right",
                },
                {
                    text: "Action",
                    value: "",
                    align: "right",
                }
            ],
            taskList: [],
            allTasks: [],
            employeeList: [],
            selectedEmployee: {},
            task: {},
            id: 0,
            empId: 0,
            list: [],
            fields: "name,actionDate,dueDate,completed,parent,assignedTo,priority,type",
            query: "/api/tasks/",
            myTask: [],
            loading: false,
            taskCondition: '',
            oldList: [],
            priorityList: ["HIGH", "MEDIUM", "LOW"]
        };
    },
    async mounted() {
        window.task = this
        this.chipFilter = this.$route.query.type;
        this.loading = true
        this.empId = JSON.parse(localStorage.getItem("profile")).accountId;
        this.employeeList = await this.getEmployees()
        this.loading = false
    },
    beforeUpdate() {
        if (this.$route.query.type)
            this.loadChipFilterData()
    },
    computed: {
        headers() {
            return this.tableHeader;
        },
        items: function () {
            if (this.isAllowed("assignEmployeeToTask")) this.tabs.push("Orphan");
            if (this.isAllowed("listAllTasks")) this.tabs.push("All");
            return this.tabs;
        },
        api() {
            return this.query;
        }
    },
    methods: {
        async removeFilter() {
            this.chipFilter = null;
            this.$router.replace("/tasks");
            this.$refs.filter.clearFilter()
            this.list = this.oldList
        },
        openDialog(task) {
            this.task = task;
            this.dialog = true;
        },
        viewTask(value) {
            this.$router.push("/tasks/" + value._id);
        },
        priorityClass(item) {
            if (item.priority == 'HIGH') return 'priority-high'
            if (item.priority == 'MEDIUM') return 'priority-medium'
            if (item.priority == 'LOW') return 'priority-low'
        },
        defaultPreset() {
            return {
                'assignedTo.displayName': [this.$store.getters.profile.name],
                'completed': [false]
            }
        },
        async loadChipFilterData() {
            if (this.list.length > 0) {
                this.oldList = this.list
                if (this.chipFilter == appConstants.TASK.MY_OVERDUE_TASK) {
                    this.updateFilterPreset(this.defaultPreset())
                    this.list = this.list.filter(rec => moment(rec.dueDate).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD"))
                } else if (this.chipFilter == appConstants.TASK.MY_TASK_DUE_TODAY) {
                    this.updateFilterPreset(this.defaultPreset())
                    this.list = this.list.filter(rec => moment(rec.dueDate).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD"))
                } else if (this.chipFilter == appConstants.TASK.OVERDUE_TASK) {
                    this.updateFilterPreset({
                        'completed': [false]
                    })
                    this.list = this.list.filter(rec => moment(rec.dueDate).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD"))
                } else if (this.chipFilter == appConstants.TASK.TODAY_DUE_TASK) {
                    this.updateFilterPreset({
                        'completed': [false]
                    })
                    this.list = this.list.filter(rec => moment(rec.dueDate).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD"))
                } else if (this.chipFilter == appConstants.TASK.ACTIVE_TASK)
                    this.updateFilterPreset({
                        'completed': [false]
                    })
                else if (this.chipFilter == appConstants.TASK.ORPHAN_TASK)
                    this.updateFilterPreset({
                        'assignedTo': [null],
                        'completed': [false]
                    })
                else if (this.chipFilter == appConstants.TASK.MY_TASKS)
                    this.updateFilterPreset(this.defaultPreset())
            }
        },
        updateFilterPreset(condition) {
            let filters = this.$store.getters.filter
            filters.forEach(rec => {
                if (rec.entity == "Task")
                    rec.appliedFilters = condition
            })
            this.$store.dispatch("actionUpdateFilter", filters);
            this.$refs.filter.checkHistory()

        }
    },
};
</script>

<style scoped>
    </style>
