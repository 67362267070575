<template>
    <v-btn :small="$vuetify.breakpoint.smAndDown" class="mr-2 align-right"  @click="$emit('click')">{{buttonTitle()}}</v-btn>
</template>
<script>
export default {
  props: ["title"],
  methods: {
    buttonTitle() {
      return this.title ? this.title : "Back"
    }
  },
};
</script>