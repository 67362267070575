<template>
<v-container>
    <v-card elevation="7">
        <v-card-text class="apply-font">
            <v-form ref="form" v-model="valid" lazy-validation>
                <alert-message :message="msg" :messageType="msgType" />
                <br />
                <!-- <center>
                    <v-card width="250">
                        <v-img height="250" :src="showBase64Logo"></v-img>
                        <v-card-text v-if="logo1!='/img/default-logo.dfef5b58.png'" class="ml-10">
                            <v-btn right top absolute fab @click="discardLogo()">
                                <v-icon :color="secondaryColor">mdi-close</v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <file-upload :showChip="false" label="Update Logo" v-model="Logo"></file-upload>
                </center>
                <br /> -->
                <h3>Organization Details</h3>
                <br />
                <v-row>
                    <v-col>
                        <v-text-field label="Name" v-model="organization.orgName" outlined></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Slug" v-model="organization.slug" disabled outlined></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Contact Number" v-model="organization.contactNumber" outlined></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Email" v-model="organization.orgEmail" disabled outlined></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Description" v-model="organization.description" outlined></v-text-field>
                    </v-col>
                    <v-col v-if="organization.addresses && organization.addresses.length!=0">
                        <v-text-field label="Address" v-model="organization.addresses[0].address" outlined></v-text-field>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col>
                        <h3 class="ml-3">Theme Color</h3>
                        <br />
                        <v-color-picker class="ma-2" v-model="setting.theme.primaryColor" hide-inputs></v-color-picker>
                    </v-col>
                    <v-col>
                        <h3 class="ml-3">Font Color</h3>
                        <br />
                        <v-color-picker class="ma-2" v-model="setting.theme.secondaryColor" hide-inputs></v-color-picker>
                    </v-col>
                    <v-col>
                        <h3 class="mb-1">Font Family</h3>
                        <br />
                        <font-picker :api-key="'AIzaSyBNXcMc1_6l7AR56V4kY-h_yDnQnMr0ND4'" :options="options" :active-font="fontFamily" @change="myFunc"></font-picker>
                    </v-col>
                </v-row> -->
                <v-divider class="my-4"/>
                <h3>Basic Settings</h3><br>
                <v-row>
                    <v-col>
                        <v-checkbox label="Send Task Assignment Mail" v-model="setting.sendTaskAssignmentMail"></v-checkbox>
                    </v-col>
                </v-row>
            </v-form><br />
            <alert-message :message="msg" :messageType="msgType" />
            <form-buttons :valid="valid" @submit="validate()" @cancel="goBack()"></form-buttons>
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import appConstants from "@/utils/appConstants.js";
import FileUpload from '@/components/FileUpload.vue';
export default {
    components: {
        FormButtons,
        FileUpload
    },
    data() {
        return {
            media: {},
            Logo: [],
            fontFamily: "Open Sans",
            options: {},
            valid: true,
            removeLogo: false,
            logo1: "",
            setting: {
                logo: {
                    _id: "",
                    fileName: "",
                    url: ""
                },
                theme: {
                    primaryColor: "",
                    secondaryColor: "",
                    font_family: "",
                    font_size: 12,
                },
                sendTaskAssignmentMail:false
            },
            id: null,
            organization: JSON.parse(localStorage.getItem("orgDetails"))
        };
    },
    computed: {
        showBase64Logo() {
            if (this.Logo.length != 0) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.logo1 = event.target.result
                    this.removeLogo = false
                }
                reader.readAsDataURL(this.Logo)
            }
            return this.logo1
        },
    },
    mounted() {
        this.initializeSetting();
        this.id = this.$route.params.id;
        this.logo1 = this.logoImage
    },
    methods: {
        discardLogo() {
            this.removeLogo = true;
            this.Logo = []
            this.logo1 = require("@/assets/default-logo.png")
            console.log(this.logo1);
        },
        myFunc(event) {
            this.fontFamily = this.setting.theme.font_family = event.family;
            console.log(event)
        },
        initializeSetting() {
            if (this.store.setting) {
                this.setting = this.store?.setting
            }
            if(!this.setting.theme){
                this.setting.theme = {}
            }
            this.setting.theme.primaryColor = this.primaryColor
            this.setting.theme.secondaryColor = this.secondaryColor
            this.fontFamily = this.setting.theme.font_family = this.font_family
            this.setting.sendTaskAssignmentMail=this.store?.setting?.sendTaskAssignmentMail 
        },
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.removeLogo == true) {
                        await this.deleteItem("you want to change your logo", appConstants.MEDIA_API + "/" + this.store.setting.logo._id)
                        this.setting.logo._id = ""
                        this.setting.logo.fileName = ""
                        this.setting.logo.url = ""
                    }
                    if (this.Logo.length != 0) {
                        if (this.store.setting) {
                            if (this.Logo.name != this.store.setting.logo.fileName && this.store.setting.logo.fileName) {
                                var flag = await this.deleteItem("you want to change your logo", appConstants.MEDIA_API + "/" + this.store.setting.logo._id)
                            }
                        }
                        if (flag || (!this.store.setting || !this.store.setting.logo.fileName)) {
                            let formData = new FormData()
                            formData.append('file', this.Logo)
                            this.media = await this.postItem(
                                appConstants.MEDIA_API + "/upload?resize=true", formData, {
                                    headers: {
                                        'Content-type': 'multipart/form-data'
                                    }
                                }
                            );
                            this.setting.logo._id = this.media._id
                            this.setting.logo.fileName = this.media.filename
                            this.setting.logo.url = this.media.path
                        }
                    }
                    await this.putItem(
                        appConstants.ORGANIZATIONS_API + "/" + this.organization._id,
                        this.organization
                    );
                    await this.patchItem(
                        appConstants.ORGANIZATIONS_API +
                        "/" +
                        this.organization._id +
                        "/settings",
                        this.setting
                    );
                    this.organization.setting = this.setting
                    Object.assign(this.organization.setting, this.setting);
                    this.$store.dispatch("actionUpdateOrgSetting", this.organization)
                    this.$router.push({
                        path: "/settings",
                    });
                } catch (error) {
                    console.log(error);
                    this.handleError(error);
                    this.msg = error.response.data.details
                }
            }
        },
    },
};
</script>

<style scoped>
.v-btn--fab.v-size--default {
    height: 35px;
    width: 32px;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
    top: -19px;
}

.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
    right: -8px;
}
</style>
