var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"4","sm":"10"}},[_c('heading',{attrs:{"title":"Roles"}})],1),_c('v-col',{staticClass:"text-right mt-4 ml-n6",attrs:{"cols":"8","sm":"2"}},[_c('add-btn',{attrs:{"permission":"addRole","tooltip":"Add role"},on:{"click":function($event){return _vm.add()}}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.roleList,"sort-by":"name","search":_vm.search,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('filters',{ref:"filter",attrs:{"fields":_vm.headers,"entity":'Role'},on:{"apply-filters":function($event){return _vm.$refs.pagination.filterApi($event, 'Role')},"clear-filters":function($event){return _vm.readRoles(true)}}})]},proxy:true},{key:"item.action",fn:function({ item }){return [(_vm.isAllowed('viewRoleDetails'))?_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.$router.push('/role/' + item._id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.isAllowed('updateRole') && _vm.checkUserRole(item))?_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.$router.push('/roles/' + item._id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(
            _vm.isAllowed('deleteRole') &&
            item.name != 'CUSTOMER' &&
            item.name != 'ADMIN' &&
            item.name != 'EMPLOYEE' &&
            item.name != 'SYSTEM_ADMIN'
          )?_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteRole(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])}),_c('pagination',{ref:"pagination",attrs:{"api":_vm.api},model:{value:(_vm.roleList),callback:function ($$v) {_vm.roleList=$$v},expression:"roleList"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }