<template>
<v-card flat>
    <v-card-text>
        <alert-message :message="msg" :messageType="msgType" />
        <v-form ref="form" v-model="valid" lazy-validation>
            <center>
                <h2>{{ formTitle }}</h2>
            </center>
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model.trim="plan.name" :rules="fieldRules" label="Plan name* :" required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model.trim="plan.cost" :rules="fieldRules" label="Plan cost* :" required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-switch inset v-model="plan.trialPlan" label="Trial Plan"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model="plan.userSize" :rules="fieldRules" label="Total Users*" required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="plan.diskSpace" :rules="fieldRules" label="Disk Space* (in Gb)" required></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-select :disabled="id!=0" v-model="plan.type" :items="planTypes" label="Type"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model="plan.validityInDays" label="Validity (in Days)"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-select :disabled="id!=0" v-model="plan.frequency" item-text="text" item-value="value" :items="frequency" label="Frequency"></v-select>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field  :disabled="id!=0" v-model="plan.interval" label="Interval between each Payment"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card flat>
                        <h3>Modules* :</h3>
                        <v-card-text>
                            <h3 class="mt-4">Basic :</h3>
                            <v-row dense>
                                <v-col v-for="record in basicModules" :key="record.text" md="3" cols="12" sm="3">
                                    <v-switch disabled inset v-model="plan.modules" :label="record.text" :value="record.text"></v-switch>
                                </v-col>
                            </v-row>
                            <h3 class="mt-4">Add Ons :</h3>
                            <v-row dense>
                                <v-col v-for="record in addOns" :key="record.text" md="3" cols="12" sm="4">
                                    <v-switch inset v-model="plan.modules" :label="record.text" :value="record.text"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <alert-message :message="msg" :messageType="msgType" />
            </v-card>
            <form-buttons :valid="valid" @submit="submit()" @cancel="goBack()"></form-buttons>
        </v-form>
    </v-card-text>
</v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import permissionCard from "@/components/PermissionCard.vue";
import SwitchButton from '@/components/SwitchButton.vue';
export default {
    components: {
        FormButtons,
        AlertMessage,
        permissionCard,
        SwitchButton
    },
    data() {
        return {
            valid: true,
            plan: {
                modules: [],
                trialPlan:false
            },
            fieldRules: [v => !!v || "Required Field"],
            formTitle: "",
            addOns: [],
            basicModules: [],
            planTypes:['BASIC','ADD_ON'],
            frequency:[{
                text:'Weekly',
                value:'weekly'
            },{
                text:'Monthly',
                value:'monthly'
            },{
                text:'Yearly',
                value:'yearly'
            }],
            id:0
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            if (this.id != 0) {
                this.formTitle = "Edit Plan";
                try {
                    this.plan = await this.getItem(appConstants.PLANS_API + "/" + this.id);
                } catch (error) {
                    this.handleError(error);
                }
            } else {
                this.formTitle = "Add New Plan";
            }
            this.readModules()
        },
        async submit() {
            if (this.$refs.form.validate()) {
                if(this.plan.type=='ADD_ON')
                    this.plan.modules=this.plan.modules.filter(rec=>!this.basicModules.some(val=>val.text==rec))
                try {
                    if (this.id == 0)
                        this.plan = await this.postItem(appConstants.PLANS_API, this.plan);
                    else
                        this.plan = await this.putItem(appConstants.PLANS_API + "/" + this.id, this.plan)
                    this.$router.push({
                        path: "/plans"
                    });
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
        async readModules() {
            this.appDrawerItems.forEach((rec) => {
                if(this.requiredModules.includes(rec.text))
                    this.basicModules.push(rec)
                else{
                    if(!this.unrequiredModules.includes(rec.text))
                        this.addOns.push(rec)
                }
            });
            if(this.plan.modules.length==0){
                this.plan.modules=this.basicModules.map(rec=>rec.text)
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
