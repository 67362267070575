<template>
  <div>
    <heading title="Documents"></heading>
    <v-data-table :headers="headers" :items="serialNo" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
      <template v-slot:top>
        <filters ref="filter" :fields="headers" :entity="'Document'" @apply-filters="$refs.pagination.filterApi($event, 'Document')" @clear-filters="initialize(true)" />
      </template>
      <template v-slot:item.parent?.name="{item}" v-if="$vuetify.breakpoint.mdAndUp">
        <label class="link" @click="$router.push(item.parent.type.toLowerCase()+'/'+item.parent._id)">{{item.parent.name}}</label>
      </template>
    </v-data-table>
    <pagination ref="pagination" v-model="documents" :api="api"/>
  </div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from "../../components/Pagination.vue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      operations: [],
      documents: [],
      search:"",
      headers: [
          {
              text:"Sr.No",
              value:"sno"
          },
        {
          text: "Document Name",
          value: "filename",
        },
        {
          text: "Attached To",
          value: "parent.name",
        },
        {
          text:"Type",
          value:"parent.type"
        }
      ],
      api:appConstants.MEDIA_API
    };
  },
  computed: {
      serialNo() {
          return this.documents?.map((d,index)=>({...d,sno:index+1})) 
      }
  },
  methods: {
    async initialize(checkFilter) {
      if(checkFilter)
        this.$refs.filter.checkHistory()
      this.documents=await this.$refs.pagination.init(this.api)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>