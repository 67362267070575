<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Workspaces"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addWorkspace" tooltip="Add Workspaces"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="workspaces" sort-by="name" :search="search" :items-per-page="-1" hide-default-footer hide-default-header>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Workspace'" @apply-filters="$refs.pagination.filterApi($event, 'Workspace')" @clear-filters="initComponent(true)" />
        </template>
        <template v-slot:item="{item}">
            <simple-card v-ripple class="mx-3" :title="item.name" :subtitle="item.description">
                <template v-slot:actions>
                    <div style="display:flex">
                        <v-btn class="mr-2" fab x-small dark @click="$router.push('/workspace/' + item._id)" v-if="isAllowed('viewWorkspaceDetails')">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn class="mr-2" fab x-small dark @click="$router.push('/workspaces/' + item._id)" v-if="isAllowed('updateWorkspace')">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn class="mr-2" fab x-small dark @click="deleteWorkspace(item)" v-if="
            isAllowed('deleteWorkspace') && item.name != 'Default Workspace'
          ">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </simple-card>
        </template>

    </v-data-table>
    <pagination ref="pagination" v-model="workspaces" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from "../../components/Pagination.vue";
import SimpleCard from './SimpleCard.vue';
export default {
    components: {
        Pagination,
        SimpleCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Workspace",
                    value: "name",
                },
                {
                    text: "Description",
                    value: "description"
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right"
                },
            ],
            workspaces: [],
            api: appConstants.WORKSPACES_API + "?fields=name,description",
        };
    },
    methods: {
        add() {
            this.$router.push("/workspaces/0");
        },
        async deleteWorkspace(item) {
            try {
                await this.deleteItem(
                    "Are you sure you want to delete?",
                    appConstants.WORKSPACES_API + "/" + item._id
                );
                this.workspaces.splice(this.workspaces.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },

        async initComponent(checkFilter) {
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.workspaces = await this.$refs.pagination.init(this.api)
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>
