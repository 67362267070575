<template>
<v-card flat wrap>
    <v-card-text>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col cols="12" class="text-center">
                    Admin Details
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="person.firstName" :rules="notEmpty" required label="First Name"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="person.lastName" :rules="notEmpty" required label="Last Name"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="person.email" :rules="emailRules" required label="E-mail"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field v-model="person.phoneNo" :rules="numRules" required label="Mobile No."></v-text-field>
                </v-col>
                <v-btn class="mt-6" @click="addAdmin"> Add</v-btn>
            </v-row>
            <alert-message :message="msg" />
        </v-form>
    </v-card-text>
</v-card>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        allowLogin: {
            type: Boolean,
        },
    },
    components: {
        AlertMessage,
    },
    computed: {
        admins: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    data() {
        return {
            notEmpty: [(v) => !!v || "Cannot be Empty"],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            person: {
                empId: Math.floor(Math.random() * 100),
                name: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNo: "",
            },
            default: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNo: "",
            },
            i: 0,
        };
    },

    methods: {
        addAdmin() {
            if (this.$refs.form.validate()) {
                this.i = this.admins.length;
                this.person.name = this.person.firstName + " " + this.person.lastName;
                let email = this.admins.filter((ele) => ele.email == this.person.email);
                if (email.length == 0) {
                    this.admins.push(this.person);
                    this.i++;
                    this.person = Object.assign({}, this.default);
                } else
                    this.msg = "Admin email " + this.person.email + " already exist.";
            }
        },
    },
};
</script>
