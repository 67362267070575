<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.mdAndUp" app>
    <v-app-bar-nav-icon
      @click.stop="$emit('toggle', true)"
      v-if="$vuetify.breakpoint.smAndDown"
    />
    <v-img
      max-width="50"
      max-height="50"
      :src="logoImage"
    ></v-img>
    <v-toolbar-title>
      <label :style="themeFont">{{$store.getters.org.orgName}}</label>
    </v-toolbar-title>
    <v-spacer />
    <label v-if="$vuetify.breakpoint.mdAndUp && isLoggedIn" :style="themeFont" class="mr-4">
      Welcome {{$store.getters.profile.name}}
    </label>
    <v-btn icon @click="clearNotificationAndNavigate()">
      <v-badge :value="$store.getters.taskFlag" color="pink" dot offset-y="5" offset-x="10">
        <v-icon medium>mdi-bell</v-icon>
      </v-badge>
    </v-btn>
    <profile />
  </v-app-bar>
</template>
<script>
import IconLabel from "@/components/IconLabel.vue";
import Profile from "@/components/Profile.vue";
import appConstants from "../utils/appConstants";
export default {
  components: { IconLabel, IconLabel, Profile },
  data: () => ({
    dialog: false,
    drawer: false,
    plan:{}
  }),
  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndUp;
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.token != "";
    },
  },
  methods: {
    clearNotificationAndNavigate() {
        this.$store.dispatch("actionUpdateTaskFlag",false);
        this.$router.push('/tasks')
    },
    
  },
};
</script>
<style></style>
