<template>
<v-layout>
<v-list-item>
    <v-list-item-content>
        <v-layout>
            <v-flex xs5>
                <v-list-item-title>{{contact.firstName}}</v-list-item-title>
                <v-list-item-subtitle>{{contact.lastName}}</v-list-item-subtitle>
                <v-list-item-subtitle class="caption">{{contact.designation}}</v-list-item-subtitle>
            </v-flex>
            <v-flex xs7>
                <v-list-item-title class="subtitle-2">{{contact.email}}</v-list-item-title>
                <v-list-item-subtitle>{{contact.phoneNo}}</v-list-item-subtitle>
            </v-flex>
        </v-layout>
    </v-list-item-content>
</v-list-item>
</v-layout>
</template>

<script>
export default {
    props: ["contact"]
};
</script>
