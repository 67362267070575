<template>
<div>
    <heading title="Billing Accounts"></heading>
    <v-data-table :headers="headers" :items="billingAccounts" sort-by="name" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'BillingAccount'" @apply-filters="$refs.pagination.filterApi($event, 'BillingAccount')" @clear-filters="initComponent(true)" />
        </template>
        <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission) ? true : false" @click="icon.name == 'mdi-delete'
                        ? deleteBillingAccount(item)
                        : performAction(item._id, icon)">
                </action-button>
            </template>
        </template>
        <template v-slot:item.status="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <status :label="item.status=='SUBSCRIBED' ? 'SUBSCRIBED' : 'UNSUBSCRIBED'" :block="false"></status>
        </template>
        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
            <simple-card @view="$router.push('/billing/'+item._id)" @delete="deleteBillingAccount(item)" :title="item.org.displayName" :subtitle="`Plan: ${item.plan.displayName}`" :description="`Cost: ₹ ${item.plan.value}`" :actions="icons">
            </simple-card>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="billingAccounts" :api="api" />
</div>
</template>

    
<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from '../../components/Pagination.vue';
import SimpleCard from '../workspaces/SimpleCard.vue'
import Status from "../../components/Status.vue";
export default {
    components: {
        Pagination,
        SimpleCard,
        Status
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/billing/",
                    permission: "viewBillingAccount",
                    event: 'view'
                },
                {
                    name: "mdi-delete",
                    permission: "deleteBillingAccount",
                    event: 'delete'
                },
            ],
            headers: [{
                    text: "Organization",
                    value: "organization.name"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "right",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            billingAccounts: [],
            api: appConstants.BILLING_ACCOUNTS_API,
        };
    },
    methods: {
        async initComponent(checkFilter) {
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.billingAccounts = await this.$refs.pagination.init(this.api)
            } catch (error) {
                this.handleError(error);
                this.msg = error.response;
            }
        },
        async deleteBillingAccount(item) {
            try {
                await this.deleteItem("Are you sure you want to delete?", appConstants.BILLING_ACCOUNTS_API + "/" + item._id);
                this.billingAccounts.splice(this.billingAccounts.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
