<template>
<div>
    <v-card flat>
        <v-card-text>
            <center>
                <p class="text-h4">Workspace Details</p>
            </center>
            <v-card flat>
                <v-card-text>
                    <v-row wrap>
                        <div class="col-xs-12 col-sm-4">
                            <row-view label="Name" v-bind:html="`<h3>${workspace.name}</h3>`"></row-view>
                            <row-view label="Description" v-bind:text="workspace.description"></row-view>
                            <view-meta-data class="mt-2" colClass="col-sm-12" :item="workspace"></view-meta-data>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <label class="text-h6">Members</label>
                            <v-list style="max-height: 500px" class="overflow-y-auto" v-if="!workspace.systemGenerated">
                                <user-card :user="member" v-for="member in workspace.members" :key="member._id" class="px-2 mt-2"></user-card>
                            </v-list>
                            <v-alert border="left" colored-border :color="themeSidebar" elevation="2" class="text-h6" style="font-weight: 400; color:gray" v-else>
                                This workspace is generated by system. All the users of the organization will have by default access to this workspace. This workpace cannot be deleted.
                            </v-alert>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <v-card flat>
                                <v-card-item-title class="title pl-3">Project</v-card-item-title>
                                <v-list style="max-height: 500px" class="overflow-y-auto">
                                    <simple-card :actions="actions" @click="$router.push('/project/'+item._id)" v-for="(item,index) in projects" :key="index" :title="item.name" :subtitle="item.customer.displayName"></simple-card>
                                </v-list>
                            </v-card>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
    <v-card flat>
        <v-card-text align="right">
            <back-button @click="goBack()" />
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import UserCard from '../../components/UserCard.vue';
import SimpleCard from "../workspaces/SimpleCard.vue";
export default {
    components: {
        BackButton,
        UserCard,
        SimpleCard
    },
    data() {
        return {
            workspace: {},
            id: 0,
            projects:[],
            actions:[{
                event:'click',
                icon:"mdi-eye",
                permission:'viewProjectDetails'
            }]
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            try {
                this.workspace = await this.getItem(
                    appConstants.WORKSPACES_API + "/" + this.id
                );
                this.projects=(await this.getItem(appConstants.PROJECT_API+`?conditions=workspace._id=${this.workspace._id}`)).data
            } catch (error) {
                this.handleError(error);
            }
        }
    }
};
</script>
