<template>
<div>
    <center>
        <h2 v-if="title" class="apply-font">{{ title }}</h2>
        <br />
        <v-toolbar flat>
            <template>
                <v-text-field solo v-model="computeSearch" class="search apply-font" label="Search here" append-icon="mdi-magnify" align="center">
                    <template v-slot:prepend v-if="filters" class="filter-button-position">
                        <search-filter ref="searchFilter" @update="filterData" :searchFilters="filters"></search-filter>
                    </template>
                    <template v-slot:prepend-inner>
                        <v-chip v-for="(flt,index) in selectedFilters" :key="index">{{flt.text}}</v-chip>
                    </template>
                </v-text-field>
            </template>
            &nbsp;
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :style="themeSidebar" v-if="isAllowed(permission)" class="mb-7" v-on="on" @click="$emit('click')">
                        <v-icon :style="themeSidebar">mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span :style="themeSidebar">{{ tooltip }}</span>
            </v-tooltip>
        </v-toolbar>
    </center>
</div>
</template>

<script>
import SearchFilter from './SearchFilter.vue'
export default {
    props: {
        title: {
            type: String,
            default: () => ""
        },
        tooltip: {
            type: String,
            default: () => ""
        },
        permission: {
            type: String,
            default: () => ""
        },
        filters: {
            type: Array,
            default: () => []
        }
    },
    components: {
        SearchFilter,
    },
    data() {
        return {
            selectSearch: "",
            searchObj: {
                srFields: [],
                srTxt: "",
                srOpr: "OR"
            },
            selectedFilters:[]
        };
    },
    methods: {
        filterData(value) {
            this.selectedFilters = value
            value.forEach(rec => {
                this.searchObj.srFields.push(rec.value)
            })
        },
        updateSearchField(search) {
            if (this.filters && this.filters.length > 0) {
                this.searchObj.srFields = this.selectedFilters.map(rec => rec.value)
                this.searchObj.srTxt = search
                if(this.searchObj.srFields.length==0)
                    this.searchObj.srFields.push(this.filters[0].value)
                this.$emit('update',this.searchObj)
            } else{
                //else condition is for existing components to work correctly
                this.$emit("input", search)
            }
        },
    },
    computed: {
        computeSearch: {
            get() {},
            set(newValue) {
                this.updateSearchField(newValue)
            },
        },
    },
};
</script>
<style>
.v-text-field.v-text-field--solo .v-input__prepend-outer, .v-text-field.v-text-field--solo .v-input__append-outer{
    margin: 0px !important
}
</style>
