<template>
<div>
    <v-card flat>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <center>
                    <h2>{{ formTitle }}</h2>
                </center>
                <alert-message :message="msg" :messageType="msgType" />
                <v-card ref="form" flat>
                    <v-card-text>
                        <h3>Label details</h3>
                        <v-card-text>
                            <v-text-field v-model="label.name" :rules="nameRules" label="Label name *" style="font-size: 10" required></v-text-field>
                            <v-textarea v-model="label.description" label="Description"></v-textarea>
                            <v-label>Label Color</v-label>
                            <v-color-picker class="ma-2" hide-inputs dot-size="25" swatches-max-height="200" v-model="label.color"></v-color-picker>
                        </v-card-text>
                        {{label.color}}
                        <alert-message :message="msg" :messageType="msgType" />
                        <form-buttons :valid="valid" @cancel="goBack()" @submit="validate()"></form-buttons>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
    components: {
        FormButtons,
        AlertMessage,
    },
    data: () => ({
        valid: true,
        nameRules: [(v) => !!v || "Name is required"],
        label: {},
        id: 0,
        formTitle: "",
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            this.label = {
                name: undefined,
                description: undefined,
                color: "",
                orgId: undefined
            };
            if (this.id != 0) {
                this.formTitle = "Edit label";
                try {
                    this.label = await this.getItem(
                        appConstants.LABELS_API + "/" + this.id
                    );
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
            } else {
                this.formTitle = "Add new label";
            }
        },
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.id == 0) {
                        toString(this.label.color)
                        this.label = await this.postItem(
                            appConstants.LABELS_API,
                            this.label
                        );
                    } else {
                        this.label = await this.putItem(
                            appConstants.LABELS_API + "/" + this.id,
                            this.label
                        );
                    }
                    this.$router.push("/labels");
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
    },
};
</script>
