<template>
<v-card flat>
    <v-card-text>
        <alert-message :message="msg" :messageType="msgType" />
        <v-form ref="form" v-model="valid" lazy-validation>
            <center>
                <h2>{{ formTitle }}</h2>
            </center>
            <v-card flat>
                <v-card-text>
                    <v-text-field v-model.trim="role.name" :rules="nameRules" label="Role name* :" required></v-text-field>
                    <v-textarea v-model="role.description" label=" Description :" required></v-textarea>
                    <v-combobox v-model="role.level" :items="levels" label="Role level*" :disabled="id != 0"></v-combobox>
                    <v-card flat>
                        <h3>Permissions* :</h3>
                        <v-card-text>
                            <template v-for="record in Object.keys(map)">
                                <permission-card :key="record" :title="record" :permissionSet="map[record]" v-model="role.permissions"></permission-card>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <alert-message :message="msg" :messageType="msgType" />
            </v-card>
            <form-buttons :valid="valid" @submit="validate()" @cancel="goBack()"></form-buttons>
        </v-form>
    </v-card-text>
</v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import permissionCard from "@/components/PermissionCard.vue";

export default {
    components: {
        permissionCard,
        FormButtons,
        AlertMessage
    },
    data: () => ({
        valid: true,
        nameRules: [v => !!v || "Name is required"],
        role: {},
        id: 0,
        formTitle: "",
        levels: [],
        currentLevel: 0,
        map: [],
        allPermissions: [],
        DEFAULT_ROLE_LEVEL: 5
    }),

    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.role = this.defaultRole()
            this.currentLevel = JSON.parse(localStorage.getItem("profile")).level;
            this.levels = [...Array(this.DEFAULT_ROLE_LEVEL).keys()].map(i => i + 1)
            this.id = this.$route.params.id;
            if (this.id != 0) {
                this.formTitle = "Edit Role";
                try {
                    this.role = await this.getItem(
                        appConstants.ROLES_API + "/" + this.id
                    );
                } catch (error) {
                    this.handleError(error);
                }
            } else {
                this.formTitle = "Add New Role";
            }
            this.map=(await this.readPermissons(this.getProfile().level)).mappedPermission;
        },
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.id == 0) {
                        this.role = await this.postItem(appConstants.ROLES_API, this.role);
                    } else {
                        this.role = await this.putItem(
                            appConstants.ROLES_API + "/" + this.id,
                            this.role
                        );
                    }
                    this.$router.push({
                        path: "/roles"
                    });
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
        defaultRole() {
            return {
                name: undefined,
                description: undefined,
                permissions: [],
                level: this.DEFAULT_ROLE_LEVEL,
                orgId: undefined
            }
        },
    }
};
</script>
