<template>
    <v-container class="display-block">
        <h2>Import {{ importFile }}</h2>
        <v-form>

            <!-- <v-checkbox label="Include first line as header?" v-model="header"></v-checkbox> -->
            <v-row align="center">
                <v-col cols="12" sm="6">
                    <v-flex class="mx-10">
                        <file-upload @input="readFile()" v-model="csvFile" label="Upload File"></file-upload>
                    </v-flex>
                </v-col>
                <v-col cols="12" sm="6" v-if="csvFile">
                    <v-flex v-if="items.length == 0">
                        <h3>Select Header Fields</h3>
                        <h4 class="py-2">First line will be included as header</h4>
                        <v-layout wrap v-if="importFile == 'operations'" align-center>
                            <v-flex sm3><label>Select Process</label></v-flex>
                            <v-flex sm9>
                                <v-select v-model="selectedProcess" class="pr-12" label="Select Process" :items="processes"
                                    item-text="displayName" item-value="_id" return-object></v-select>
                            </v-flex>
                            <v-flex sm3><label>Select Owner</label></v-flex>
                            <v-flex sm9>
                                <v-select v-model="selectedOwner" class="pr-12" label="Select Owner" :items="employees"
                                    item-text="displayName" item-value="_id" return-object></v-select>
                            </v-flex>
                            <v-flex sm3><label>Select Executor</label></v-flex>
                            <v-flex sm9>
                                <v-select v-model="selectedExecutor" class="pr-12" label="Select Executor"
                                    :items="employees" item-text="displayName" item-value="_id" return-object></v-select>
                            </v-flex>
                            <v-flex sm3><label>Date Format</label></v-flex>
                            <v-flex sm9>
                                <v-select v-model="selectedDateFormat" class="pr-12" label="Select Date Format"
                                    :items="dateFormat" item-text="displayName" item-value="format"></v-select>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout align-center>
                            <v-flex sm3><label>Headers from file</label></v-flex>
                            <v-flex>
                                <label>Columns to match</label>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-layout v-for="(data, index) in csvStringArray[0]" :key="index" align-center>
                            <v-flex sm3><label>{{ data }}</label></v-flex>
                            <v-flex>
                                <v-select v-model="fields[index]" class="pr-12" label="Select Field" :items="attributes"
                                    item-text="label" item-value="value"></v-select>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-col>
            </v-row>

            <v-flex v-if="items.length > 0">
                <v-row>
                    <v-flex xs12 sm1 ma-3>Summmary :</v-flex>
                    <v-flex xs4 sm1 ma-3>Success: {{ successCount }}</v-flex>
                    <v-flex xs4 sm7 ma-3>Failed: {{ failedCount }}</v-flex>
                    <v-flex mx-4 xs10 sm2>
                        <v-select label="Show Records" :items="showRecords" v-model="show"></v-select>
                    </v-flex>
                </v-row>
            </v-flex>
            <v-flex v-if="items.length > 0">
                <v-data-table :headers="headers" :items="filteredArr" class="elevation-1"
                    v-if="$vuetify.breakpoint.smAndUp">
                    <template v-slot:item.rec="{ item }">
                        {{ item.rec }}
                    </template>
                    <template v-slot:item.msg="{ item }">
                        <template v-if="item.msg && !item.success">
                            <label>{{ item.msg }}</label>
                        </template>
                    </template>
                    <template v-slot:item.rec.actionDate="{ item }">
                        <label>{{ item.rec.actionDate | dateFormat }}</label>
                    </template>
                    <template v-slot:item.rec.expiryDate="{ item }">
                        <label>{{ item.rec.expiryDate | dateFormat }}</label>
                    </template>
                    <template v-slot:item.success="{ item }">
                        <v-icon v-if="item.success" class="active-icon">mdi-check-circle-outline</v-icon>
                        <v-icon v-if="!item.success" class="deactive-icon">mdi-cancel</v-icon>
                    </template>
                </v-data-table>
                <template v-if="$vuetify.breakpoint.xsOnly">
                    <view-card :arrItems="filteredCardArr" :show="show"></view-card>
                </template>
            </v-flex>
            <v-flex v-if="loading" text-center>
                <v-progress-circular indeterminate :size="78" :width="7" :model-value="loading"></v-progress-circular>
            </v-flex>
            <v-flex text-right>
                <v-btn v-if="items.length == 0" class="ma-2" @click="submit()">Import Data</v-btn>
                <v-btn class="ma-2" @click="$router.go(-1)">Back</v-btn>
            </v-flex>
        </v-form>
    </v-container>
</template>

<script>
import ViewCard from '@/components/ImportResultCard'
import appConstants from '@/utils/appConstants'
import FileUpload from '@/components/FileUpload'
export default {
    components: {
        ViewCard,
        FileUpload
    },
    data() {
        return {
            csvFile: '',
            importFile: '',
            csvStringArray: [],
            header: false,
            attributes: [],
            stringArray: [],
            fields: [],
            items: [],
            headers: [],
            successCount: 0,
            failedCount: 0,
            showRecords: ['All', 'Failed'],
            show: '',
            cardItems: [],
            customerType: [appConstants.ACCOUNT.TYPE.B2B, appConstants.ACCOUNT.TYPE.B2C],
            accountType: appConstants.ACCOUNT.TYPE.B2C,
            processes: [],
            employees: [],
            selectedProcess: undefined,
            selectedExecutor: undefined,
            selectedOwner: undefined,
            selectedDateFormat: 'YYYY-MM-DD',
            loading: false,
            dateFormat: [
                { displayName: 'YYYY-MM-DD', format: 'YYYY-MM-DD' },
                { displayName: 'DD/MM/YYYY', format: 'DD/MM/YYYY' },
                { displayName: 'MM/DD/YYYY', format: 'MM/DD/YYYY' },
                { displayName: 'DD-MM-YYYY', format: 'DD-MM-YYYY' },
                { displayName: 'MM-DD-YYYY', format: 'MM-DD-YYYY' }
            ]
        }
    },
    computed: {
        filteredArr() {
            var itemArr = []
            if (this.show == 'Failed') {
                this.items.forEach(item => {
                    if (!item.success)
                        itemArr.push(item)
                })
                return itemArr
            } else
                return this.items
        },
        filteredCardArr() {
            var itemArr = []
            if (this.show == 'Failed') {
                this.cardItems.forEach(item => {
                    if (!item.success)
                        itemArr.push(item)
                })
                return itemArr
            } else
                return this.cardItems
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.importFile = this.$route.params.importFile
            this.attributes = await this.getItem(appConstants.IMPORT_FILE + "/" + this.importFile)
            if (this.importFile == "operations") {
                this.processes = (await this.getItem(`${appConstants.PROCESSES_API}?fields=_id,name,version`)).data.map(rec => ({
                    _id: rec._id,
                    displayName: rec.name,
                    value: rec.version
                }))
                this.employees = (await this.getItem(`${appConstants.EMPLOYEES_API}?fields=displayName,email&size=-1&sort=+displayName`)).data.map(rec => ({
                    _id: rec._id,
                    displayName: rec.displayName,
                    value: rec.email
                }))
            }
        },
        readFile() {
            const csvData = this.csvFile
            var reader = new FileReader()
            reader.readAsText(csvData)
            reader.onload = event => {
                let text = reader.result
                var firstLine = text.split('\n');
                if (!this.header)
                    this.stringArray.push(firstLine[0])
                else
                    this.stringArray.push(firstLine[1])
                this.csvStringArray.push(this.stringArray[0].split(","))
            }
        },
        async submit() {
            this.loading = true
            let payload = {
                headers: this.fields,
                noheader: this.header
            }
            let formData = this.convertToFormData(payload, [this.csvFile], 'csvFile')
            if (this.importFile == 'operations') {
                formData.append('process', JSON.stringify(this.selectedProcess))
                formData.append('executor', JSON.stringify(this.selectedExecutor))
                formData.append('owner', JSON.stringify(this.selectedOwner))
                formData.append('dateFormat', JSON.stringify(this.selectedDateFormat))
            }
            if (this.csvFile != '') {
                this.items = await this.postItem(`${appConstants.IMPORT_FILE}/${this.importFile}`, formData)
                this.setHeader()
            } else
                alert("Please select file to Upload")
            this.loading = false
        },
        setHeader() {
            this.items.forEach(item => {
                if (item.failedCount)
                    this.failedCount += 1
                else
                    this.successCount += 1
                if (this.importFile != 'operations' && !item.rec['firstName']) {
                    item.rec['companyName'] = item.rec['displayName']
                }
            })
            this.fields.forEach(item => {
                if (!item.noheader) {
                    let headerTxt = item.split(".")[0]
                    this.headers.push({
                        'text': headerTxt,
                        'value': 'rec.' + item
                    })
                }
            });
            this.headers.push({
                'text': 'Success',
                'value': 'success'
            })
            this.headers.push({
                'text': 'Message',
                'value': 'msg'
            })
            this.createCardItemArr()
        },
        createCardItemArr() {
            var data = {}
            this.items.forEach(doc => {
                this.headers.forEach(item => {
                    if (doc.rec.hasOwnProperty(item.text))
                        data[item.text] = doc.rec[item.text]
                })
                if (doc.msg)
                    data['error'] = doc.msg
                data['success'] = doc.success
                this.cardItems.push(data)
                data = {}
            })
        }
    },
}
</script>
