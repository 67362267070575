<template>
<v-card>
    <v-card-text>
        <center>
            <label class="subtitle">{{title}}</label>
        </center>
    <GChart class="px-2" type="ColumnChart" :data="chartData" :options="chartOptions" />
    </v-card-text>
</v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import { GChart } from "vue-google-charts";
export default {
    components: {
        GChart,
    },
    data() {
        return {
            employeeMonthCount: [],
            chartData:[],
            title: "Employee Completed Task Count for last 3 Months",
            chartOptions: {
                height: 200,
                colors: ["#774EA7", "#F99F1A", "red", "#00A9F4"],
            },
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.chartData = [];
            this.employeeMonthCount = await this.getItem(
                appConstants.STATS_API +"/employee-performance-count-x-months"
            );
            let min = Math.min(...this.employeeMonthCount.map(item => item.month));
            let max = Math.max(...this.employeeMonthCount.map(item => item.month));
            let counter = min
            let header = ["Name"]
            while(counter <= max){
                header.push(this.toMonthName(counter++))
            }
            this.chartData.push(header)
            let result = this.transformDataForChart(this.employeeMonthCount)
            Object.values(result).forEach(rec => {
                let res = [rec.name]
                counter = min
                while(counter <= max){
                    let count = rec.stats[counter++]
                    res.push(count ? count : 0)
                }
                this.chartData.push(res)
            })
            this.employeeMonthCount = result
        },
        transformDataForChart(list){
            let result = {}
            list.forEach(rec => {
                let emp = result[rec._id]
                let rs = {}
                if(emp){
                    emp.stats[rec.month] = rec.count
                }else{
                    rs[rec.month] = rec.count
                    emp = {
                        _id: rec._id,
                        name: rec.name,
                        stats: rs
                    }
                }
                result[rec._id] = emp
            })
            return result
        }
    },
};
</script>
