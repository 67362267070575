<template>
<v-container>
    <div>
        <v-card flat>
            <v-card-text>
                <center>
                    <h2>Label Details</h2>
                </center>
                <br />
                <div>
                    <v-row>
                        <v-col>
                            <row-view label="Name" v-bind:text="label.name"></row-view>
                        </v-col>
                        <v-col>
                            <row-view label="Description" v-bind:text="label.description"></row-view>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="2">
                            <row-view label="Color" v-bind:text="label.color"></row-view>
                            <v-sheet :style="{ background: label.color }" class="pa-4"></v-sheet>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-text align="right">
                <back-button @click="goBack()" />
            </v-card-text>
        </v-card>
    </div>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "@/components/BackButton.vue";

export default {
    components: {
        BackButton,
    },
    data: () => ({
        label: {},
        id: 0,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.label = await this.getItem(
                    appConstants.LABELS_API + "/" + this.id
                );
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
