<template>
<div>
    <heading title="Employees">
        <add-btn @click="add()" permission="addEmployee" tooltip="Add Employee"></add-btn>
    </heading>
    <v-data-table :headers="headers" :items="employeeList" sort-by="firstName" class="elevation-1 " :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Employee'" :preset="defaultPreset()" @apply-filters="$refs.pagination.filterApi($event, 'Employee')" @clear-filters="$refs.filter.checkHistory()" />
        </template>
        <template v-slot:item.active="{ item }">
            <v-icon v-if="item.active" title="Active">mdi-account-check</v-icon>
            <v-icon v-else title="Inactive">mdi-account-cancel</v-icon>
        </template>
        <template align="right" v-slot:item.action="{ item }">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :show="isAllowed(icon.permission) ? true : false" :icon="icon" @click="
              icon.name == 'mdi-delete'
                ? deleteEmployee(item)
                : performAction(item._id, icon)
            "></action-button>
            </template>
            <action-button class="mr-2" :show="isAllowed('updateEmployee')" icon="mdi-lock-reset" @click="openPasswordResetDialog(item._id)"></action-button>
            <assign-employee @change="assignEmployee(item,$event)" :empList="employeeList" v-if="(isAllowed('updateEmployee'))"></assign-employee>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="employeeList" />
    <dialog-box title="Reset Password" :dialog="showPasswordResetForm" @close="closePasswordResetDialog()">
        <password-reset :forceReset="true" api="/api/employees" ref="passwordResetForm" :userId="selectedId" :userName="selectedUsername" @close="closePasswordResetDialog()"></password-reset>
        <alert-message :message="msg" :messageType="msgType" />
    </dialog-box>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from "../../components/Pagination.vue";
import DialogBox from "../../components/DialogBox.vue";
import PasswordReset from "../../components/PasswordReset.vue";
import ActionButton from "../../components/ActionButton.vue";
import AssignEmployee from "@/components/AssignEmployee.vue";
export default {
    components: {
        Pagination,
        DialogBox,
        PasswordReset,
        ActionButton,
        AssignEmployee,
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/employee/",
                    permission: "viewEmployeeDetails",
                },
                {
                    name: "mdi-pencil",
                    path: "/employees/",
                    permission: "updateEmployee",
                },
                {
                    name: "mdi-delete",
                    permission: "deleteEmployee",
                },
            ],
            headers: [{
                    text: "Name",
                    value: "displayName",
                },
                {
                    text: "E-mail",
                    value: "email",
                },
                {
                    text: "Phone No.",
                    value: "phoneNo",
                },
                {
                    text: "Emp Id",
                    value: "empId",
                },
                {
                    text: "Active",
                    value: "active",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            employeeList: [],
            displayName: "",
            api: appConstants.EMPLOYEES_API,
            showPasswordResetForm: false,
            selectedUsername: appConstants.INITIAL_VALUE,
            selectedId: appConstants.INITIAL_VALUE,
            activeEmployee: true
        };
    },
    mounted() {
        this.readEmployees();
    },

    methods: {
        async deleteEmployee(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this employee ?",
                        appConstants.EMPLOYEES_API + "/" + item._id
                    )
                )
                    this.employeeList.splice(this.employeeList.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/employees/0");
        },
        async readEmployees() {
            this.employeeList = [];
            try {
                if (!this.activeEmployee)
                    this.api = appConstants.EMPLOYEES_API + "?fields=displayName,email,phoneNo,empId,active&activeOnly=" + this.activeEmployee
                else
                    this.api = appConstants.EMPLOYEES_API + "?fields=displayName,email,phoneNo,empId,active"
                this.$refs.pagination.updateApiCall(this.api)
            } catch (error) {
                console.log(error);
            }
        },
        async openPasswordResetDialog(id) {
            let userName = (await this.getItem(appConstants.EMPLOYEES_API + "/" + id + "/userProfile"))[0].userName
            this.selectedUsername = userName;
            this.selectedId = id;
            this.showPasswordResetForm = true;
        },
        closePasswordResetDialog() {
            this.showPasswordResetForm = false;
            this.$refs.passwordResetForm.resetForm();
        },
        defaultPreset() {
            return {
                active: [true]
            }
        },
        async assignEmployee(currentEmp,newEmp) {
            try {
                let updatedTaskCount=(await this.putItem(appConstants.TASKS_API + "/assignEmployee/"+currentEmp._id, this.createReference(newEmp._id,newEmp.displayName,newEmp.email))).data
                if(updatedTaskCount >0)
                    alert(updatedTaskCount + " Task/s have been re-assigned")
                else
                    alert("No Task to re-assign")
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped></style>
