<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Roles"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addRole" tooltip="Add role"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="roleList" sort-by="name" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Role'" @apply-filters="$refs.pagination.filterApi($event, 'Role')" @clear-filters="readRoles(true)" />
        </template>
        <template align="right" v-slot:item.action="{ item }">
            <v-btn class="mr-2" fab x-small dark @click="$router.push('/role/' + item._id)" v-if="isAllowed('viewRoleDetails')">
                <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn class="mr-2" fab x-small dark @click="$router.push('/roles/' + item._id)" v-if="isAllowed('updateRole') && checkUserRole(item)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mr-2" fab x-small dark @click="deleteRole(item)" v-if="
            isAllowed('deleteRole') &&
            item.name != 'CUSTOMER' &&
            item.name != 'ADMIN' &&
            item.name != 'EMPLOYEE' &&
            item.name != 'SYSTEM_ADMIN'
          ">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="roleList" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from '../../components/Pagination.vue';
export default {
    components: {
        Pagination
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Description",
                    value: "description",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            roleList: [],
            level: 0,
            api: appConstants.ROLES_API + "?fields=name,description,systemGenerated",
        };
    },
    methods: {
        async deleteRole(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this role ?",
                        appConstants.ROLES_API + "/" + item._id
                    )
                )
                    this.$delete(this.roleList, this.roleList.indexOf(item));
            } catch (error) {
                this.handleError(error);
                this.msg = error.response;
            }
        },
        add() {
            this.$router.push("/roles/0");
        },
        async readRoles(checkFilter) {
            this.level = JSON.parse(localStorage.getItem("profile")).level;
            this.roleList = [];
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.roleList = await this.$refs.pagination.init(this.api)
            } catch (error) {
                this.handleError(error);
                this.msg = error.response;
            }
        },
        checkUserRole(item) {
            if (JSON.parse(localStorage.getItem("profile")).level == "1") {
                return true;
            } else if (item.systemGenerated) return false;
            return true;
        }
    },
};
</script>
