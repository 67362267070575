<template>
<div>
    <v-btn :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" class="mr-2" @click="dialog=true">Templates</v-btn>
    <dialog-box :dialog="dialog" title="Add Process from template" :errorMessage="msg" @close="dialog=false">
        <v-card v-for="item in list" :key="item._id" class="my-2">
            <v-card>
                <v-img height="100">
                    <diagram :showBtn="false" :process="item"></diagram>
                </v-img>
                <v-row>
                    <v-col sm="10">
                        <v-card-title>{{item.name+'(v'+item.version+')'}}</v-card-title>
                    </v-col>
                    <v-col sm="2">
                        <v-btn class="mr-2 mt-4" fab x-small dark @click="addProcess(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="mt-n2">
                    <div> TurnAroundTime: {{ `${item.turnAroundTime.value ? (item.turnAroundTime.value+' Days') : ''}` }}</div>
                    <div class="mt-2 word-wrap" style="max-width: 250px;" v-html="item.description"></div>
                </v-card-text>
            </v-card>
        </v-card>
    </dialog-box>
</div>
</template>

<script>
import appConstants from '../utils/appConstants';
import Diagram from "@/components/Diagram.vue";
import SimpleCard from '../views/workspaces/SimpleCard.vue'
export default {
    components: {
        SimpleCard,
        Diagram,
    },
    data() {
        return {
            dialog: false,
            list: [],
            icons: [{
                    name: appConstants.ICONS.VIEW,
                    path: "/process/",
                    permission: "viewProcessDetails",
                    event: 'view'
                },
                {
                    name: "mdi-arrow-down",
                    permission: "addProcess",
                    event: 'add'
                },
            ],
            msg: ''
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.list = await this.getItem(appConstants.PROCESSES_API + '/templates')
        },
        async addProcess(process) {
            try {
                process['orgId'] = this.$store.getters.org._id
                await this.postItem(appConstants.PROCESSES_API, process);
                this.dialog = false
                this.$emit('update')
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.details[0];
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.word-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: normal;
}
</style>
