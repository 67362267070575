<template>
<div>
    <v-btn :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" @click="getData()" class="mr-2">
        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-archive-arrow-up</v-icon>
        Export
    </v-btn>
    <dialog-box :dialog="showDialog" title="Select file type" :errorMessage="msg" @close="showDialog=!showDialog">
        <div class="text-center">
            <v-btn-toggle>
                <download-excel :data="processedData" :fields="json_fields" :name="fileName">
                    <v-btn title="Download in .xlxs format. This option required microsoft excel install on the device" class="excel-btn mx-2">
                        <v-icon class="icon-color">mdi-microsoft-excel</v-icon>
                    </v-btn>
                </download-excel>
                <download-excel :data="processedData" :fields="json_fields" :name="fileName+'.csv'" type="csv">
                    <v-btn title="Dowload in .csv format. Works on all devices" class="export-btn mx-2">
                        <v-icon>mdi-file-delimited</v-icon>
                    </v-btn>
                </download-excel>
            </v-btn-toggle>
        </div>
    </dialog-box>
</div>
</template>

<script>
import moment from 'moment';
import appConstants from '../utils/appConstants';
export default {
    props: {
        fileName: {
            type: String,
            default: () => ''
        },
        fields: {
            type: Array,
            default: () => []
        },
        apiCall: {
            type: String
        },
        apiCondition:{
            type:String
        },
        entity:{
            type:String
        }
    },
    data() {
        return {
            showDialog: false,
            json_fields: {},
            processedData: []
        }
    },
    methods: {
        async processData() {
            this.json_fields = {}
            this.fields.forEach(rec => {
                if(rec.text!='Action')
                    this.json_fields[rec.text] = rec.value
            })
        },
        async getData() {
            let data
            let filterData=this.getFilterData()
            if(filterData)
                data = await this.callFilterApi(filterData)
            else{
                let api = this.apiCall.split('?')[0]
                let apiString=this.apiCondition? api +"?conditions="+this.apiCondition+"&size=-1":api+"?size=-1"
                data = (await this.getItem(apiString)).data
            }
            this.processedData = data.map(obj => {
                const updatedObj = {...obj};
                Object.keys(updatedObj).forEach(key => {
                    if (moment(updatedObj[key], moment.ISO_8601, true).isValid()) 
                        updatedObj[key] =moment(updatedObj[key]).format(appConstants.DATEFORMAT.DMY);
                    if(updatedObj[key]==false)
                        updatedObj[key]='PENDING'
                    if(updatedObj[key]==true)
                        updatedObj[key]='COMPLETED'
                });
                return updatedObj;
            });
            if (this.processedData.length > 0)
                this.processData();
            this.showDialog = !this.showDialog
        },
        async callFilterApi(filterData) {
            let filter=this.createFilterPayload(filterData)
            let payload={filter}
            payload['entity']=this.entity
            let response=(await this.postItem(appConstants.FILTER_API+`?size=-1`,payload)).data
            return response
        },
        getFilterData(){
            let filterHistory = this.$store.getters.filter
            filterHistory=filterHistory.filter(rec=>rec.entity==this.entity)
            if (filterHistory[0] ?.entity == this.entity)
                return filterHistory[0].appliedFilters
            else
                return false
        }
    },
};
</script>

<style>
.excel-btn {
    height: 2rem !important;
    background: green !important;
}

.export-btn {
    height: 2rem !important
}

.icon-color {
    color: white !important;
}
</style>
