<template>
    <v-row>
    <v-col cols="12" sm="10" offset-sm="1">
        <v-card flat>
            <v-card-text>
                <section-header title="Employee Details"/>
                <br>
                <v-row wrap>
                    <v-col cols="12" sm="6"><row-view label="Name" v-bind:text="employee.displayName"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="UserName" v-bind:text="userName"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="E-mail" v-bind:text="employee.email"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="Mobile No." v-bind:text="employee.phoneNo"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="Emp_id" v-bind:text="employee.empId"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="DOB" v-bind:text="employee.dob"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="Designation" v-bind:text="employee.designation"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="Active Status" v-bind:text="employee.active"></row-view></v-col>
                    <v-col cols="12" sm="6"><row-view label="Roles">
                        <v-chip v-for="role in employee.roles" :key="role._id" small label>{{role}}</v-chip>
                    </row-view></v-col>
                    <v-col cols="12"><view-meta-data :item="employee"></view-meta-data></v-col>
                    <v-col cols="12"><section-header title="Addresses"/> <address-table :addresses="employee.addresses" :readOnly="true" /></v-col>
                </v-row>
            </v-card-text>
            <v-col>
                <section-header title="Tasks"/>
                <br>
                <v-data-table v-if="$vuetify.breakpoint.smAndUp" :headers="headers" :items="employeeTasks" class="elevation-1" :items-per-page="-1" sort-by="actionDate"  hide-default-footer>
                    <template v-slot:item.name="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                        <label @click="$router.push(`/tasks/${item._id}`)" class="link">{{ item.name}}</label>
                    </template>
                    <template v-slot:item.actionDate="{ item }">
                        {{ item.actionDate | dateFormat }}
                    </template>
                    <template v-slot:item.dueDate="{ item }">
                        {{ item.dueDate | dateFormat }}
                    </template>
                    <template v-slot:item.completed="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                        <status :label="item.completed ? 'Completed' : 'Pending'" :block="false"></status>
                    </template>
                </v-data-table>                
                <v-layout v-if="$vuetify.breakpoint.xsOnly" column>
                    <v-col v-for="item in employeeTasks" :key="item.code">
                        <task-card :task="item"></task-card>
                        <v-divider></v-divider>
                    </v-col>
                </v-layout>
               <pagination v-model="employeeTasks" :api="getApi" :employeeId="$route.params.id" />
            </v-col>
            <v-card-text align="right">
                <back-button @click="goBack()" />
            </v-card-text>
        </v-card>
        </v-col>
    </v-row>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "@/components/BackButton.vue";
import AddressTable from '../../components/AddressTable.vue';
import Pagination from "../../components/Pagination.vue";
import TaskCard from '@/components/TaskCard'
import SectionHeader from '../../components/SectionHeader.vue';
import Status from '../../components/Status.vue';
export default {
    components: {
        BackButton,
        AddressTable,
        Pagination,
        TaskCard,
        SectionHeader,
        Status
    },
    data: () => ({
        employee: {
            userName:''
        },
        id: 0,
        employeeTasks: [],
        api: "",
        userName:'',
        headers: [{
                text: "Name",
                value: "name"
            },
            {
                text: "Operation",
                value: "parent.displayName"
            },
            {
                text: "Action Date",
                value: "actionDate"
            },
            {
                text: "Type",
                value: "type"
            },
            {
                text: "Due Date",
                value: "dueDate"
            },
            {
                text: "Status",
                value: "completed"
            },
        ]
    }),
    mounted() {
        this.initialize();
    },
    computed: {
        getApi() {
            this.api=appConstants.TASKS_API+`?fields=name,actionDate,dueDate,priority,parent,type,completed&sort=+actionDate&conditions=completed=false,assignedTo._id=${this.$route.params.id}&size=5`
            return this.api 
        }
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.employee = await this.getItem(
                    appConstants.EMPLOYEES_API + "/" + this.id
                );
                this.userName= (await this.getItem(appConstants.EMPLOYEES_API + "/" + this.id + "/userProfile" ))[0].userName
            } catch (error) {
                this.handleError(error);
            }
        },
        
    },
};
</script>
