<template>
<v-container fluid>
    <heading title="Operations">
        <add-btn @click="add()" permission="addOperation" tooltip="Add new Operations"></add-btn>
        <export-to class="ml-2" entity="Operation" apiCondition="completed=false" :apiCall="api" :fields="headers" fileName="Operations" />
        <import-file-button importData='operations'></import-file-button>
    </heading>
    <v-data-table :headers="headers" :items="operations" sort-by="actionDate" class="elevation-1" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Operation'" :preset="defaultPreset()" @apply-filters="$refs.pagination.filterApi($event, 'Operation')" @clear-filters="$refs.filter.checkHistory()" />
            <v-chip v-if="chipFilter" class="ma-2" close @click:close="removeURL()">
                {{ chipFilter }}
            </v-chip>
            <operation-column-dropdown @update="updateHeaders($event)" class="mx-4" :headers="headers"/>
            <dialog-box :dialog="dialog" title="Delete The Operation" :errorMessage="msg" @close="closeDialog()">
                <v-radio-group v-model="deleteInstance">
                    <v-radio v-if="isRecursive" name="delete" label="Delete All Instance" value="1">Delete All Instance</v-radio>
                    <v-radio name="delete" label="Delete Current Instance" value="2"></v-radio>
                </v-radio-group>
                <v-btn v-if="deleteInstance == '1'" @click="deleteAll()">Delete All</v-btn>
                <v-btn v-if="deleteInstance == '2'" @click="deleteop()">Delete
                </v-btn>
                <v-btn @click="closeDialog()">Cancel</v-btn>
            </dialog-box>
        </template>
        <template v-slot:item.process.displayName="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            {{item.process.displayName}} <label style="color:gray">(v{{item.process.value}})</label>
        </template>
        <template v-slot:item.actionDate="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <show-date :date="item.actionDate"></show-date>
        </template>
        <template v-slot:item.expiryDate="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <show-date :date="item.expiryDate"></show-date>
        </template>
        <template v-slot:item.dueDays="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <label v-date-color:date="item.expiryDate">{{ item.dueDays }}</label>
        </template>
        <template v-slot:item.completed="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <status :label="item.completed ? 'Completed' : 'In Process'"></status>
        </template>
        <template v-slot:item.currentTask.displayName="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <status :label="item.currentTask.displayName" v-if="item.currentTask" :block="false"></status>
        </template>
        <template v-slot:item.owner="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <user-card :user="item.owner" :size="20"></user-card>
        </template>
        <template v-slot:item.executor="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <user-card :user="item.executor" :size="20"></user-card>
        </template>
        <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission) ? true : false" @click="
              icon.name == 'mdi-delete'
                ? deleteOperation(item)
                : performAction(item._id, icon)
            " v-if="icon.name != 'mdi-pencil' || (icon.name == 'mdi-pencil' && !item.completed)"></action-button>
            </template>
        </template>
        <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
            <operation-responsive-card @delete="deleteOperation(item)" :operation="item" :icons="icons" />
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="operations" />
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import DialogBox from "../../components/DialogBox.vue";
import Pagination from "../../components/Pagination.vue";
import moment from 'moment';
import Status from "../../components/Status.vue";
import ShowDate from "../../components/ShowDate.vue";
import OperationResponsiveCard from "@/components/OperationResponsiveCard"
import ImportFileButton from '../../components/ImportFileButton.vue';
import UserCard from '../../components/UserCard.vue';
import Heading from '../../components/Heading.vue';
import ExportTo from '../../components/ExportTo.vue'
import OperationColumnDropdown from "@/components/OperationColumnDropdown.vue";
export default {
    data() {
        return {
            isRecursive: false,
            query: "",
            dialog: false,
            deleteInstance: "",
            chipFilter: '',
            orgStatus1: "",
            activeOperation: [],
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/operation/",
                    permission: "viewOperationDetails",
                },
                {
                    name: "mdi-pencil",
                    path: "/operations/",
                    permission: "updateOperation",
                },
                {
                    name: "mdi-delete",
                    permission: "deleteOperation"
                },
            ],
            headers: [{
                    text: "Operation",
                    value: "name",
                },
                {
                    text: "Customer",
                    value: "account.displayName",
                },
                {
                    text: "Process",
                    value: "process.displayName",
                },
                {
                    text: "Start date",
                    value: "actionDate",
                    type: Date,
                },
                {
                    text: "Expiry date",
                    value: "expiryDate",
                    type: Date,
                },
                {
                    text: "CreatedAt",
                    value: "createdAt",
                    align: ' d-none',
                    type: Date,
                },
                {
                    text: "Workspace",
                    value: "workspace.displayName",
                    align: !this.modulePermission("Workspaces") ? ' d-none' : 'left',
                },
                {
                    text: "Owner",
                    value: "owner",
                    type: Object,
                },
                {
                    text: "Executor",
                    value: "executor",
                    type: Object,
                },
                {
                    text: "Project",
                    value: "project.displayName",
                    align: !this.modulePermission("Projects") ? ' d-none' : 'left',
                },
                {
                    text: "Status",
                    value: "completed",
                    align: ' d-none'
                },
                {
                    text: "Current Status",
                    value: "currentTask.displayName",
                    align: "left",
                },
                {
                    text: "Due Days",
                    value: "dueDays",
                    type: Date,
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            operations: [],
            operationCondition: '',
            oldHeaders:[],
            api: appConstants.OPERATIONS_API +
                "?fields=name,actionDate,account,process,type,currentTask,createdAt,workspace,project&conditions=completed=false&sort=+actionDate",
        };
    },
    components: {
        DialogBox,
        Pagination,
        Status,
        ShowDate,
        OperationResponsiveCard,
        ImportFileButton,
        UserCard,
        Heading,
        ExportTo,
        OperationColumnDropdown
    },
    mounted() {
        this.initComponent();
        this.loadOperationHeader()
    },
    beforeUpdate() {
        this.processOperationCustomfields()
        this.getDueDays()
    },
    methods: {
        async initComponent() {
            try {
                this.oldHeaders=this.headers
                this.chipFilter = this.$route.query.type
                if (this.chipFilter == appConstants.OPERATION.WIDGET.CURRENT_MONTH) {
                    const startOfMonth = new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm')).toISOString()
                    const endOfMonth = new Date(moment().endOf('month').format('YYYY-MM-DD hh:mm')).toISOString()
                    this.operationCondition = `completed=false,owner._id=${this.getProfile().accountId},createdAt>${startOfMonth},actionDate<${endOfMonth}`
                    this.operations = await this.apiCall(this.operationCondition)
                }
            } catch (error) {
                console.log(error);
            }
        },
        getDueDays() {
            this.operations.forEach(rec => {
                if (rec.completed == false) {
                    if (!rec.expiryDate && rec.actionDate)
                        rec.expiryDate = moment(new Date(rec.actionDate), 'DD-MM-YYYY').add(10, 'days').toString()
                    rec.dueDays = moment(new Date(rec.expiryDate)).diff(moment(new Date()), 'days')
                }
            })
        },
        async apiCall(condition) {
            this.api = appConstants.OPERATIONS_API + '?conditions=' + condition + "&sort=+actionDate"
            this.$refs.pagination.init(this.api)
        },
        removeURL() {
            this.chipFilter = null
            this.$router.replace('/operations')
        },
        add() {
            this.$router.push("/operations/0");
        },
        closeDialog() {
            this.dialog = false;
            this.isRecursive = false;
        },
        async deleteOperation(item) {
            try {
                this.query = appConstants.OPERATIONS_API + "/" + item._id;
                if (item.type == appConstants.OPERATION.TYPE.RECURSIVE) {
                    this.isRecursive = true
                }
                this.dialog = true;
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.details.errors;
            }
        },
        async deleteAll() {
            if (this.deleteInstance == "1") {
                await this.deleteInstanceItem(
                    (this.query = this.query + "?deleteAll=" + true)
                );
                this.isRecursive = false
                this.updateOperationList()
                this.dialog = false;
                this.initComponent();
            }
        },
        async deleteop() {
            if (this.deleteInstance == "2") {
                await this.deleteInstanceItem(this.query);
                this.updateOperationList()
                this.dialog = false;
                this.updateOperationList()
                this.initComponent();
            }
        },
        updateOperationList() {
            let itemId = this.query.replace(appConstants.OPERATIONS_API + "/", '')
            let index = this.operations.findIndex(rec => rec._id === itemId.toString())
            if (index > -1)
                this.operations.splice(index, 1);
        },
        defaultPreset() {
            return {
                'owner.displayName': [this.$store.getters.profile.name],
                'completed': [false]
            }
        },
        updateHeaders(headers){
            if(headers.length==0){
                this.headers=this.oldHeaders
                localStorage.removeItem("selectedOperationHeaders");
            }
            else{
                let includeHeaders=['Operation','Customer','Process']
                this.headers=[]
                this.oldHeaders.forEach(rec=>{
                    if(includeHeaders.includes(rec.text))
                        this.headers.push(rec)
                })
                headers.forEach(rec=>{
                    this.headers.push(rec)
                })
                this.headers.push(this.oldHeaders.find(rec=>rec.text=='Action'))
                localStorage.setItem('selectedOperationHeaders',JSON.stringify(this.headers))
            }
        },
        loadOperationHeader(){
            let headers=JSON.parse(localStorage.getItem('selectedOperationHeaders'))
            if(headers?.length>0)
                this.headers=headers
        },
        processOperationCustomfields(){
            this.operations.forEach(rec=>{
                rec.customFields.forEach(item=>{
                    rec[item.field.field]=item.value
                })
            })
        }
    },
};
</script>
