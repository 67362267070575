<template>
<div>
    <v-card class="mx-2" flat>
        <v-autocomplete solo v-model="process" :items="processList" item-text="displayName" label="Select process" item-value="id" return-object @input="getData">
        </v-autocomplete>
        <v-slide-group v-if="$vuetify.breakpoint.mdAndUp" multiple>
            <v-slide-item v-for="(data, index) in dataList" :key="index">
                <v-col>
                    <v-card min-height="600px" flat>
                        <v-card-text>
                        <h4 class="font-weight-bold">{{ data._id }}</h4>
                        <h5>Count : {{ data.count }}</h5>
                    </v-card-text>
                    <kanban-operation-card v-for="(operation, index) in dataList[index].operations" :key="index" :operation="operation" :actionDate="operation.actionDate" :name="operation.name" @click="showOperation(operation._id)" />
                    </v-card>
                </v-col>
            </v-slide-item>
        </v-slide-group>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" xs="12" v-for="(data, index) in dataList" :key="index">
                <v-card flat>
                    <v-card-text>
                    <h4>{{ data._id }}</h4>
                    <h5>Count : {{ data.count }}</h5>
                </v-card-text>
                <kanban-operation-card v-for="(operation, index) in dataList[index].operations" :key="index" :operation="operation" :actionDate="operation.actionDate" :name="operation.name" @click="showOperation(operation._id)" />
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</div>
</template>

<script>
import KanbanOperationCard from "../components/KanbanOperationCard.vue";
import RowItem from "../components/RowItem.vue";
import appConstants from "@/utils/appConstants.js";

export default {
    components: {
        RowItem,
        KanbanOperationCard
    },
    data() {
        return {
            process: {},
            processList: [],
            operationList: [],
            size: 0,
            dataList: []
        };
    },
    mounted() {
        this.initLists();
    },
    methods: {
        async getData() {
            this.dataList = await this.getItem(appConstants.KANBAN_API + "/" + this.process._id);
            this.sortByActionDate()
        },
        async initLists() {
            this.processList = [];
            try {
                this.processList = (
                    await this.getItem(
                        appConstants.PROCESSES_API +
                        "?fields=name,description,status,parentId,steps,version&conditions=latestOnly=false,checkActiveOperation=true&status=ACTIVE,INACTIVE"
                    )
                ).data.map(rec => ({...rec, "displayName": rec.name+" (v"+rec.version+")"}));
                if(this.$route.query.processId){
                    this.dataList = await this.getItem(appConstants.KANBAN_API + "/" + this.$route.query.processId);
                    this.sortByActionDate()
                    this.process = this.processList.find(rec => rec._id == this.$route.query.processId)
                }
            } catch (error) {
                console.log(error);
            }
        },
        sortByActionDate(){
            if(this.dataList.length>0){
                this.dataList.forEach(rec=>{
                    rec.operations=rec.operations.sort(function(a,b){return new Date(a.actionDate) - new Date(b.actionDate);})
                })
            }
        },
        showOperation(id) {
            this.$router.push("/operation/" + id);
        },
    },
};
</script>

<style>
</style>
