<template>
   <!--  <v-card height="100%">
        <v-card-text>
            <center class="ma-2">
                <label class="subtitle">Operation Status</label>
                <GChart type="PieChart" :data="chartData" :options="chartOptions" />
            </center>
        </v-card-text>
    </v-card> -->
    <v-row justify="space-around" no-gutters>
        <v-col cols="12">
            <widget-stats-count icon="mdi-vector-link" title="Active Operations" :stat="operationStats.activeOperations"></widget-stats-count>
        </v-col>
        <v-col cols="12" style="margin-top:2%;">
            <widget-stats-count icon="mdi-vector-link" title="Operations Created in July" :stat="operationStats.operationsCreated"></widget-stats-count>
        </v-col>
    </v-row>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import {
    GChart
} from "vue-google-charts";
import WidgetStatsCount from './WidgetStatsCount.vue';
export default {
    components: {
        GChart,
        WidgetStatsCount,
    },
    data() {
        return {
            operationStats: {},
            chartData: {},
            title: "Month Wise Operation Count",
            chartOptions: {
                title:"XXXX",
                height: 300,
                pieHole: 0.8,
                colors: ["#774EA7", "#F99F1A", "red", "#00A9F4"],
                legend:{
                    "position": "bottom"
                }
            },
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.chartData = [
                ["Operations", "Count"]
            ];
            this.operationStats = await this.getItem(
                appConstants.STATS_API + "/operation-stats"
            );
            Object.keys(this.operationStats).forEach((key) => {
                if (key != "_id")
                    this.chartData.push([key, this.operationStats[key]]);
            });
        },
    },
};
</script>
