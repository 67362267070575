<template>
<v-row>
    <v-col cols="12" sm="10" offset-sm="1">
        <v-card flat>
            <v-card-text>
                <section-header title="Customer Details"/>
                <div>
                    <v-layout wrap>
                        <v-col cols="12" sm="6">
                            <row-view :label="customer.type == 'B2C'?'Name':'Company Name'" :text="customer.displayName"></row-view>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <row-view label="UserName" v-bind:text="userName"></row-view>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <row-view label="E-mail" v-bind:text="customer.email"></row-view>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <row-view label="Mobile No." v-bind:text="customer.phoneNo"></row-view>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <row-view label="Relation Ship Manager" :text="customer.rm?customer.rm.displayName:'-'"></row-view>
                        </v-col>
                        <v-col v-if="customer.type == 'B2C'" cols="12" sm="6">
                            <row-view label="DOB" v-bind:text="customer.dob"></row-view>
                        </v-col>
                        <v-col v-if="customer.type == 'B2C'" cols="12" sm="6">
                            <row-view label="Active Status" v-bind:text="customer.active"></row-view>
                        </v-col>
                        <v-col v-if="customer.type == 'B2C'" cols="12" sm="6">
                            <row-view label="Allow Login Status" v-bind:text="customer.allowLogin"></row-view>
                        </v-col>
                        <v-col>
                            <view-meta-data :item="customer"></view-meta-data>
                        </v-col>
                    </v-layout>
                    <v-col>
                        <row-view label="Addresses"></row-view>
                        <address-table :addresses="customer.addresses" :readOnly="true" />
                    </v-col>
                </div>
            </v-card-text>
            <v-col v-if="customer.type == 'B2B'">
                <row-view label="ContactPersons"></row-view>
                <v-card outlined>
                    <v-card-text>
                        <v-simple-table height="auto" v-if="$vuetify.breakpoint.smAndUp">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">First Name</th>
                                        <th class="text-left">Last Name</th>
                                        <th class="text-left">E-mail</th>
                                        <th class="text-left">Mobile No.</th>
                                        <th class="text-left">Designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(person, index) in persons">
                                        <tr :key="index">
                                            <td>{{ person.firstName }}</td>
                                            <td>{{ person.lastName }}</td>
                                            <td>{{ person.email }}</td>
                                            <td>{{ person.phoneNo }}</td>
                                            <td>{{ person.designation }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-layout v-if="$vuetify.breakpoint.xsOnly">
                            <v-col v-for="item in persons" :key="item._id">
                                <view-contact-person :contact="item" />
                            </v-col>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <div class="d-flex justify-space-between">
                    <label class="title font-weight-bold mx-2">Operations</label>
                    <operation-dialog title="Add New Operation" :account="createReference(customer._id, customer.displayName, customer.email)"></operation-dialog>
                </div>
                <br>
                <v-tabs v-model="tab" fixed-tabs show-arrows>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab v-for="(item, i) in items" :key="i" @click="getOperations(i)">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="item in items" :key="item">
                        <v-data-table :headers="headers" :items="customerOperations" class="elevation-1" sort-by="actionDate" :items-per-page="-1" hide-default-footer>
                            <template v-slot:item.name="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                                <label @click="$router.push(`/operation/${item._id}`)" class="link">{{ item.name}}</label>
                            </template>
                            <template v-slot:item.actionDate="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                                {{ item.actionDate | dateFormat }}
                            </template>
                            <template v-slot:item.currentTask.displayName="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                                <status :block="false" :label="item.currentTask.displayName"></status>
                            </template>
                            <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                                <operation-responsive-card :operation="item"></operation-responsive-card>
                            </template>
                        </v-data-table>
                        <pagination v-model="customerOperations" :api="getApi" :customerId="$route.params.id" />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-card-text align="right">
                <back-button @click="goBack()" />
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "@/components/BackButton.vue";
import AddressTable from "../../components/AddressTable.vue";
import Pagination from "../../components/Pagination.vue";
import ViewContactPerson from '@/components/ViewContactPerson.vue'
import OperationResponsiveCard from '@/components/OperationResponsiveCard'
import moment from "moment"
import SectionHeader from '../../components/SectionHeader.vue';
import OperationDialog from '../../components/OperationDialog.vue';
import Status from '../../components/Status.vue';
export default {
    components: {
    BackButton,
    AddressTable,
    Pagination,
    ViewContactPerson,
    OperationResponsiveCard,
    SectionHeader,
    OperationDialog,
    Status
},
    data: () => ({
        customer: {},
        persons: [],
        id: 0,
        tab: null,
        items: ["Ongoing", "Future", "Completed"],
        customerOperations: [],
        fields: "?fields=name,actionDate,account,process,type,currentTask,createdAt,completed&sort=+actionDate",
        api: "",
        userName: '',
        headers: [{
                text: "Operation",
                value: "name"
            },
            {
                text: "Process",
                value: "process.displayName"
            },
            {
                text: "Action Date",
                value: "actionDate"
            },
            {
                text: "Type",
                value: "type"
            },
            {
                text: "Current Status",
                value: "currentTask.displayName"
            }
        ]
    }),
    mounted() {
        this.initialize();
        this.getOperations(this.tab)
    },
    computed: {
        getApi() {
            return this.api
        }
    },
    methods: {
        async getOperations(i) {
            if (i == 0 || this.tab == null) {
                this.api = appConstants.OPERATIONS_API + this.fields + `&conditions=type=NORMAL,completed=false,account._id=${this.$route.params.id}&size=5`
            }
            if (i == 1) {
                this.api = appConstants.OPERATIONS_API + this.fields + `&conditions=type=NORMAL,completed=false,account._id=${this.$route.params.id},actionDate>${moment(new Date()).format(appConstants.DATEFORMAT.YMD)}&size=5`
            }
            if (i == 2) {
                this.api = appConstants.OPERATIONS_API + this.fields + `&conditions=type=NORMAL,completed=true,account._id=${this.$route.params.id}&size=5`
            }
            this.customerOperations = (await this.getItem(this.api)).data
        },
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.customer = await this.getItem(
                    appConstants.CUSTOMERS_API + "/" + this.id
                );
                let currentUserName = (await this.getItem(appConstants.CUSTOMERS_API + "/" + this.id + "/userProfile"))
                this.userName = currentUserName.length > 0 ? currentUserName[0].userName : ''
            } catch (error) {
                this.handleError(error);
            }
            try {
                this.persons = (
                    await this.getItem(appConstants.CUSTOMERS_API + `/agents?conditions=parent=${this.customer._id.toString()}`)
                );
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
