<template>
<div>
    <v-flex xs12 sm4>
        <template>
            <v-row justify="center">
                <dialog-box :dialog="dialog" title="Add Action" :errorMessage="msg" @close="close()">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card ref="form" flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-autocomplete :rules="notEmpty" v-model="currentAction.source" :items="steps" :return-object="true" item-text="displayName" item-value="id" label="Source Step *" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-text-field v-model="currentAction.name" :rules="notEmpty" label="Name *" clearable></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-autocomplete :rules="notEmpty" v-model="currentAction.destination" :items="steps" :return-object="true" item-text="displayName" item-value="id" label="Destination Step *" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-text-field v-model.number="currentAction.weight" type="number" label="Weight" clearable></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid" @click="validate()">Submit</v-btn>
                                <v-btn @click="close()">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </dialog-box>
            </v-row>
        </template>
    </v-flex>
</div>
</template>

<script>
import DialogBox from "./DialogBox.vue";
import {
    ObjectId
} from "bson";

export default {
    components: {
        DialogBox,
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        dialog: {
            type: Boolean,
            default: () => false,
        },
        i: {
            type: Number,
            default: () => 0,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        currentAction: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    data() {
        return {
            notEmpty: [(v) => !!v || "Cannot be Empty"],
            valid: true,
        };
    },
    methods: {
        close() {
            !this.msg && this.currentAction._id ?
                this.$emit("close", -1) :
                this.$emit("close", this.i - 1);
        },
        async validate() {
            this.msg = ""
            if (this.$refs.form.validate()) {
                let repeatedActions = this.actions.filter(
                    (action) => action.name == this.currentAction.name
                );
                if (repeatedActions.length > 1)
                    this.msg =
                    "Action name " + this.currentAction.name + " is already in use";
                else {
                    if (!this.currentAction._id) this.currentAction._id = new ObjectId();
                    this.$emit("submit");
                }
            }
        },
    },
};
</script>

<style scoped>
</style>
