<template>
<v-container fluid>
    <v-row justify="center">
        <v-col cols="8" sm="8" class="text-center">
            <heading title="Processes"></heading>
        </v-col>
        <v-col cols="4" sm="4" class="text-right mt-4">
            <v-row no-gutters :class="$vuetify.breakpoint.smAndUp?'mt-2 ml-12':''">
                <template-dialog @update="readProcesses()" :class="$vuetify.breakpoint.xsOnly?'ml-7':''"></template-dialog>
                <export-button :selectedData="selectedProcesses" :show="isAllowed('exportProcesses')" :multiple="true" @export="exportProcesses">
                    <v-btn :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" @click="selectWorkspace()" class="mr-2">
                        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-archive-arrow-down </v-icon>
                        Import
                    </v-btn>
                    <add-btn @click="add()" permission="addProcess" tooltip="Add process" ></add-btn>
                </export-button>
            </v-row>
        </v-col>
    </v-row>
    <alert-message :message="msg" :messageType="msgType" />
    <v-data-table v-model="selected" show-select item-key="name" :headers="headers" :items="processList" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Process'" @apply-filters="$refs.pagination.filterApi($event, 'Process')" @clear-filters="readProcesses(true)" />
        </template>
        <template v-slot:item.isTemplate="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.isTemplate" v-bind="attrs" v-on="on" class="active-icon">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Yes</span>
                </v-tooltip>
            </template>
        <template align="right" v-slot:[`item.action`]="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <template v-for="icon in icons">
                <action-button :class="icon.name=='mdi-content-copy'?'mx-3':'mr-2'" :key="icon.name" :show="isAllowed(icon.permission)" :icon="icon" @click="
              icon.name == 'mdi-delete'
                ? deleteProcess(item)
                :icon.name =='mdi-content-copy'
                ?$router.push({ name: 'AddProcess', params: { id: item._id ,action:'COPY' }}):
                performAction(item._id, icon)
            "></action-button>
            </template>
        </template>
        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
            <simple-card @view="$router.push('/process/'+item._id)" @update="$router.push('/processes/'+item._id)" @delete="deleteProcess(item)" @click="$router.push({ name: 'AddProcess', params: { id: item._id ,action:'COPY' }})" :title="item.name+'(v'+item.version+')'" :subtitle="item.workspace?.displayName" :actions="icons" :description="`${item.turnAroundTime.value ? (item.turnAroundTime.value+' Days') : ''}`"></simple-card>
        </template>
    </v-data-table>
    <pagination ref='pagination' v-model="processList" :api="api" />
    <import-process-dialog :dialog="dialog" @close="dialog = false" @submit="submit" />
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import AlertMessage from "../../components/AlertMessage.vue";
import ViewLabel from "../labels/ViewLabel.vue";
import ExportButton from "../../components/ExportButton.vue";
import DialogBox from "../../components/DialogBox.vue";
import axios from "axios";
import ImportProcessDialog from "../../components/ImportProcessDialog.vue";
import Pagination from '../../components/Pagination.vue';
import SimpleCard from '../workspaces/SimpleCard.vue'
import TemplateDialog from "../../components/TemplateDialog.vue";
export default {
    components: {
        AlertMessage,
        ViewLabel,
        ExportButton,
        DialogBox,
        ImportProcessDialog,
        Pagination,
        SimpleCard,
        TemplateDialog
    },
    data() {
        return {
            singleSelect: false,
            selected: [],
            search: "",
            icons: [{
                    name: appConstants.ICONS.VIEW,
                    path: "/process/",
                    permission: "viewProcessDetails",
                    event: 'view'
                },
                {
                    name: appConstants.ICONS.UPDATE,
                    path: "/processes/",
                    permission: "updateProcess",
                    event: 'update'
                },
                {
                    name: appConstants.ICONS.DELETE,
                    permission: "deleteProcess",
                    event: 'delete'
                },
                {
                    name: appConstants.ICONS.COPY,
                    permission: "addProcess",
                    event: 'click'
                },
            ],
            headers: [{
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Version",
                    value: "version",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: "Process time(Days)",
                    value: "turnAroundTime.value",
                    align: "center"
                },
                {
                    text: "Workspace",
                    value: "workspace.displayName",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            processList: [],
            dialog: false,
            api: appConstants.PROCESSES_API + "?fields=name,description,status,parentId,version,workspace,turnAroundTime,isTemplate&status=ACTIVE,DRAFT",
        };
    },
    mounted() {
        if(this.$store.getters.profile.level == 1){
            this.headers.pop()
            this.headers.push({text: "Template",value: "isTemplate"})
            this.headers.push({text: "Action",value: "action",align: "right"})
        }
        this.readProcesses();
    },
    computed: {
        selectedProcesses() {
            return this.selected;
        },
    },
    methods: {
        selectWorkspace() {
            this.dialog = true;
        },
        submit(error) {
            this.dialog = false;
            if (error) {
                this.handleError(error);
                this.msg = error.response.data.details;
            } else {
                alert("Processes imported successFully");
                this.readProcesses();
            }
        },
        async exportProcesses(exportData) {
            let processIds = exportData.map((process) => process._id);
            let processData = {
                processIds: processIds
            };
            try {
                await axios
                    .post(
                        this.buildApiUrl(appConstants.PROCESSES_API + "/export"),
                        processData,
                        this.commonHeader(), {
                            responseType: "arraybuffer",
                            headers: {
                                Accept: "application/octet-stream"
                            },
                        }
                    )
                    .then((response) => {
                        this.downloadFile(response, "exportedProcesses.json");
                    });
                let msg =
                    this.selectedProcesses.length > 0 ?
                    "Processes exported successfully !" :
                    "Process exported successfully !";
                alert(msg);
            } catch (error) {
                this.handleError(error);
            }
        },
        async deleteProcess(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this process ?",
                        appConstants.PROCESSES_API + "/" + item._id
                    )
                )
                    this.processList.splice(this.processList.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/processes/0");
        },
        async readProcesses(checkFilter) {
            if (checkFilter)
                this.$refs.filter.checkHistory()
            this.msg = "";
            this.processList = [];
            try {
                this.processList = await this.$refs.pagination.init(this.api)
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>

<style scoped>
</style>
