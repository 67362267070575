<template>
<div>
    <v-card flat>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <center>
                    <h2>{{ formTitle }}</h2>
                </center>
                <v-card ref="form" flat>
                    <v-card-text>
                        <alert-message :message="msg" :messageType="msgType" />
                        <v-text-field v-model="process.name" :rules="nameRules" label="Process name *" style="font-size: 10" required clearable></v-text-field>
                        <label>Description</label>
                        <wysiwyg v-model="process.description"></wysiwyg>
                        <v-row>
                            <v-col>
                                <v-autocomplete v-model="process.workspace" :items="workspaces" item-text="displayName" :disabled="id != 0 || ($route.params.action && $route.params.action != 'COPY')||!modulePermission('Workspaces')" label="Workspace" item-value="id" return-object clearable></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-autocomplete v-model="process.pm" :items="processManagerData" item-text="displayName" label="Process Manager" item-value="id" return-object clearable></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-chip v-for="(step, index) in process.steps" :key="index + 100" row class="ma-2" :rules="notEmpty" label close @click:close="removeStep(index)" @click="openStepDialog(index)">{{ step.name }}
                        </v-chip>
                        <step-form :value="currentStep" :dialog="stepDialog" :i="stepCount" :steps="process.steps" @submit="addStepToProcess()" @close="removeStep" />
                        <v-row>
                            <v-col>
                                <v-text-field v-model.number="process.turnAroundTime.value" label="Processing Time" type="number" clearable>
                                    <template v-slot:append>
                                        Days
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="2">
                                <v-checkbox v-model="process.flexibleTimeline" label="Flexible Timeline"></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <v-checkbox v-if="$store.getters.profile.level == 1" v-model="process.isTemplate" label="Use as Template"></v-checkbox>
                                <!-- <v-select v-model="process.turnAroundTime.unit" label="Processing Time Unit" :items="['MINUTES', 'DAYS']" clearable></v-select> -->
                            </v-col>
                        </v-row>
                        <v-col cols="12">
                            <v-btn block @click="addStep()">
                                <v-icon>mdi-plus</v-icon>Add Step
                            </v-btn>
                        </v-col>
                        <v-chip v-for="(action, index) in process.actions" :key="index" row class="ma-2" :rules="notEmpty" label close @click:close="removeAction(index)" @click="openActionDialog(index)">{{ action.name }}
                        </v-chip>
                        <action-form :value="currentAction" :dialog="actionDialog" :i="actionCount" :actions="process.actions" :steps="stepsReferences" @submit="addActionToProcess()" @close="removeAction" />
                        <br />
                        <v-col cols="12">
                            <v-btn block @click="addAction()">
                                <v-icon>mdi-plus</v-icon>Add Action
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <import-process-from-mermaid-graph @convert="importProcessFromMermaid($event)"></import-process-from-mermaid-graph>
                        </v-col>
                        <alert-message :message="msg" :messageType="msgType" />
                    </v-card-text>
                    <div align="center">
                        <diagram :process="process"></diagram>
                    </div>
                    <div align="right">
                        <form-buttons :valid="valid" @cancel="goBack()" @submit="validate()"></form-buttons>
                    </div>
                </v-card>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import StepForm from "@/components/StepForm.vue";
import ActionForm from "@/components/ActionForm.vue";
import BackButton from "@/components/BackButton.vue";
import Diagram from "../../components/Diagram.vue";
import ImportProcessFromMermaidGraph from '../../components/ImportProcessFromMermaidGraph.vue';

export default {
    components: {
        BackButton,
        FormButtons,
        AlertMessage,
        StepForm,
        ActionForm,
        Diagram,
        ImportProcessFromMermaidGraph,
    },
    data: () => ({
        notEmpty: [(v) => !!v || "Cannot be Empty"],
        valid: true,
        nameRules: [(v) => !!v || "Name is required"],
        process: {
            turnAroundTime: {
                unit: 'DAYS'
            },
        },
        id: 0,
        formTitle: "",
        steps: [],
        diagram: `graph LR
            A(Alignment)
            B(Back)
            A-->B
            B-->C
            B-->B
            C-->B`,
        stepDialog: false,
        actionDialog: false,
        currentStep: {
            _id: appConstants.INITIAL_VALUE,
            name: appConstants.INITIAL_VALUE,
            description: appConstants.INITIAL_VALUE,
            assignedTo: appConstants.INITIAL_VALUE,
            priority: "MEDIUM",
            sequence: 0,
            turnAroundTime: {
                number: 1,
                type: "DAYS",
            },
            type: "TASK",
            startStep: false,
            endStep: false,
        },
        steps: [],
        actions: [],
        currentAction: {
            _id: appConstants.INITIAL_VALUE,
            name: appConstants.INITIAL_VALUE,
            sourceStep: appConstants.INITIAL_VALUE,
            destinationStep: appConstants.INITIAL_VALUE,
            weight: appConstants.INITIAL_VALUE,
        },
        actionCount: 0,
        stepCount: 0,
        workspaces: [],
        processManagerData: []
    }),
    mounted() {
        this.initialize();
    },
    computed: {
        stepList() {
            let stepIds = [];
            if (this.process.steps && this.process.steps.length > 0)
                stepIds = this.process.steps.map((step) => step._id);
            if (this.process.actions && this.process.actions.length > 0) {
                this.process.actions.forEach((action, i) => {
                    if (action.source && action.destination) {
                        if (
                            stepIds.indexOf(action.source._id) == -1 ||
                            stepIds.indexOf(action.destination._id) == -1
                        ) {
                            this.process.actions.splice(i, 1);
                            this.actionCount--;
                        } else {
                            let sourceStep = this.process.steps.find(
                                (step) => step._id == action.source._id
                            );
                            let destinationStep = this.process.steps.find(
                                (step) => step._id == action.destination._id
                            );
                            action.source = this.createReference(
                                sourceStep._id,
                                sourceStep.name,
                                sourceStep.description
                            );
                            action.destination = this.createReference(
                                destinationStep._id,
                                destinationStep.name,
                                destinationStep.description
                            );
                        }
                    }
                });
            }
            return this.process.steps;
        },
        stepsReferences() {
            let steps = [];
            if (this.stepList && this.stepList.length > 0) {
                this.stepList.forEach((step) => {
                    steps.push(
                        this.createReference(step._id, step.name, step.description)
                    );
                });
            }
            return steps;
        },
        calculateProcessTAT() {
            this.process.turnAroundTime.value = 0
            this.process.steps.forEach(rec => {
                if (!rec.endStep)
                    this.process.turnAroundTime.value += rec.turnAroundTime.number
            })
        },
    },
    methods: {
        addStepToProcess() {
            this.stepDialog = false;
            let index = this.process.steps.findIndex(
                (step) => step._id == this.currentStep._id
            );
            if (index > -1) this.process.steps.splice(index, 1, this.currentStep);
            else this.process.steps.push(this.currentStep);
            this.calculateProcessTAT
        },
        removeStep(index) {
            if (index != -1) {
                this.stepCount--;
                this.process.steps.splice(index, 1);
            }
            this.stepDialog = false;
            this.calculateProcessTAT
        },
        initStep() {
            return {
                _id: null,
                name: appConstants.INITIAL_VALUE,
                description: appConstants.INITIAL_VALUE,
                assignedTo: null,
                priority: "MEDIUM",
                sequence: 0,
                turnAroundTime: {
                    number: 1,
                    type: "DAYS",
                },
                type: "TASK",
                startStep: false,
                endStep: false,
                flexibleTimeline: false
            };
        },
        openStepDialog(index) {
            this.currentStep = this.process.steps[index];
            this.stepDialog = true;
        },
        addStep() {
            this.stepCount = this.process.steps.length;
            this.currentStep = this.initStep();
            this.stepCount++;
            this.stepDialog = true;
        },
        addActionToProcess() {
            this.actionDialog = false;
            let index = this.process.actions.findIndex(
                (action) => action._id == this.currentAction._id
            );
            if (index > -1) this.process.actions.splice(index, 1, this.currentAction);
            else this.process.actions.push(this.currentAction);
        },
        removeAction(index) {
            if (index != -1) {
                this.actionCount--;
                this.process.actions.splice(index, 1);
            }
            this.actionDialog = false;
        },
        initAction() {
            return {
                _id: null,
                name: null,
                source: null,
                destination: null,
                weight: appConstants.PROCESS.ACTIONS.WEIGHT,
            };
        },
        openActionDialog(index) {
            this.currentAction = this.process.actions[index];
            this.actionDialog = true;
        },
        addAction() {
            this.actionCount = this.actions.length;
            this.currentAction = this.initAction();
            this.actionCount++;
            this.actionDialog = true;
        },
        async initialize() {
            this.id = this.$route.params.id;
            this.process = {
                name: appConstants.INITIAL_VALUE,
                description: appConstants.INITIAL_VALUE,
                steps: [],
                actions: [],
                workspace: {},
                pm: {},
                orgId: appConstants.INITIAL_VALUE,
                turnAroundTime: {
                    unit: 'DAYS'
                },
                isTemplate: false
            };
            this.processManagerData = (
                await this.getItem(
                    appConstants.EMPLOYEES_API + "?fields=_id,displayName,email&size=-1"
                )
            ).data;
            if (this.modulePermission('Workspaces')) {
                let workspaceData = (
                    await this.getItem(
                        appConstants.WORKSPACES_API + "?fields=name,description&size=-1"
                    )
                ).data;
                workspaceData.forEach((workspace) => {
                    this.workspaces.push(
                        this.createReference(
                            workspace._id,
                            workspace.name,
                            workspace.description
                        )
                    );
                });
            }
            if (this.$route.params && this.$route.params.action &&
                this.$route.params.action == appConstants.PROCESS.ACTIONS.COPY &&
                this.$route.params.id) {
                try {
                    this.process = await this.getItem(
                        appConstants.PROCESSES_API + "/" + this.id
                    );
                    this.id = 0
                    delete this.process._id
                    delete this.process.version
                    delete this.process.parentId
                    this.process.name = "Copy of " + this.process.name
                    this.process.status = appConstants.PROCESS.STATUS.DRAFT
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
            } else if (this.id != 0) {
                this.formTitle = "Edit process";
                try {
                    this.process = await this.getItem(
                        appConstants.PROCESSES_API + "/" + this.id
                    );
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
                this.stepCount = this.process.steps.length;
                this.actionCount = this.process.actions.length;
            } else {
                this.formTitle = "Add new process";
            }
        },
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.id == 0) {
                        this.process = await this.postItem(
                            appConstants.PROCESSES_API,
                            this.process
                        );
                    } else {
                        this.process = await this.putItem(
                            appConstants.PROCESSES_API + "/" + this.id,
                            this.process
                        );
                    }
                    this.$router.push("/processes");
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
            }
        },
        importProcessFromMermaid(graph) {
            this.process.actions = graph.actions
            this.process.steps = graph.steps
        }
    },
};
</script>
