<template>
<v-navigation-drawer v-model="drawer" mini-variant-width="auto" :mini-variant="$vuetify.breakpoint.smAndUp" :permanent="$vuetify.breakpoint.smAndUp" clipped :color="themeSidebar" app>
    <v-list>
        <menu-item icon="mdi-view-dashboard" text="Dashboard" path="/home" v-if="$store.getters?.profile?.type=='EMPLOYEE'" />
        <template v-for="item in appDrawerItems">
            <menu-item v-if="($store.getters.profile?.level==1||billingModules.includes(item.text))&&isAllowed(item.permission)" :text="item.text" :icon="item.icon" :path="item.path" :key="item.text" />
        </template>
    </v-list>
    <center class="version-block" :style="`color:${secondaryColor}`">
        v{{require('../../package.json').version}}
    </center>
</v-navigation-drawer>
</template>

<script>
import appConstants from '../utils/appConstants';
import MenuItem from "./MenuItem.vue";
export default {
    components: {
        MenuItem,
    },
    props: {
        value: {
            type: Boolean,
            defualt: () => true,
        },
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        }
    },  
    data() {
        return {
            mini: true,
            billingModules:[],
            activeBillingAccount:false
        };
    },
    mounted () {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.billingModules=await this.getModules()
        }
    },
};
</script>

<style lang="scss" scoped>
.version-block{
    padding: 10px 0;
    color: white;
    font-size: 13px;
}
</style>
