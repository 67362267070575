export default Object.freeze({
    ORGANIZATIONS_API: "/api/organizations",
    MEDIA_API:"/api/media",
    PROCESSES_API: "/api/processes",
    EMPLOYEES_API: "/api/employees",
    CUSTOMERS_API: "/api/customers",
    SERVICES_API: "/api/services",
    LABELS_API: "/api/labels",
    ROLES_API: "/api/roles",
    OPERATIONS_API: "/api/operations",
    CUSTOMERS_API: "/api/customers",
    TASKS_API: "/api/tasks",
    GROUPS_API: "/api/groups",
    WORKSPACES_API: "/api/workspaces",
    AUTHENTICATE_API: "/api/authenticate",
    KANBAN_API: "/api/kanban",
    STATS_API:"/api/stats",
    STATS_TASKS_API: "/api/stats/task-stats",
    PROJECT_API: "/api/projects",
    SEARCH_API: "/api/search",
    CUSTOM_FIELD_API: "/api/customFields",
    IMPORT_FILE:"/api/importFile",
    NOTIFICATIONS_API: "/api/notifications",
    PLANS_API:"/api/plans",
    SUBSCRIPTION_API:"/api/subscriptions",
    BILLING_ACCOUNTS_API:"/api/billingAccounts",
    WEBSTORE_API:"/api/webstore",
    STRIPE_CHECKOUT:"/stripe/checkout",
    STRIPE_CUSTOMER_PORTAL: "/stripe/customer-portal",
    FILTER_API:"/api/filter",
    TEMPLATE_API:"/api/templates",
    STATUS_CODE: {
        UNAUTHORISED: 403,
        SESSION_TIMEOUT: 419
    },
    PROCESS: {
        STATUS: {
            ACTIVE: "ACTIVE",
            DRAFT: "DRAFT",
            INACTIVE: "INACTIVE"
        },
        PRIORITY: {
            HIGH: "HIGH",
            MEDIUM: "MEDIUM",
            LOW: "LOW"
        },
        ACTIONS:{
            WEIGHT:1,
            COPY:'COPY'
        }
    },
    OPERATION: {
        PROCESS_EXECUTION: {
            AUTOMATIC: "AUTOMATIC",
            MANUAL: "MANUAL"
        },
        TYPE:{
            NORMAL:"NORMAL",
            RECURSIVE:"RECURSIVE"
        },
        WIDGET:{
            ACTIVE:"ACTIVE",
            CURRENT_MONTH:"CURRENT_MONTH"
        }
    },
    DATEFORMAT: {
        YMD: "YYYY-MM-DD",
        DMY: "DD/MM/YYYY",
        MDY:"MM/DD/YYYY"
    },
    ICONS: {
        DELETE: "mdi-delete",
        UPDATE: "mdi-pencil",
        VIEW: "mdi-eye",
        EXPORT: "mdi-archive-arrow-up",
        COPY: "mdi-content-copy"
    },
    SIZE: 20,
    B2B: "B2B",
    INITIAL_VALUE: undefined,
    ACCOUNT:{
        TYPE:{
            B2B: "B2B",
            B2C: "B2C",
            EMPLOYEE: "EMPLOYEE",
            AGENT: "AGENT"
        }
    },
    TASK:{
        MY_TASKS:"MY_TASKS",
        MY_TASK_DUE_TODAY:"MY_TASK_DUE_TODAY",
        MY_OVERDUE_TASK:"MY_OVERDUE_TASK",
        ACTIVE_TASK:"ACTIVE_TASK",
        TODAY_DUE_TASK:"TODAY_DUE_TASK",
        OVERDUE_TASK:"OVERDUE_TASK",
        ORPHAN_TASK:"ORPHAN_TASK"
    },
    CUSTOM_FIELD:{
        TYPE:{
            TEXT:"TEXT",
            NUMERIC:"NUMERIC",
            PREDEFINED:"PREDEFINED"
        }
    },
    RAZORPAY_KEY:"rzp_test_gDok3MpNgcmpGP",
    TEMPLATE:{
        TYPE:{
            OPERATION:"OPERATION"
        }
    }
})