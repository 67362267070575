<template>
<div>
    <v-btn :disabled="selectedData.length == 0" :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" v-if="show && multiple" @click="exportData" class="mr-2">
        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-archive-arrow-up</v-icon>
        Export
    </v-btn>
    <v-btn v-if="show && !multiple" fab x-small dark @click="exportData" class="mr-2">
        <v-icon>mdi-archive-arrow-up</v-icon>
    </v-btn>
    <slot></slot>
</div>
</template>

<script>
import ActionButton from "./ActionButton.vue";

export default {
    components: {
        ActionButton,
    },
    props: {
        selectedData: {
            type: Array,
            default: Array,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        singleData: {
            type: Object,
            default: Object,
        },
    },
    methods: {
        exportData() {
            let exportData = [];
            if (!this.multiple) exportData.push(this.singleData);
            else exportData = this.selectedData;
            this.$emit("export", exportData);
        },
    },
};
</script>

<style>
</style>
