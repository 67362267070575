<template>
<v-container>
    <center>
        <v-card flat color="grey lighten-3" width="auto">
            <v-form ref="form" lazy-validation>
                <v-card-text>
                    <center>
                        <h2>Reset Password Form</h2>
                    </center>
                </v-card-text>
                <password-reset :forceReset="false" ref="passwordResetForm" :userName="profileDetails.username" @save="resetPassword($event)" @close="$router.push('/home')"></password-reset>
            </v-form>
        </v-card>
    </center>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import PasswordReset from "@/components/PasswordReset.vue";
export default {
    components: {
        PasswordReset,
        appConstants,
    },
    data() {
        return {
            profileDetails: JSON.parse(localStorage.getItem("profile")),
            selectedId: appConstants.INITIAL_VALUE,
        };
    },
    methods: {
        async resetPassword(event) {
            try {
                this.selectedId = this.profileDetails._id;
                await this.putItem(
                    appConstants.EMPLOYEES_API + "/" + this.selectedId + "/resetPassword",
                    event
                );
                this.$refs.passwordResetForm.resetForm();
                alert("Password reset successfully!!");
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
