<template>
<div>
    <v-flex xs12 sm4>
        <template>
            <v-row justify="center">
                <dialog-box :dialog="dialog" title="Select Workspace" :errorMessage="msg" @close="$emit('close')">
                    <br />
                    <v-form enctype="multipart/form-data">
                        <file-upload label="Select json file" v-model="jsonFile" />
                        <v-radio-group v-model="useDefaultWorkspace" mandatory row>
                            <v-radio label="Import default workspace for all processes" :value="true"></v-radio>
                            <v-radio label="Create workspace if not exist" :value="false"></v-radio>
                        </v-radio-group>
                        <div align="right">
                            <v-btn :disabled="jsonFile == null" @click="submit">Submit</v-btn>
                        </div>
                    </v-form>
                </dialog-box>
            </v-row>
        </template>
    </v-flex>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import DialogBox from "./DialogBox.vue";
import AlertMessage from "./AlertMessage.vue";
import FileUpload from "./FileUpload.vue";
import axios from "axios";

export default {
    components: {
        DialogBox,
        AlertMessage,
        FileUpload,
    },
    props: {
        dialog: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            useDefaultWorkspace: true,
            jsonFile: null
        };
    },
    methods: {
        async readFile(file) {
            this.jsonFile = file;
        },
        async submit() {
            try {
                let formData = this.convertToFormData({
                        useDefaultWorkspace: this.useDefaultWorkspace
                    },
                    [this.jsonFile],
                    "jsonFile"
                );
                await axios.post(
                    this.buildApiUrl(appConstants.PROCESSES_API + "/import"),
                    formData,
                    this.commonHeader()
                );
                this.$emit('submit', null)
            } catch (error) {
                this.handleError(error);
                this.$emit('submit', error)
            }
        },
    },
};
</script>

<style scoped>
.spacing {
    margin-right: 8px;
}
</style>
