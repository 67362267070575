<template>
<v-row dense class="mr-n8">
    <row-view :class="colClass" v-if="item.createdBy" label="CreatedBy" v-bind:text="item.createdBy.displayName" />
    <row-view :class="colClass" v-if="item.updatedBy" label="UpdatedBy" v-bind:text="item.updatedBy.displayName" />
    <row-view :class="colClass" label="CreatedOn" v-bind:text="item.createdAt|dateFormat" />
    <row-view :class="colClass" label="UpdatedOn" v-bind:text="item.updatedAt|dateFormat" />
</v-row>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: {}
        },
        colClass:{
            type:String,
            default:"col-xs-12 col-sm-6"
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
