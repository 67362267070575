<template>
<v-card>
    <v-card-text>
        <h3>{{ title }}</h3>
        <v-layout wrap>
            <v-flex v-for="permission in permissionSet" :key="permission.code" md3 xs12 sm4>
                <switch-button v-model="computedPermissions" :label="permission.name" :code="permission.code" :tooltip="permission.description"></switch-button>
            </v-flex>
        </v-layout>
    </v-card-text>
</v-card>
</template>

<script>
import SwitchButton from './SwitchButton.vue';

export default {
    components: {
        SwitchButton
    },
    props: ["title", "value", "permissionSet"],
    data() {
        return {
            selectedPermissions: [],
        };
    },
    computed: {
        computedPermissions: {
            get() {
                this.selectedPermissions = this.value;
                return this.selectedPermissions;
            },
            set(newValue) {
                this.selectedPermissions = newValue;
                this.$emit("input", this.selectedPermissions);
            },
        },
    },
};
</script>

<style scoped>
</style>
