<template>
<v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
        <section-header title="Task Details"></section-header><br>
        <alert-message :message="msg" :messageType="msgType" />
        <v-row>
            <v-col cols="12" sm="6">
                <row-view label="Name" v-bind:text="task.name"></row-view>
            </v-col>
            <v-col cols="12" sm="6">
                <row-item label="Parent">
                    <label @click="isAllowed('viewOperationDetails') ? $router.push('/operation/' + task.parent._id): ''" class="link">{{task?.parent?.displayName}}</label>
                </row-item>
            </v-col>
            <v-col v-if="task.description" cols="12" sm="6">
                <row-view label="Description" :html="task.description">
                </row-view>
            </v-col>
            <v-col v-if="task.previousTaskInstructions" cols="12" sm="6">
                <row-view label="Previous task instructions" :html="task.previousTaskInstructions">
                </row-view>
            </v-col>
            <v-col v-if="!task.completed" cols="12" sm="6">
                <row-view label="Action date" v-bind:text="task.actionDate | dateFormat"></row-view>
                <row-view label="Action time" v-if="task.actionTime" v-bind:text="task.actionTime"></row-view>
            </v-col>
            <v-col v-if="task.completed && task.updatedAt" cols="12" sm="6">
                <row-view label="Completed on " v-bind:text="task.updatedAt | dateFormat">
                </row-view>
            </v-col>
            <v-col cols="12" sm="6">
                <row-view label="Due date" v-bind:text="task.dueDate | dateFormat"></row-view>
            </v-col>
            <v-col cols="12" sm="6">
                <row-view label="Assigned to " v-if="task.assignedTo">
                    <user-card :user="task.assignedTo"></user-card>
                </row-view>
            </v-col>
            <v-col cols="12" sm="6">
                <row-view label="Priority">
                    <v-chip small label :color="task.priority == 'HIGH' ? 'red' : task.priority == 'MEDIUM' ? 'orange': 'yellow'">{{task.priority}}</v-chip>
                </row-view>
            </v-col>
            <v-col cols="12" sm="6">
                <row-view label="Time Spent" v-bind:text="task.timeSpent+' min(s)'"></row-view>
            </v-col>
            <v-col cols="12" sm="6">
                <row-view label="Status">
                    <status :label="task.completed ? 'Completed' : 'Pending'" :block="false"></status>
                </row-view>
            </v-col>
            <v-col sm="12">
                <view-meta-data :item="task"></view-meta-data>
            </v-col>
        </v-row>
        <div v-if="isAllowed('updateTask')">
            <section-header title="Update Details" class="mt-5"></section-header><br>
            <v-row>
                <v-col cols="12">
                    <row-item label="Notes" v-if="task.completed || !task.assignedTo">
                        <p v-html="task.note"></p>
                    </row-item>
                    <v-textarea v-else :rules="notEmpty" label="Note *" rows="6" v-model="task.note"></v-textarea>
                    <custom-field v-if="!task.completed && task.customFields.length>0 && this.checkModule('CustomFields') != null" :customFields="task.customFields"/>
                </v-col>
                <v-col cols="12">
                    <row-item label="Instructions for next task" v-if="task.completed || !task.assignedTo">
                        <p v-html="task.nextTaskInstructions"></p>
                    </row-item>
                    <wysiwyg v-model="task.nextTaskInstructions" placeholder="Instructions for next task" v-else></wysiwyg>
                    <v-row v-if="task.completed && task.customFields.length>0">
                        <v-col cols="12" v-for="item in task.customFields" :key="item.field._id">
                            <row-item v-if="item.value" :label="item.field.label">
                                <span v-if="(typeof item.value)=='object'">
                                    {{ item.value.join(",") }}
                                </span>
                                <span v-else>{{ item.value }}</span>
                            </row-item>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" v-if="!task.completed">
                    <v-row class="mx-3">
                        <date title="Next Action date *" :disabled="task.completed || !task.assignedTo" v-model="task.nextActionDate" :rules="notEmpty" />
                    </v-row>
                    <v-row class="mx-3" v-if="task.type=='MEETING'">
                        <set-time :disabled="task.completed || !task.assignedTo" title="Next Action time" v-model="task.nextActionTime" />
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                    <file-download v-if="task.attachments.length>0" :attachments="task.attachments"></file-download>
                    <file-upload v-if="!task.completed" v-model="uploadFile" :multiple="true"></file-upload> OR
                    <file-url v-model="task.attachmentUrls" :readOnly="task.completed" />
                </v-col>
            </v-row>
            <row-view v-if="task.attachments.length>0" class="col-xs-12" label="Attachments" />
            <alert-message :message="msg" :messageType="msgType" />
            <br />
            <v-card flat>
                <v-row class="justify-space-between">
                    <operation-dialog v-if="isAllowed('addOperation') && !task.completed" class="ma-2" @success="operationData($event)" />
                    <div class="d-flex">
                        <template v-if="!task.completed &&task?.assignedTo?._id == this.getProfile().accountId && operation?.executionType != executionType.MANUAL">
                            <v-btn :small="$vuetify.breakpoint.smAndDown" class="ma-2" :disabled="!valid" :loading="actionTaken" v-for="action in actions" v-bind:key="action.name" @click="goTo(action)">
                                {{ action.name }}
                            </v-btn>
                        </template>
                        <template v-if="!task.completed &&task?.assignedTo?._id == this.getProfile().accountId && operation.executionType == executionType.MANUAL">
                            <v-autocomplete v-model="step" filled dense :items="process.steps" item-text="name" :disabled="task.completed || !task.assignedTo" label="Select Next step" item-value="id" return-object></v-autocomplete>
                            <v-btn :small="$vuetify.breakpoint.smAndDown" class="ma-2" @click="goTo()">Submit</v-btn>
                        </template>
                        <back-button class="ma-2" :title="isAllowed('updateTask') && !task.completed && task.assignedTo? 'Cancel': 'Back'" @click="$router.go(-1)" />
                    </div>
                </v-row>
            </v-card>
        </div>
    </v-container>
</v-form>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import Date from "../../components/Date.vue";
import SetTime from "../../components/Time.vue";
import BackButton from "../../components/BackButton.vue";
import RowItem from "../../components/RowItem.vue";
import Status from "../../components/Status.vue";
import UserCard from '../../components/UserCard.vue';
import FileUpload from "@/components/FileUpload.vue";
import FileDownload from "@/components/FileDownload.vue";
import FileUrl from '@/components/FileUrl'
import SectionHeader from '../../components/SectionHeader.vue';
import OperationDialog from "../../components/OperationDialog.vue";
import CustomField from '@/components/CustomField'
var moment = require("moment");

export default {
    components: {
        FormButtons,
        AlertMessage,
        FileUpload,
        FileDownload,
        Date,
        SetTime,
        BackButton,
        RowItem,
        Status,
        UserCard,
        FileUrl,
        SectionHeader,
        OperationDialog,
        CustomField
    },
    data() {
        return {
            uploadFile: [],
            actions: [],
            valid: false,
            users: [],
            task: {
                attachmentUrls: [],
                attachments: [],
                note: "",
                customFields:[]
            },
            notEmpty: [(v) => !!v || "Required"],
            operation: {},
            process: {},
            step: {},
            attachments: [],
            actionTaken: false
        };
    },
    mounted() {
        this.initComponent();
    },
    computed: {
        executionType() {
            return appConstants.OPERATION.PROCESS_EXECUTION
        }
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            try {
                this.task = await this.getItem(appConstants.TASKS_API + "/" + this.id);
                this.operation = await this.getItem(
                    appConstants.OPERATIONS_API + "/" + this.task.parent._id
                );
                this.process = await this.getItem(
                    appConstants.PROCESSES_API + "/" + this.operation.process._id
                );
                let actionList = this.process.actions.filter(
                    (action) => action.source._id == this.task.stepId
                );
                if(!this.task.completed)
                    this.task.customFields=this.process.steps.filter(step => step._id == this.task.stepId).map(rec => rec = rec.customFields)[0]
                actionList.forEach((action) => {
                    this.actions.push({
                        _id: action._id,
                        name: action.name,
                    });
                });
                if(this.checkModule('CustomFields') == null){
                    this.task.customFields.forEach(rec=>{
                        rec.required=false
                    })
                }
            } catch (error) {
                this.handleError(error);
            }
        },
        async goTo(action) {
            if (this.$refs.form.validate()) {
                this.actionTaken = true
                await this.validateDocument()
                this.task.nextActionDate = moment(
                    this.task.nextActionDate,
                    appConstants.DATEFORMAT.DMY
                ).format(appConstants.DATEFORMAT.YMD);
                try {
                    if (
                        this.operation.executionType ==
                        appConstants.OPERATION.PROCESS_EXECUTION.MANUAL
                    )
                        await this.putItem(
                            appConstants.OPERATIONS_API +
                            "/" +
                            this.task.parent._id +
                            "/task/" +
                            this.step._id,
                            this.task
                        );
                    else
                        await this.putItem(
                            appConstants.OPERATIONS_API +
                            "/" +
                            this.task.parent._id +
                            "/task/" +
                            action._id,
                            this.task
                        );
                    this.$router.go(-1);
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
                this.actionTaken = false
            }
        },
        async validateDocument() {
            if (this.uploadFile.length != 0) {
                for (var i = 0; i < this.uploadFile.length; i++) {
                    let formData = new FormData()
                    formData.append('file', this.uploadFile[i])
                    this.media = await this.postItem(
                        appConstants.MEDIA_API + "/upload",
                        formData, {
                            headers: {
                                'Content-type': 'multipart/form-data'
                            }
                        }
                    );
                    this.attachments.push({
                        _id: this.media._id,
                        fileName: this.media.originalFileName,
                        url: this.media.path
                    })
                }
                for (var k = 0; k < this.attachments.length; k++)
                    this.task['attachments'].push(this.attachments[k])
            }
        },
        operationData(operation) {
            alert('Operation ' + operation.name + ' created')
            this.task['note'] = operation.name
        }
    },
};
</script>
