<template>
<div>
    <div class="text-right">
        <v-btn class="base-inverted" @click="toggleDialog()">Import Mermaid graph</v-btn>
    </div>
    <dialog-box :dialog="showDialog" title="Import Mermaid graph" @close="toggleDialog()" width="600">
        <v-textarea style="white-space: pre;" full-width placeholder="Enter mermaid mermaidCode" v-model="mermaidCode"></v-textarea>
        <div class="text-right">
            <v-btn @click="convertToJson(mermaidCode)">Process</v-btn>
        </div>
    </dialog-box>
</div>
</template>

<script>
import DialogBox from './DialogBox.vue'
import mermaidAPI from 'mermaid'
import {
    ObjectId
} from 'bson'
export default {
    components: {
        DialogBox
    },
    data() {
        return {
            showDialog: false,
            mermaidCode: "",
            jsonResult: undefined,
            pattern: /(-->|---*>|--[\w\W]+-->)/,
            actionNamePattern: /--[\w\W]+-->/,
            nodes: [],
            edges: []
        }
    },
    mounted() {
        mermaidAPI.initialize({
            startOnLoad: true
        });;
    },
    methods: {
        toggleDialog() {
            this.showDialog = !this.showDialog
        },
        convertToJson(graphCode) {
            this.nodes = []
            this.edges = []
            const graphLines = graphCode.split('\n').map(line => line.trim());
            if (mermaidAPI.parse(graphCode)) {
                // Parse the graph lines and extract nodes and edges
                for (const line of graphLines) {
                    const match = line.match(this.pattern)
                    //console.log(`Line: '${line}'\tMatch: ${match}`);
                    if (match) {
                        const [source, target] = line.split(match[0]);
                        let sourceNode = this.registerNode(source)
                        let targetNode = this.registerNode(target)
                        this.edges.push(this.registerEdge(sourceNode, targetNode, match[0]));
                    }
                }
            }
            this.replaceNodeIdWithMongoId()
            //console.log("\nEdges: " + JSON.stringify(this.edges));
            this.$emit("convert", {
                steps: this.nodes,
                actions: this.edges
            })
            this.toggleDialog()
        },
        registerNode(name) {
            let id = name.trim()
            let existingNode = this.nodes.find(rec => rec._id == name.trim())
            if (!existingNode && name.includes('[') && name.includes(']')) {
                id = name.substring(0, name.indexOf('[')).trim();
                name = name.substring(name.indexOf('[') + 1, name.indexOf(']')).trim();
            }
            if (!existingNode) {
                existingNode = {
                    _id: id,
                    name: name.trim(),
                    priority: "MEDIUM",
                    type: "TASK",
                    turnAroundTime: {
                        number: 1,
                        type: "DAYS"
                    },
                    startStep: this.nodes.length == 0,
                    endStep: false,
                    sequence: (this.nodes.length + 1)
                }
                this.nodes.push(existingNode)
            }
            return existingNode
        },
        registerEdge(source, target, name) {
            if (name.match(this.pattern)) {
                name = name.replaceAll("-", "").trim()
                name = name.replaceAll(">", "").trim()
                if (!name) {
                    name = `action-${source.sequence}`
                }
            }
            return {
                _id: new ObjectId(),
                name: name.trim(),
                weight: 1,
                source: {
                    _id: source._id,
                    displayName: source.name
                },
                destination: {
                    _id: target._id,
                    displayName: target.name
                }
            }
        },
        replaceNodeIdWithMongoId() {
            let vm = this
            this.nodes.forEach(rec => {
                let newId = new ObjectId()
                let oldId = rec._id
                rec._id = newId
                this.edges.filter(rec => rec.source._id == oldId).forEach(rec => rec.source._id = newId)
                this.edges.filter(rec => rec.destination._id == oldId).forEach(rec => rec.destination._id = newId)
                if (rec.sequence == this.nodes.length) {
                    rec.endStep = true
                }
            })
        },
        //Do not delete: Applicable for  mermaid v10.1.0
        //ref: https://stackblitz.com/edit/node-5crpy6?file=package.json&view=editor
        /* async convertUsingMermaidApi(mermaidGraph) {
            const parser = (
                await mermaid.mermaidAPI.getDiagramFromText(mermaidGraph)
            ).getParser().yy;
            console.log(parser.getEdges());
        } */
    }
}
</script>

<style lang="scss" scoped>

</style>
