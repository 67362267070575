<template>
<v-btn :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" v-if="isAllowed('createImportFile')" :to="{ name: 'ImportFile', params: { importFile: importData}}">
    <v-icon :small="$vuetify.breakpoint.xsOnly" class="mr-2">mdi-file-import</v-icon>
    Import
</v-btn>
</template>

<script>
export default {
    props: ['importData']
}
</script>

<style lang="scss" scoped>

</style>
