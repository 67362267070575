<template>
<v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <center v-on="on" :class="styleClass" @click="$emit('click')">
        <v-icon v-text="icon" :style="themeSidebarFont"></v-icon>
        <v-list-item-subtitle v-text="text" :style="themeSidebarFont" class="apply-font"></v-list-item-subtitle>
      </center>
    </template>
    <span :style="themeSidebar">{{ tooltip }}</span>
</v-tooltip>
</template>

<script>
export default {
    props: ['text', 'icon', 'path', 'tooltip', 'styleClass'],
}
</script>

<style lang="scss" scoped></style>
