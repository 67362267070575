<template>
    <v-flex>
        <v-btn block x-small :class="status" label v-if="block" text>{{label}}</v-btn>
        <v-btn x-small :class="status" label v-if="!block" text>{{label}}</v-btn>
    </v-flex>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: () => ''
            },
            block:{
                type: Boolean,
                default: () => true
            }
        },

        computed: {
            status() {
                switch (this.label.toLowerCase()) {
                    case 'pending': return 'pending';
                    case 'completed': return 'completed';
                    case 'subscribed':return 'subscribed';
                    case 'unsubscribed':return 'unsubscribed';
                    default: return 'default'
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.completed,.subscribed{
    background-color: green !important;
    color: white;
}
.pending,.unsubscribed{
    background-color: red !important;
    color: white;
}
.default{
    background-color: rgb(235, 233, 233) !important;
    color: black;
}

</style>