<template>
    <div>
        <div v-if="urls.length>0">
            <v-chip style="max-width:200px" v-for="(key,index) in urls" :key="index" label class="ml-1 my-1" :close="!readOnly" @click="openUrl(key)" @click:close="removeUrl(index)">{{key}}
                <v-icon v-if="readOnly" class="mx-2">mdi-link</v-icon>
            </v-chip>
        </div>
        <v-text-field v-model="url" @keypress.enter="addUrl()" v-if="!readOnly" placeholder="Enter single file url and press enter">
            <template v-slot:append>
                <v-btn class="ma-2" @click="addUrl()" text><v-icon>mdi-paperclip</v-icon>Add</v-btn>
            </template>
        </v-text-field>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                url: ''
            }
        },
        props: {
            value: {
                type: [Array, String],
                default: () => undefined
            },
            readOnly: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            urls:{
                get(){
                    return this.value ? this.value : []
                },
                set(newValue){
                    this.url = newValue.trim()
                    this.addUrl()
                }
            }
        },
        methods: {
            addUrl() {
                if(this.url){
                    this.urls.push(this.url)
                    this.url = ''
                    this.$emit('input', this.urls)
                }
            },
            removeUrl(index){
                this.urls.splice(index, 1)
                this.$emit('input', this.urls)
            },
            openUrl(url){
                window.open(url, '_blank');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>