import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    profile: JSON.parse(localStorage.getItem('profile')),
    orgDetails:JSON.parse(localStorage.getItem('orgDetails'))||'',
    taskFlag:JSON.parse(localStorage.getItem('taskFlag'))||'',
    planCart:JSON.parse(localStorage.getItem('planCart'))||[],
    filterHistory:JSON.parse(localStorage.getItem('filterHistory'))||[]
  },
  getters:{
    profile:state=>state.profile,
    org:state=>state.orgDetails,
    taskFlag:state=>state.taskFlag,
    planCart:state=>state.planCart,
    filter:state=>state.filterHistory
  },
  mutations: {
    updateOrgSetting: (state, organization) =>{
      state.orgDetails = organization
      localStorage.setItem("orgDetails", JSON.stringify(organization))
    },
    updateTaskFlag:(state,taskFlag)=>{
      state.taskFlag=taskFlag
      if(taskFlag == null){
        localStorage.removeItem("taskFlag")
      }else{
        localStorage.setItem("taskFlag",taskFlag)
      }
    },
    updatePlanCart: (state, planCart) =>{
      state.planCart = planCart
      localStorage.setItem("planCart", JSON.stringify(planCart))
    },
    updateFilter: (state, filter) =>{
      state.filter = filter
      localStorage.setItem("filterHistory", JSON.stringify(filter))
    },
    updateProfile: (state, profile) =>{
      state.profile = profile
      localStorage.setItem("profile", JSON.stringify(profile))
    },
  },
  actions: {
    actionUpdateOrgSetting: ({commit}, data)=>{
      commit('updateOrgSetting', data)
    },
    actionUpdateTaskFlag:({commit},data)=>{
      commit('updateTaskFlag',data)
    },
    actionUpdatePlanCart:({commit},data)=>{
      commit('updatePlanCart',data)
    },
    actionUpdateFilter:({commit},data)=>{
      commit('updateFilter',data)
    },
    actionUpdateProfile:({commit},data)=>{
      commit('updateProfile',data)
    }
  },
  modules: {
  }
})
