<template>
<v-container>
    <v-card flat>
        <v-card-text>
            <center>
                <h2>Custom Field Details</h2>
            </center>
            <br />
            <v-row>
                <v-col sm="4">
                    <row-view label="Label" v-bind:text="customField.label"></row-view>
                </v-col>
                <v-col sm="4">
                    <row-view label="Type" v-bind:text="customField.type"></row-view>
                </v-col>
                <v-col sm="4" v-if="customField.values&&customField.values.length>0">
                    <row-view label="Values">
                        <ul class="mt-2">
                            <li class="body-1" v-for="(item,index) in customField.values" :key="index">
                                {{item}}
                            </li>
                        </ul>
                    </row-view>
                </v-col>
                <v-col cols="12" sm="8">
                    <view-meta-data :item="customField"></view-meta-data>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text align="right">
            <back-button @click="goBack()" />
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "@/components/BackButton.vue";

export default {
    components: {
        BackButton,
    },
    data: () => ({
        customField: {},
        id: 0,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.customField = await this.getItem(appConstants.CUSTOM_FIELD_API + "/" + this.id);
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
