<template>
<v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
        <v-btn :color="primaryColor" dark v-bind="attrs" v-on="on" block height="48">
            Filter
        </v-btn>
    </template>
    <label hidden>{{convertedFilters}}</label>
    <v-list>
        <v-list-item-group multiple v-model="selectedFilters">
            <v-list-item v-for="(item, index) in searchFilters" :key="index" >
                <template v-slot:default="{ active }">
                    <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title style="cursor: pointer">{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</v-menu>
</template>

<script>
export default {
    props: {
        searchFilters: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            selectedFilters: [],
            toggle:false
        }
    },
    mounted () {
        this.init();
    },
    computed: {
        convertedFilters() {
            let filters = []
            this.selectedFilters.forEach(index => {
               filters.push(this.searchFilters[index])
            })
            this.$emit('update', filters)
        }
    },
    methods: {
        init(){
            this.selectedFilters=[]
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
