<template>
<div>
    <v-card flat>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <center>
                    <h2>{{ formTitle }}</h2>
                </center>
                <alert-message :message="msg" :messageType="msgType" />
                <v-card ref="form" flat>
                    <v-card-text>
                        <h3>Organization details</h3>
                        <v-card-text>
                            <v-text-field v-model="organization.orgName" :rules="nameRules" label="Organization name *" style="font-size: 10" @input="generateSlug()" required></v-text-field>
                            <v-textarea v-model="organization.description" label="Description "></v-textarea>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="organization.contactNumber" type="text" label="Contact no.">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="organization.orgEmail" :rules="emailRules" label="E-mail *" :disabled="id != 0" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="organization.slug" onkeypress="return event.charCode != 32" @input="generateUrl()" :rules="slugRules" :hint="url" persistent-hint label="Access Point *"></v-text-field>
                            <br>
                            <v-row>
                                <v-col>
                                    Addresses :<br />
                                    <address-card v-model="address" @add="addAddress()" />
                                    <address-table :addresses="organization.addresses" @delete="removeAddress" />
                                </v-col>
                            </v-row><br>
                            <template>
                                <h3>Admin details</h3>
                                <org-admin v-model="organization.admins"></org-admin>
                            </template>
                            <template>
                                <h3>Admins</h3>
                                <v-data-table :headers="headers" :items="organization.admins" class="elevation-1">
                                    <template v-slot:item.action="{ item }">
                                        <v-icon small @click="removeAdmin(item)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </template>
                            <template>
                                <h3>Beta Features</h3>
                                <v-layout column>
                                    <v-checkbox label="Manual Engine" value="manualEngine" v-model="organization.uiFeatures"></v-checkbox>
                                </v-layout>
                            </template>
                            <h3 class="my-2" v-if="billingDetails?.currentModules?.length>0">Modules</h3>
                            <v-row dense v-if="billingDetails?.currentModules?.length>0">
                                <v-col v-for="record in addOnModules" :key="record.text" md="3" cols="12" sm="4">
                                    <v-switch v-model="billingDetails.currentModules" inset :label="record.text" :value="record.text"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <alert-message :message="msg" :messageType="msgType" />
                        <form-buttons :valid="valid" @submit="validate()" @cancel="goBack()"></form-buttons>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import AddressCard from "@/components/AddressCard.vue";
import AddressTable from "@/components/AddressTable.vue";
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import OrgAdmin from "@/components/OrgAdmin.vue";

export default {
    components: {
        FormButtons,
        AlertMessage,
        OrgAdmin,
        AddressCard,
        AddressTable
    },
    data: () => ({
        valid: true,
        admins: [],
        employees: [],
        nameRules: [(v) => !!v || "Name is required"],
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        slugRules: [(v) => !!v || "Slug is required"],
        organization: {},
        firstName: "",
        lastName: "",
        admin: {
            name: "",
            email: ""
        },
        id: 0,
        url: "",
        area: [],
        formTitle: "",
        addOnModules: [],
        headers: [{
                text: "Name",
                value: "firstName",
            },
            {
                text: "Email",
                value: "email",
            },
            {
                text: "Contact No.",
                value: "phoneNo",
            },
            {
                text: "Action",
                value: "action",
                align: "center",
            },
        ],
        address: {
            addressType: "",
            address: "",
        },
        default: {
            addressType: "",
            address: "",
        },
        billingDetails: {}
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        addAddress() {
            let addExist = this.organization.addresses.find(
                (add) => add.addressType == this.address.addressType
            );
            if (!addExist) {
                this.organization.addresses.push(this.address);
                this.address = Object.assign({}, this.default);
            } else {
                let message = `AddressType '${this.address.addressType}' is already added`;
                alert(message);
            }
        },
        removeAddress(i) {
            this.organization.addresses.splice(i, 1);
        },
        async initialize() {
            this.id = this.$route.params.id;
            this.organization = {
                orgName: "",
                description: "",
                contactNumber: "",
                orgEmail: "",
                addresses: [],
                admins: [],
            };
            if (this.id != 0) {
                this.formTitle = "Edit organization";
                try {
                    this.organization = await this.getItem(
                        appConstants.ORGANIZATIONS_API + "/" + this.id
                    );
                    this.admins = await this.getItem(
                        appConstants.EMPLOYEES_API + "/admins/" + this.id
                    );
                    for (var i = 0; i < this.admins.length; i++) {
                        for (var j = 0; j < this.admins[i].roles.length; j++) {
                            if (this.admins[i].roles[j] == "ADMIN" || this.admins[i].roles[j] == "SYSTEM_ADMIN")
                                this.employees.push(this.admins[i])
                        }
                    }
                    this.organization.admins = this.employees
                    await this.getActiveModules()
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
            } else {
                this.formTitle = "Add new organization";
            }
        },
        async getActiveModules() {
            this.billingDetails = (await this.getItem(appConstants.BILLING_ACCOUNTS_API + '/org/' + this.organization._id))[0]
            this.appDrawerItems.forEach((rec) => {
                if (!this.unrequiredModules.includes(rec.text))
                    this.addOnModules.push(rec)
            });
        },
        async updateModules() {
            this.billingDetails['modulesUpdated']=true
            await this.putItem(appConstants.BILLING_ACCOUNTS_API+'/'+this.billingDetails._id,this.billingDetails);
        },
        generateSlug() {
            this.organization.slug = this.organization.orgName
                .toLowerCase()
                .replaceAll(" ", "-");
            this.generateUrl();
        },
        generateUrl() {
            this.url =
                location.protocol + "//" + location.host + "/" + this.organization.slug;
        },
        async validate() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.id == 0) {
                        this.organization = await this.postItem(
                            appConstants.ORGANIZATIONS_API,
                            this.organization
                        );
                    } else {
                        this.organization = await this.putItem(
                            appConstants.ORGANIZATIONS_API + "/" + this.id,
                            this.organization
                        );
                    }
                    await this.updateModules()
                    this.$router.push("/organizations");
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.details;
                }
            }
        },
        removeAdmin(item) {
            this.organization.admins.splice(
                this.organization.admins.indexOf(item),
                1
            );
        }
    },
};
</script>
