<template>
<v-container>
    <center>
        <h2>Add Custom Field</h2>
    </center>
    <v-form ref="form" v-model="valid" lazy-validation>
        <alert-message :message="msg" :messageType="msgType" />
        <v-row class="mt-4">
            <v-col sm="4" offset-sm="2">
                <v-text-field label="Label" v-model="customField.label" :rules="notEmpty" required />
            </v-col>
            <v-col sm="4">
                <v-autocomplete label="Type" v-model="customField.type" :items="supportedType" item-text="label" item-value="value" :rules="notEmpty" required />
            </v-col>
        </v-row>
        <v-row class="mt-4 justify-center" offset-xs="2">
            <v-col v-if="customField.type==appConstants.CUSTOM_FIELD.TYPE.PREDEFINED" sm="6">
                <v-row class="justify-center" offset-xs="2">
                    <v-col sm="10">
                        <v-text-field v-model="fieldValue" label="Values" />
                    </v-col>
                    <v-col sm="2">
                        <action-button icon="mdi-plus" @click="saveValue()"></action-button>
                    </v-col>
                </v-row>
                <v-list dense>
                    <v-list-item-group color="primary">
                        <template v-for="(item, i) in customField.values">
                            <indline-edit-item v-model="customField.values[i]" :key="i" @remove="removeValue(i)"></indline-edit-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <v-row flat class="justify-end">
            <form-buttons :valid="valid" @submit="save()" @cancel="goBack()"></form-buttons>
        </v-row>
    </v-form>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import ActionButton from '../../components/ActionButton.vue';
import IndlineEditItem from '../../components/IndlineEditItem.vue';
export default {
    components: {
        FormButtons,
        ActionButton,
        IndlineEditItem
    },
    data() {
        return {
            appConstants: appConstants,
            selectedItem: undefined,
            customField: {
                label: '',
                type: '',
                values: []
            },
            valid: true,
            fieldValue: '',
            editIndex: -1,
            supportedType: [{
                    "label": "Text",
                    "value": appConstants.CUSTOM_FIELD.TYPE.TEXT
                },
                {
                    "label": "Numeric",
                    "value": appConstants.CUSTOM_FIELD.TYPE.NUMERIC
                },
                {
                    "label": "Predefined Values",
                    "value": appConstants.CUSTOM_FIELD.TYPE.PREDEFINED
                }
            ]
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        saveValue() {
            this.customField.values.push(this.fieldValue)
            this.fieldValue = ''
        },
        removeValue(index) {
            this.customField.values.splice(index, 1)
        },
        async initComponent() {
            this.id = this.$route.params.id
            if (this.id != 0)
                this.customField = await this.getItem(appConstants.CUSTOM_FIELD_API + "/" + this.id)
        },
        async save() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.id == 0)
                        this.customField = await this.postItem(appConstants.CUSTOM_FIELD_API, this.customField);
                    else
                        this.customField = await this.putItem(appConstants.CUSTOM_FIELD_API + "/" + this.customField._id, this.customField)
                    this.$router.go(-1);
                } catch (error) {
                    console.log(error);
                    this.handleError(error);
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
