<template>
    <v-card @click="navigateTo()" class="elevation-4" :color="widgetStyle == 'simple' ? color : ''" height="100%"> 
        <v-list-item three-line>
            <v-list-item-avatar tile size="100" :color="widgetStyle == 'box' ? color : ''">
                <v-icon class="text-h2" :style="iconColor">{{ icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <div align="center">
                    <p class="stat-text mb-1" :style="textColor">{{ stat }}</p>
                    <p class="subtitle mb-3" :style="textColor">{{ title }}</p>
                </div>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
        },
        title: {
            type: String,
        },
        stat: {
            type: [String, Number],
            default: () => 0
        },
        color: {
            type: String,
        },
        dark: {
            type: Boolean,
            default: () => false
        },
        widgetStyle:{
            type: String,
            default: () => 'simple',
            validator: prop => {
                if(prop &&  prop == 'box' || prop == 'simple') return true
                return false
            }
        },
        url:{
            type:String,
            default: () => ''
        },
        urlQuery:{
            type:String,
            default: () => ''
        }
    },
    computed: {
        textColor(){
            return this.dark ? 'color: white' : 'color: #5e5e5e'
        },
        iconColor(){
            return this.widgetStyle == 'box' ? 'color: white' : this.dark ? 'color: white' : 'color: #5e5e5e'
        },
        darkOrLight() {
            return this.dark ? 'color: white' : 'color: #5e5e5e'
        }
    },
    methods: {
        navigateTo() {
            if(this.url){
                this.$router.push({name:this.url,query:{type:this.urlQuery}})
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.stat-text {
    color: #595959;
    font-size: 2.5rem;
    font-weight: 500;
}
</style>