<template>
    <div>
        <v-row class="align-center ma-5">
            <v-col cols="12" md="6" :order="`${$vuetify.breakpoint.mdAndUp ? 2: 1}`">
                <v-row class="no-gutters align-right">
                    <v-col>
                        <span class="overline mr-5">{{recordsOnCurrentPage}} of {{totalData}} items</span>
                    </v-col>
                    <v-col>
                        <v-combobox v-model="size" @change="init(api)" :items="rowsPerPage" label="Rows per page" dense></v-combobox>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" :order="`${$vuetify.breakpoint.mdAndUp ? 1: 2}`">
                <div class="text-right" align="right">
                    <v-pagination v-model="pageNo" :length="pageCount"></v-pagination>
                </div>
            </v-col>
        </v-row>
    </div>
    </template>
    
    <script>
    import appConstants from "../utils/appConstants";
    
    export default {
        props: {
            value: {
                type: Array,
                default: Array,
            },
            api: {
                type: String,
                default: String,
            }
        },
        data() {
            return {
                page: 1,
                size: appConstants.SIZE,
                pageCount: 0,
                rowsPerPage: [5, 10, 15, 20],
                recordsOnCurrentPage: 0,
                totalData: 0,
                search:{},
                filter:{
                    data:{},
                    entity:''
                },
                firstPageCalled:false
            };
        },
        mounted() {
            this.init(this.api);
            this.checkPageHistory()
        },
        methods: {
            async init(apiCall) {
                this.page = 1;
                if(apiCall.includes('size')){
                    let apiParams = new URLSearchParams(apiCall);
                    this.size=(apiParams.get('size'));
                }
                if(apiCall)
                    this.pageChanged(apiCall)
            },
            checkPageHistory(){
                let filterHistory=this.$store.getters.filter
                let index=filterHistory.findIndex(rec=>rec.entity==this.filter?.entity)
                if(index>-1){
                    if(filterHistory[index].newFilter){
                        this.page=1
                        filterHistory[index].newFilter=false
                        this.$store.dispatch("actionUpdateFilter",filterHistory)
                        this.firstPageCalled=false
                    }
                    else
                        this.page=filterHistory[index].pageNo     
                }   
            },
            calculateRecordsOnCurrentPage(totalRecords, totalPages, currentPage){
                if(currentPage == totalPages){
                    return totalRecords
                }
                return currentPage*this.size
            },
            async pageChanged(apiCall){
                let response = await this.getItem(this.createApiString(apiCall))
                this.processResponseData(response)
            },
            updateApiCall(updatedApi){
                this.apiCall=updatedApi
                this.init(this.apiCall)
            },
            async searchApi(entity,searchObj){
                searchObj['entity']=entity
                searchObj['srField']=[...new Set(searchObj['srFields'])]
                this.search=searchObj
                this.search['flag']=true
                let response=await this.postItem(this.createApiString(appConstants.SEARCH_API),searchObj)
                this.processResponseData(response)
            },
            async filterApi(filter,entity){
                this.filter.data=filter
                this.filter.entity=entity
                let payload={filter}
                payload['entity']=entity
                if(entity=='Employee'||entity=='Customer'){
                    if(entity=='Employee')
                        payload['accountType']=appConstants.ACCOUNT.TYPE.EMPLOYEE
                    payload['entity']='Account'
                }
                if(entity=='Document')
                    payload['entity']='Media'
                try{
                    let filterHistory=this.$store.getters.filter
                    let index=filterHistory.findIndex(rec=>rec.entity==this.filter.entity)
                    if((this.page==filterHistory[index]?.pageNo && !this.firstPageCalled)){
                        if(filterHistory[index]?.newFilter)
                            this.page=1
                        if(this.page==1)
                            this.firstPageCalled=true
                        let response=await this.postItem(appConstants.FILTER_API+`?pageNo=${this.page}&size=${this.size}`,payload)
                        this.processResponseData(response)
                    }
                }catch(error){
                    this.handleError(error)
                }
            },
            createApiString(api){
                let queryString = `pageNo=${this.page}&size=${this.size}`
                return api.includes("?")?api+"&"+queryString:api+"?"+queryString
            },
            processResponseData(response){
                this.data = response.data;
                this.totalData = response.meta.totalRecords
                this.recordsOnCurrentPage = this.calculateRecordsOnCurrentPage(response.meta.totalRecords, response.meta.totalPages, response.meta.currentPage)
                this.pageCount = response.meta.totalPages;
            },
            updatePageData(newPageNo){
                let filterHistory=this.$store.getters.filter
                let index=filterHistory.findIndex(rec=>rec.entity==this.filter?.entity)
                if(index>-1)
                    filterHistory[index]['pageNo']=newPageNo
                this.$store.dispatch("actionUpdateFilter",filterHistory)
            },
        },
        computed: {
            data: {
                get() {
                    this.calculateRecordsOnCurrentPage(response.meta.totalRecords, response.meta.totalPages, response.meta.currentPage)
                    return this.value;
                },
                set(newValue) {
                    this.$emit("input", newValue);
                },
            },
            pageNo: {
                get() {
                    this.checkPageHistory()
                    this.filterApi(this.filter.data,this.filter.entity)
                    return this.page;
                },
                async set(newValue) {
                    this.page = newValue;
                    try {
                        if(this.search['flag'])
                            this.searchApi(this.search.entity,this.search)
                        else if(this.filter.entity){
                            this.firstPageCalled=false
                            this.filterApi(this.filter.data,this.filter.entity)
                            this.updatePageData(newValue)
                        }
                        else
                            this.pageChanged(this.api)
                    } catch (error) {
                        console.log(error);
                        this.handleError(error);
                        this.msg = error.response;
                    }
                },
            },
        },
    };
    </script>
    
    <style>
    </style>
    