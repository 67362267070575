<template>
    <label v-date-color:date="date" class="text-body-2">{{ date | dateFormat }}</label>
</template>

<script>
import moment from 'moment'
    export default {
        props: {
            date: {
                type: String,
                default: () => ''
            },
        },
        computed: {
            styleclass() {
                //console.log(moment(this.date).dayOfYear()+"\t"+moment().dayOfYear());
                let difference = moment(this.date).diff(moment(), 'days')
                if(difference >= 0 && difference< 3) return 'expiring-soon';
                if(difference < 0) return 'overdue';
                if(difference > 3 ) return 'future-date';
            }
        },
    }
</script>

<style lang="scss" scoped>
.overdue{
    color: red;
}
.expiring-soon{
    color: orange;
}
.future-date{
    color: green;
}
@media only screen and (max-width: 500px) {
    .overdue{
        color: red;
        font-size: smaller;
    }
    .expiring-soon{
        color: orange;
        font-size: smaller;
    }
    .future-date{
        color: green;
        font-size: smaller;
    }
}
</style>