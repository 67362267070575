<template>
    <span v-bind="$attrs">
        <v-btn class="base-inverted mx-1" :small="$vuetify.breakpoint.smAndDown" @click="showDialog=true">Add Operation</v-btn>
        <dialog-box :dialog="showDialog" @close="showDialog=false" width="1000px">
            <operation-template @success="operationCreated($event)" @cancel="showDialog=false" :account="account" :changeTemplate="true"/>
        </dialog-box>
    </span>
    </template>
    
    <script>
    import DialogBox from './DialogBox.vue';
    import OperationTemplate from '@/components/OperationTemplate.vue'
    
    export default {
        props: {
            account: {
                type: Object
            }
        },
        components: {
            DialogBox,
            OperationTemplate
        },
        data() {
            return {
                showDialog: false
            }
        },
        methods: {
            operationCreated(operation) {
                this.showDialog=false
                this.$emit('success',operation)
            }
        },
    }
    </script>
    
    <style lang="scss" scoped>
    
    </style>
    