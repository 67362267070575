<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="Projects"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addProject" tooltip="Add project"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="projects" sort-by="name" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'Project'" @apply-filters="$refs.pagination.filterApi($event, 'Project')" @clear-filters="initComponent(true)" />
        </template>
        <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission) ? true : false" @click="icon.name == 'mdi-delete'
                    ? deleteProject(item)
                    : performAction(item._id, icon)">
                </action-button>
            </template>
        </template>
        <template v-slot:item.completed="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <status :label="item.completed ? 'Completed' : 'In Process'" :block="false"></status>
        </template>
        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
            <simple-card @view="$router.push('/project/'+item._id)" @update="$router.push('/projects/'+item._id)" 
                @delete="deleteProject(item)" :title="item.name" :subtitle="item.workspace.displayName" :actions="icons">
            </simple-card>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="projects" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import Pagination from "../../components/Pagination.vue";
import SimpleCard from '../workspaces/SimpleCard.vue'
import Status from "../../components/Status.vue";
export default {
    components: {
        Pagination,
        SimpleCard,
        Status
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/project/",
                    permission: "viewProjectDetails",
                    event:'view'
                },
                {
                    name: "mdi-pencil",
                    path: "/projects/",
                    permission: "updateProject",
                    event:'update'
                },
                {
                    name: "mdi-delete",
                    permission: "deleteProject",
                    event:'delete'
                },
            ],
            headers: [{
                    text: "Project",
                    value: "name",
                },
                {
                    text: "Workspace",
                    value: "workspace.displayName"
                },
                {
                    text: "Customer",
                    value: "customer.displayName"
                },
                {
                    text: "Completed",
                    value: "completed"
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right"
                },
            ],
            projects: [],
            api: appConstants.PROJECT_API,
        };
    },
    methods: {
        add() {
            this.$router.push("/projects/0");
        },
        async deleteProject(item) {
            try {
                await this.deleteItem(
                    "Are you sure you want to delete?",
                    appConstants.PROJECT_API + "/" + item._id
                );
                this.projects.splice(this.projects.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },

        async initComponent(checkFilter) {
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.projects = await this.$refs.pagination.init(this.api)
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>
