<template>
<div>
    <v-tabs v-model="tab" center-active show-arrows align-with-title :class="$vuetify.breakpoint.xsOnly?'mt-12 pt-4':'ma-6'" :vertical="$vuetify.breakpoint.smAndUp">
        <v-tab :value="1" class="justify-start">
            <v-icon left>mdi-cog-outline</v-icon>General
        </v-tab>
        <v-tab :value="2" @click="showPlanDetails()" class="justify-start">
            <v-icon left>mdi-credit-card-outline</v-icon>Subscriptions
        </v-tab>
        <v-tab-item>
            <v-card class="mx-auto my-12 apply-font" max-width="800" :style="themeFont"><br>
                <center>
                    <v-card min-height="200" min-width="200" max-width="200">
                        <v-img min-height="200" min-width="200" :src="logoImage"></v-img>
                    </v-card>
                </center><br>
                <v-divider />
                <v-card-text class="pt-6 icon-position">
                    <v-btn class="mr-2" right top absolute fab large @click="performAction(orgDetails._id, icon)">
                        <v-icon color="green lighten-2">mdi-pencil</v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-title>Organization Details</v-card-title>
                <v-row class="ma-4">
                    <v-col>
                        <row-view label="Name" v-bind:text="orgDetails.orgName"></row-view>
                        <row-view label="Slug" v-bind:text="orgDetails.slug"></row-view>
                        <row-view label="Contact Number" v-bind:text="orgDetails.contactNumber"></row-view>
                        <row-view label="Email" v-bind:text="orgDetails.orgEmail"></row-view>
                        <row-view label="Description" v-bind:text="orgDetails.description"></row-view>
                        <row-view v-if="orgDetails.addresses && orgDetails.addresses.length!=0" label="Address" v-bind:text="orgDetails.addresses[0].address"></row-view>
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-title>Basic Settings</v-card-title><br>
                <v-row class="mx-4">
                    <v-col>
                        <row-view label="Send Task Assignment Mail" v-bind:text="orgDetails.setting.sendTaskAssignmentMail?'Yes':'No'"></row-view>
                    </v-col>
                </v-row>
                <v-row v-if="bill?.status=='UNSUBSCRIBED'">
                    <v-col class="text-right">
                        <v-btn class="mr-2 unsubscribe-btn" right @click="deleteOrg()">
                            Delete Account
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-tab-item>

        <v-tab-item>
            <center>
                <v-card flat max-width="800" class="text-justify">
                    <v-card-text>
                        <v-card flat v-if="bill?.subscriptionId && bill.status == 'SUBSCRIBED'" class="mx-auto my-12 apply-font" max-width="800" :style="themeFont">
                            <v-card-title>Subscription Details</v-card-title>
                            <v-row>
                                <v-col>
                                    <row-view label="Name" v-bind:text="bill.name"></row-view>
                                    <row-view label="Subscription Id" v-bind:text="bill.subscriptionId"></row-view>
                                    <row-view label="Plan Id" v-bind:text="bill.planId"></row-view>
                                    <row-view label="Allowed Users" v-bind:text="bill.userSize"></row-view>
                                    <row-view label="Allowed Storage">{{ bill.diskSpace }} GB</row-view>
                                    <row-view label="Modules">
                                        <v-chip v-for="mod in bill.currentModules" :key="mod" label color="light-blue" small class="ma-1">{{ mod }}</v-chip>
                                    </row-view>

                                    <row-view label="Valid Till">
                                        <show-date :date="bill.validTill"></show-date>
                                    </row-view>
                                    <row-view label="Status">{{ bill.status }}</row-view>
                                    <div class="text-right mt-4">
                                        <v-btn class="base-inverted" @click="loadCustomerPortal()">Manage Subscription</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                        <center v-else>
                            <v-alert type="error" elevation="10">No Active Subscription Found</v-alert>
                            <h2 class="my-10">Please select plan to continue</h2>
                            <stripe-plans @click="planSelected($event)"></stripe-plans>
                        </center>
                        <!-- <subscription :org="$store.getters.org" @close="showDrawer=false" @update="updateDrawer" :showDrawer="showDrawer" ref="subscription" /> -->
                    </v-card-text>
                </v-card>
            </center>
        </v-tab-item>
    </v-tabs>
</div>
</template>

<script>
import Subscription from '../../components/Subscription.vue';
import appConstants from '../../utils/appConstants';
import Status from "../../components/Status.vue";
import ShowDate from '../../components/ShowDate.vue';
import StripePlans from '../../components/StripePlans.vue';
export default {
    components: {
        Subscription,
        Status,
        ShowDate,
        StripePlans
    },
    data() {
        return {
            icon: {
                path: "/settings/",
            },
            orgDetails: JSON.parse(localStorage.getItem("orgDetails")),
            bill: {
                activePlans: []
            },
            plan: {},
            showDrawer: false,
            tab: 0,
            subscriptionValidity: ''
        };
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        initComponent() {
            (this.$route.params.tab)-1||0
            if(this.tab>0)
                this.showPlanDetails()
        },
        async showPlanDetails() {
            this.plan = {
                displayName: '',
                value: 0
            }
            this.bill = (await this.getItem(appConstants.BILLING_ACCOUNTS_API + "/org/" + this.$store.getters.org._id))[0]
            if (this.bill && this.bill.subscriptionId) {
                this.subscriptionValidity = this.bill.validTill
            }
        },
        unsubscribePlan() {
            this.$refs.subscription.unsubscribe()
            this.showPlanDetails()
        },
        updateDrawer(value) {
            this.showDrawer = value
        },
        async deleteOrg() {
            try {
                await this.deleteItem("Are you sure you want to delete your Account ?", appConstants.ORGANIZATIONS_API + "/" + this.$store.getters.org._id)
                this.logout()
            } catch (error) {
                this.handleError(error);
            }
        },
        async loadCustomerPortal() {
            let details = {
                pg_customer: this.orgDetails.pg_customer,
                slug: this.$store.getters.org.slug
            }
            let session = (await this.postItem(appConstants.STRIPE_CUSTOMER_PORTAL, details))
            window.location.href = session.url
        },
        async planSelected(plan) {
            let response = (await this.postItem(appConstants.STRIPE_CHECKOUT, this.buildPayload(plan)))
            window.location.href = response.url
        },
        buildPayload(plan){
            return {
                plan:{
                    _id: plan.planId,
                    type: plan.planType,
                    enableQty: true
                },
                customer:{
                    _id: this.orgDetails?._id,
                    slug: this.orgDetails?.slug,
                    pg_customer: this.orgDetails?.pg_customer
                }
            }
        }
    },
};
</script>

<style scoped>
.icon-position {
    position: relative;
}

.subscribe {
    color: green;
}

.unsubscribe-btn {
    color: red;
}
</style>
