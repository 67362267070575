<template>
    <v-container>
        <div>
            <v-card flat>
                <v-card-text>
                    <center>
                        <h2>Process Details</h2>
                    </center>
                    <br>
                    <div>
                        <alert-message :message="msg" :messageType="msgType" />
                        <v-row>
                            <v-col cols="12" sm="6">
                                <row-view label="Name" v-bind:text="process.name"></row-view>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <row-view label="Description" v-bind:html="process.description"></row-view>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <row-view label="Status" v-bind:text="process.status"></row-view>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <row-view label="Version" v-bind:text="process.version"></row-view>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="process.pm">
                                <row-view label="Process Manager" v-bind:text="process.pm.displayName"></row-view>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="process.turnAroundTime?.value">
                                <row-view label="Processing Time"
                                    v-bind:text="process.turnAroundTime.value + ' ' + process.turnAroundTime.unit"></row-view>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <row-view label="Timeline">
                                    <v-chip small label outlined :color="process.flexibleTimeline ? 'orange' : 'green'">{{
                                        process.flexibleTimeline ? 'Flexible' : 'Strict'
                                    }}</v-chip>
                                </row-view>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <view-meta-data :item="process"></view-meta-data>
                            </v-col>
                        </v-row>
                        <br>
                        <v-btn v-if="process.version != 1" class="mr-2 align-right" @click="getAllVersions()">
                            See all versions
                        </v-btn>
                        <br />
                        <div v-if="allVersions.length > 0">
                            <h4>Process versions</h4>
                            <v-data-table :headers="versionHeaders" :items="allVersions" class="elevation-1">
                                <template align="right" v-slot:[`item.action`]="{ item }">
                                    <export-button :show="isAllowed('exportProcesses')" :process="item"
                                        @export="exportProcesses">
                                        <template v-for="icon in icons">
                                            <action-button v-if="icon.name != 'mdi-pencil' ||
                                                currentVersion == item.version
                                                " class="mr-2" :key="icon.name" :icon="icon"
                                                :show="isAllowed(icon.permission) ? true : false" @click="
                                                    icon.name == 'mdi-delete'
                                                        ? deleteProcessVersion(item)
                                                        : icon.name == 'mdi-eye'
                                                            ? seeVersion(item)
                                                            : performAction(item._id, icon)
                                                    "></action-button>
                                        </template>
                                    </export-button>
                                </template>
                            </v-data-table>
                            <br />
                        </div>
                    </div>
                    <br />
                </v-card-text>
                <v-card-text class="text-center">
                    <h3>Diagram</h3>
                    <diagram :process="process"></diagram>
                </v-card-text>
                <v-card-text v-if="!process.isTemplate" class="justify-space-between">
                    <v-btn class="base-inverted mr-1" @click="upgradeAllOperations()">Upgrade All Operations</v-btn>
                    <span>
                        <v-btn @click="validateProcess()">
                            {{ status }}
                        </v-btn>
                        <back-button @click="goBack()" />
                    </span>
                </v-card-text>
            </v-card>
            <alert-message :message="msg" :messageType="msgType" />
        </div>
    </v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import AlertMessage from "../../components/AlertMessage.vue";
import Diagram from "../../components/Diagram.vue";
import ExportButton from "../../components/ExportButton.vue";

export default {
    components: {
        BackButton,
        AlertMessage,
        Diagram,
        ExportButton
    },
    data: () => ({
        currentVersion: 0,
        icons: [{
            name: "mdi-eye",
            path: "/process/",
            permission: "viewProcessDetails"
        },
        {
            name: "mdi-pencil",
            path: "/processes/",
            permission: "updateProcess"
        },
        {
            name: "mdi-delete",
            permission: "deleteProcess"
        },
        ],
        versionHeaders: [{
            text: "Name",
            value: "name",
        },
        {
            text: "Status",
            value: "status",
        },
        {
            text: "Version",
            value: "version",
        },
        {
            text: "Action",
            value: "action",
            align: "right",
        },
        ],
        process: {},
        id: 0,
        allVersions: [],
    }),
    computed: {
        status() {
            return this.process.status == appConstants.PROCESS.STATUS.ACTIVE ?
                "Deactivate" :
                "Activate";
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        seeVersion(item) {
            this.process = item;
        },
        async exportProcesses(exportData) {
            let processIds = exportData.map((process) => process._id);
            let processData = {
                processIds: processIds
            };
            try {
                await axios.post(
                    appConstants.PROCESSES_API + "/export",
                    processData,
                    this.commonHeader()
                );
                let msg = "Process exported successfully !"
                alert(msg)
            } catch (error) {
                this.handleError(error);
                this.msg = error
            }
        },
        async initialize() {
            this.id = this.$route.params.id;
            try {
                this.process = await this.getItem(
                    appConstants.PROCESSES_API + "/" + this.id
                );
                this.currentVersion =
                    this.currentVersion == 0 ? this.process.version : this.currentVersion;
            } catch (error) {
                this.handleError(error);
            }
        },
        async validateProcess() {
            try {
                this.process = await this.patchItem(
                    appConstants.PROCESSES_API + "/" + this.id + "/changeStatus"
                );
                alert("Process is " + this.process.status);
                this.$router.push({
                    name: "Processes"
                })
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.details;
            }
        },
        async getAllVersions() {
            try {
                this.allVersions = await this.getItem(
                    appConstants.PROCESSES_API +
                    "/" +
                    this.id +
                    "/versions?_id,name,status,version"
                );
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.details;
            }
        },
        async deleteProcessVersion(item) {
            try {
                if (
                    await this.deleteItem(
                        "Are you sure you want to delete this version of process ?",
                        appConstants.PROCESSES_API + "/" + item._id
                    )
                )
                    this.allVersions.splice(this.allVersions.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        async upgradeAllOperations() {
            try {
                const confirmed = confirm(`Please proceed with caution as this action will upgrade all the operations referring to ${this.process.name}(v${this.process.version - 1}) to ${this.process.name}(v${this.process.version}). Keep in mind that this may potentially break some operations. Please press OK to confirm`);
                if (confirmed) {
                    let result = await this.patchItem(
                        appConstants.PROCESSES_API + "/" + this.id + "/upgradeAll"
                    );
                    alert(`Successfully upgraded ${JSON.stringify(result.nModified)} operations to ${this.process.name}(v${this.process.version})`);
                    this.$router.push({
                        name: "Processes"
                    })
                }
            } catch (error) {
                this.handleError(error);
            }
        }
    },
};
</script>
