<template>
<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="computedDateFormatted" :label="title" append-icon="mdi-calendar" readonly :disabled="disabled" v-bind="attrs" v-on="on"></v-text-field>
    </template>
    <v-date-picker @change="$emit('change',computedDateFormatted)" :disabled="disabled" v-model="date" @input="menu = false"></v-date-picker>
</v-menu>
</template>

<script>
var moment = require("moment");

export default {
    props: ["title", "value", "dateFormat", "disabled"],
    data: (vm) => ({
        date: null,
        menu: false,
    }),

    computed: {
        computedDateFormatted: {
            get() {
                var updatedDate = this.formatDate(this.date ? this.date : this.value);
                this.$emit("input", updatedDate);
                return updatedDate;
            },
            set(newValue) {
                this.date = this.formatDate(newValue);
            },
        },
    },

    methods: {
        formatDate(date) {
            if (!date) {
                date = moment(new Date()).format(this.setDateFormat())
            }
            if (moment(date, this.setDateFormat()).format(this.setDateFormat()) != date) {
                date = moment(date, "YYYY/MM/DD").format(this.setDateFormat())
                return date
            }
            return date;
        },
        setDateFormat() {
            return this.dateFormat ? this.dateFormat : "DD/MM/YYYY"
        }
    },
};
</script>
