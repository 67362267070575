<template>
<v-card flat wrap>
    <br />
    <v-form ref="form" lazy-validation>
        <v-row>
            <v-col cols="12" md="4" lg="3" sm="4">
                <v-text-field class="apply-font" v-model="address.addressType" label="Address type*" :rules="notEmpty" clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="7" sm="4">
                <v-text-field class="apply-font" v-model="address.address" :rules="notEmpty" label="Address*" clearable></v-text-field>
            </v-col>
            <v-col md="2" lg="2" sm="2">
                <v-btn class="ml-2 mt-5 apply-font" :style="themeSidebar" :disabled="!this.address.addressType || !this.address.address" @click="$emit('add')">
                    Add</v-btn>
            </v-col>
        </v-row>
    </v-form>
</v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        address: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    data() {
        return {
            notEmpty: [(v) => !!v || "Cannot be Empty"],
        };
    },
};
</script>
