import { render, staticRenderFns } from "./ViewProfile.vue?vue&type=template&id=60a25868&scoped=true&"
import script from "./ViewProfile.vue?vue&type=script&lang=js&"
export * from "./ViewProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a25868",
  null
  
)

export default component.exports