<template>
<v-list-item :to="path">
    <template v-if="$vuetify.breakpoint.smAndDown">
        <v-list-item-icon>
            <v-icon v-text="icon" :style="themeSidebarFont"></v-icon>
        </v-list-item-icon>
        <v-list-item-content :style="themeSidebarFont">
            <v-list-item-title v-text="text"></v-list-item-title>
        </v-list-item-content>
    </template>
    <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-list-item-content>
            <icon-label :text="text" :icon="icon" :path="path" :tooltip="tooltip || text"></icon-label>
        </v-list-item-content>
    </template>
</v-list-item>
</template>

<script>
import IconLabel from '@/components/IconLabel.vue';
export default {
    components: {
        IconLabel,
    },
    props: ['text', 'icon', 'path', 'tooltip'],
}
</script>

<style lang="scss" scoped></style>
