<template>
  <v-flex>
    <input
      v-show="false"
      type="file"
      ref="fileUploadControl"
      @change="handleFileUpload()"
      :multiple="multiple"
    />
    <v-btn @click="$refs.fileUploadControl.click()" text height="150px" block class="upload-area">
      <v-icon class="mr-2">mdi-cloud-upload</v-icon>{{ label }}</v-btn
    >
    <v-flex class="ma-3" v-if="showChip">
      <v-chip v-for="file in files" :key="file.name">
        {{ file.name }}
      </v-chip>
    </v-flex>
  </v-flex>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => "Upload Files",
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    showChip:{
      type:Boolean,
      default:()=>true
    }
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    handleFileUpload() {
      this.files = this.$refs.fileUploadControl.files;
      this.$emit("input", this.multiple ? this.files : this.files[0]);
    },
    removeFile() {
      this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-area{
  background-color: #e1e1e1 !important;
}
</style>
