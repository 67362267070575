<template>
    <v-toolbar flat>
        <v-toolbar-title>
            <h3 v-if="$vuetify.breakpoint.smAndUp">{{ title }}</h3>
            <h3 v-else-if="$vuetify.breakpoint.xsOnly">{{ title }}</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <slot></slot>
    </v-toolbar>
    <!-- <div>
    <center>
        <h2 v-if="$vuetify.breakpoint.smAndUp" class="pt-4">{{ title }}</h2>
        <h3 v-else-if="$vuetify.breakpoint.xsOnly" class="pt-4">{{ title }}</h3>
    </center>
</div> -->
</template>

<script>
export default {
    props: ["title"]
};
</script>

<style scoped></style>
