<template>
  <div align="right">
    <v-btn class="mr-2" :loading="loading" :disabled="!valid" @click="$emit('submit')">Submit</v-btn>
    <back-button @click="$emit('cancel')" title="Cancel" />
  </div>
</template>
<script>
import BackButton from "./BackButton.vue";
export default {
  components: { BackButton },
  props: {
    valid:{
      type: Boolean,
      default: () => true
    },
    loading:{
      type:Boolean,
      default: ()=>false
    }
  },
};
</script>

