<template>
<v-container>
    <v-card flat>
        <v-card-text>
            <v-form ref="form" v-model="valid" :lazy-validation="true">
                <alert-message :message="msg" :messageType="msgType" />
                <center>
                    <h2>{{ formTitle }}</h2>
                </center>
                <v-row>
                    <row-item label="Customer Type">
                    <v-radio-group v-model="customer.type" mandatory row :disabled="id != 0">
                        <v-radio label="B2C" value="B2C"></v-radio>
                        <v-radio label="B2B" value="B2B"></v-radio>
                    </v-radio-group>
                    </row-item>
                </v-row>
                <v-card flat v-if="customer.type == 'B2C'">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3>Customer Information</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field :rules="notEmpty" label="First Name *" v-model="customer.firstName" required clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field label="Middle Name" v-model="customer.middleName" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field :rules="notEmpty" label="Last Name *" v-model="customer.lastName" clearable required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <label hidden>{{computedUserName}}</label>
                                <v-text-field @change="updateUserName=false" :rules="numRules" label="Mobile No.*" v-model="customer.phoneNo" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <v-text-field label="E-mail" v-model="customer.email" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="4">
                                <app-date v-model="customer.dob" title="DOB" dateFormat="DD/MM/YYYY"></app-date>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete v-model="customer.rm" :items="relationShipManagerData" item-text="displayName" label="Relation Ship Manager" item-value="id" return-object clearable></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Addresses :<br />
                                <address-card v-model="address" @add="addAddress()" />
                                <address-table :addresses="customer.addresses" @delete="removeAddress" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Status</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" sm="6">
                                <v-radio-group v-model="customer.active" mandatory row>
                                    <v-radio label="Active" :value="true"></v-radio>
                                    <v-radio label="Inactive" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="6" v-if="id != 0 && customer.allowLogin">
                                <v-autocomplete v-model="customer.roles" :items="roles" dense chips item-text="name" label="Roles" multiple clearable></v-autocomplete>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="4" lg="4" sm="6">
                                <v-checkbox :disabled="!customer.active" v-model="customer.allowLogin" label="Allow Login"></v-checkbox>
                            </v-col>
                        </v-row>
                        <div v-if="customer.allowLogin">
                            <v-row>
                                <v-col cols="12" md="4" lg="4" sm="3">
                                    <v-text-field :disabled="!customer.active" label="Username" v-model="customer.userName"/>
                                </v-col>
                                <v-col v-if="id == 0" cols="12" md="4" lg="4" sm="3">
                                    <v-text-field :disabled="!customer.active" type="password" :rules="passwordRule" label="Password" v-model="customer.password" required clearable></v-text-field>
                                </v-col>
                                <v-col v-if="id == 0" cols="12" md="4" lg="4" sm="3">
                                    <v-text-field :disabled="!customer.active" type="password" label="Confirm Password" v-model="confirmPassword" :rules="passwordRule" clearable></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-card-text>
    </v-card>
    <v-card flat v-if="customer.type == 'B2B'">
        <v-card-text>
            <v-row>
                <v-col>
                    <h3>Company Information</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field :rules="notEmpty" label="Company Name *" v-model="customer.displayName" required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" lg="6" sm="6">
                    <v-text-field label="Company E-mail *" v-model="customer.email"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" sm="6">
                    <v-text-field type="number" label=" Company Contact No." v-model="customer.phoneNo"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" lg="3" sm="3">
                    <v-radio-group v-model="customer.active" mandatory row>
                        <v-radio label="Active" :value="true"></v-radio>
                        <v-radio label="Inactive" :value="false"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="4" lg="3" sm="3">
                    <v-checkbox v-model="customer.allowLogin" label="Allow Login"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" lg="6" sm="4">
                    <v-autocomplete v-model="customer.rm" :items="relationShipManagerData" item-text="displayName" label="Relation Ship Manager" item-value="id" return-object clearable></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    Addresses :<br />
                    <address-card v-model="address" @add="addAddress()" />
                    <address-table :addresses="customer.addresses" @delete="removeAddress" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h3>Contact Person Information</h3>
                </v-col>
            </v-row>

            <contact-person v-model="contactPersons" :allowLogin="customer.allowLogin" :parent="id"></contact-person>

        </v-card-text>
    </v-card>
    <alert-message :message="msg" :messageType="msgType" />
    <form-buttons :valid="valid" @submit="validate()" @cancel="$emit('cancel')"></form-buttons>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants";
import AlertMessage from "@/components/AlertMessage.vue";
import FormButtons from "@/components/FormButtons.vue";
import AppDate from "@/components/Date.vue";
import SwitchButton from "@/components/SwitchButton.vue";
import ContactPerson from "@/components/ContactPerson.vue";
import AddressCard from "@/components/AddressCard.vue";
import AddressTable from "@/components/AddressTable.vue";
import RowItem from './RowItem.vue';

export default {
    props: {
        id: {
            type: [String, Number],
            default: () => 0
        },
    },
    components: {
        SwitchButton,
        AppDate,
        FormButtons,
        AlertMessage,
        ContactPerson,
        AddressCard,
        AddressTable,
        RowItem,
    },
    data: () => ({
        valid: true,
        notEmpty: [(v) => !!v || "Required"],
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRule: [
            (v) => (v && v.length > 7) || "Password should be min 8 characters.",
        ],
        customer: {
            type: appConstants.ACCOUNT.TYPE.B2C,
            contactPersons: []
        },
        formTitle: "Add New Customer",
        confirmPassword: "",
        roles: ["CUSTOMER"],
        contactPersons: [],
        address: {
            addressType: "",
            address: "",
        },
        default: {
            addressType: "",
            address: "",
        },
        relationShipManagerData: [],
        updateUserName:true
    }),
    computed: {
        computedUserName() {
            if(this.id==0)
                this.customer['userName']=this.updateUserName?this.customer.phoneNo:this.customer['userName'] 
        }
    },
    mounted() {
        this.initialize();
    },
    methods: {
        addAddress() {
            let addExist = this.customer.addresses.find(
                (add) => add.addressType == this.address.addressType
            );
            if (!addExist) {
                this.customer.addresses.push(this.address);
                this.address = Object.assign({}, this.default);
            } else {
                let message = `AddressType '${this.address.addressType}' is already added`;
                alert(message);
            }
        },
        async initialize() {
            //this.id = this.$route.params.id;
            this.customer = {
                type: appConstants.ACCOUNT.TYPE.B2C,
                displayName: appConstants.INITIAL_VALUE,
                addresses: [],
                email: appConstants.INITIAL_VALUE,
                phoneNo: appConstants.INITIAL_VALUE,
                roles: ["CUSTOMER"],
                contactPersons: [],
                rm: {},
                allowLogin:false,
                active:true
            };
            this.relationShipManagerData = (
                await this.getItem(
                    appConstants.EMPLOYEES_API + "?fields=_id,displayName,email&sort=+displayName&size=-1"
                )
            ).data;
            this.roles = (await this.getItem(appConstants.ROLES_API)).data;
            if (this.id != 0) {
                this.formTitle = "Edit Customer";
                try {
                    this.customer = await this.getItem(
                        appConstants.CUSTOMERS_API + "/" + this.id
                    );
                    let userName=await this.getItem(appConstants.CUSTOMERS_API + "/" + this.id + "/userProfile" )
                    this.customer['userName']=userName.length>0?userName[0].userName:''
                    if (this.customer.type == appConstants.ACCOUNT.TYPE.B2B) {
                        this.contactPersons = (
                            await this.getItem(appConstants.CUSTOMERS_API + `/agents?conditions=parent=${this.id}`)
                        )
                    }
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
        async validate() {
            if (this.$refs.form.validate()) {
                if (this.contactPersons.length > 0){
                    this.contactPersons[0].phoneNo=this.contactPersons[0].phoneNo||this.customer.phoneNo
                    this.customer.contactPersons = this.contactPersons;
                }
                if (this.customer.type == "B2C") {
                    this.customer.displayName =
                        this.customer.firstName + " " + this.customer.lastName;
                }
                if (this.id == 0) {
                    try {
                        this.customer.orgId = JSON.parse(
                            localStorage.getItem("orgDetails")
                        )._id;
                        this.customer = await this.postItem(
                            appConstants.CUSTOMERS_API,
                            this.customer
                        );
                        this.$emit('success', this.customer);
                    } catch (error) {
                        this.handleError(error);
                    }
                } else {
                    try {
                        this.customer = await this.putItem(
                            appConstants.CUSTOMERS_API + "/" + this.id,
                            this.customer
                        );
                        this.$emit('success', this.customer);
                    } catch (error) {
                        this.handleError(error);
                    }
                }
            }
        },
        removeAddress(i) {
            this.customer.addresses.splice(i, 1);
        }
    },
};
</script>
