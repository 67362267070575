<template>
<v-card>
    <widget-stats-count url="Documents" widgetStyle="simple" :dark="true" color="#00A9F4" icon="mdi-database"
        title="of storage used" :stat="totalStorage|| '0 kb'">
    </widget-stats-count>
</v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import WidgetStatsCount from './WidgetStatsCount.vue';
export default {
    components: {
        WidgetStatsCount
    },
    data() {
        return {
            documentStorage: 0,
            totalStorage: 0
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.documentStorage = await this.getItem(appConstants.STATS_API + "/document-storage");
            if (this.documentStorage.length > 0)
                this.totalStorage =this.calculateStorageUsed(this.documentStorage[0].totalStorage)
        },
        calculateStorageUsed(x) {
            const units = ['bytes', 'KB', 'MB', 'GB'];
            let l = 0,
                storage = parseInt(x, 10) || 0;
            while (storage >= 1024 && ++l) {
                storage = storage / 1024;
            }
            return (storage.toFixed(storage < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
