<template>
<v-container>
    <v-progress-circular v-if="loadingPlans"
      :width="3"
      color="var(--base)"
      indeterminate
    ></v-progress-circular>
    <v-btn v-if="!loadingPlans && plans.length == 0" @click="init()">Load plans</v-btn>
    <v-row align="center">
        <v-col cols="12" sm="4" v-for="plan in plans" :key="plan.id">
            <v-card min-width="300">
                <v-card-text class="text-center">
                    <center>
                        <label class="text-h6">{{plan.name}}</label><br>
                        <div v-if="plan.price.tiers_mode == 'graduated'">
                            <label class="text-h6">₹ {{plan.planTiers[0].price*plan.planTiers[0].upperLimit}}/-</label> <label class="text-caption">{{billingPeriodTitle(plan)}}</label>
                            <v-divider></v-divider>
                            <div class="tier-info">
                                <template v-for="tier in plan.planTiers">
                                    <span :key="tier.startLimit">
                                        <label v-if="tier.upperLimit">Pay ₹ {{tier.price}} per user upto {{tier.upperLimit}} users</label>
                                        <label v-else>Pay ₹ {{tier.price}} per user from {{tier.startLimit}} user</label>
                                    </span><br>
                                </template>
                            </div>
                        </div>
                        <div v-else>
                            <label class="text-h6">₹ {{plan.price.unit_amount/100}}/- <label class="text-caption">every {{plan.price.recurring.interval_count}} {{plan.price.recurring.interval}}</label></label>
                            <v-divider></v-divider>
                        </div>
                        <label>Users: <label class="text-h6">{{plan?.metadata?.users}}</label></label><br>
                        <label>Storage: <label class="text-h6">{{plan?.metadata?.storage}}</label> GB</label>
                        <v-divider></v-divider>
                        <label class="text-center text-body-2"><strong>Modules</strong></label><br>
                        <label v-for="module in plan?.metadata?.modules?.split(', ')" :key="module">{{module}}<br></label>
                        <v-btn class="mt-5 base-inverted" @click="$emit('click', {planId: plan.price.id, planType: plan.price.type, enableQty: plan.price.tiers_mode == 'graduated'})">Subscribe</v-btn>
                    </center>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import appConstants from '../utils/appConstants';

export default {
    data() {
        return {
            plans: [],
            loadingPlans: false
        }
    },
    computed: {
        planPrice() {
            if(this.plan.tiers_mode == 'graduated'){
                this.plan.metadata.users * this.plan.metadata
            }
            return this.tiers_mode 
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loadingPlans = true
            try {
                this.plans = (await this.getItem(`${appConstants.WEBSTORE_API}/plans`)).filter(rec => rec.active).sort((a, b) => parseInt(a.metadata.users) - parseInt(b.metadata.users))
            } catch (error) {
                this.handleError(error)
            }
            this.loadingPlans = false
        },
        billingPeriodTitle(plan){
            if(plan.price.recurring.interval_count > 1)
                return `every ${plan.price.recurring.interval_count} ${plan.price.recurring.interval}`
            else 
                return `every ${plan.price.recurring.interval}`
        }
    },
}
</script>

<style lang="scss" scoped>
.tier-info{
    background-color: var(--light-background);
    padding: 3%;
}
</style>
