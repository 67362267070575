<template>
<div>
    <v-row justify="center">
        <v-col cols="4" sm="10" class="text-center">
            <heading title="CustomFields"></heading>
        </v-col>
        <v-col cols="8" sm="2" class="text-right mt-4 ml-n6">
            <add-btn @click="add()" permission="addCustomField" tooltip="Add CustomField"></add-btn>
        </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="items" class="elevation-1" :search="search" :items-per-page="-1" hide-default-footer>
        <template v-slot:top>
            <filters ref="filter" :fields="headers" :entity="'CustomField'" @apply-filters="$refs.pagination.filterApi($event, 'CustomField')" @clear-filters="initComponent(true)" />
            </template>
        <template v-slot:item.values="{item}">
            <v-chip label v-for="item in item.values" :key="item" class="mr-1">{{item}}</v-chip>
        </template>
        <template align="right" v-slot:item.action="{ item }">
            <template v-for="icon in icons">
                <action-button class="mr-2" :key="icon.name" :icon="icon" :show="isAllowed(icon.permission) ? true : false" @click="
              icon.name == 'mdi-delete'
                ? deleteField(item)
                : performAction(item._id, icon)
            "></action-button>
            </template>
        </template>
    </v-data-table>
    <pagination ref="pagination" v-model="items" :api="api" />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import AlertMessage from "@/components/AlertMessage.vue";
import Pagination from "../../components/Pagination.vue";
import DialogBox from "../../components/DialogBox.vue";
import PasswordReset from "../../components/PasswordReset.vue";
export default {
    components: {
        Pagination,
        DialogBox,
        PasswordReset,
        AlertMessage
    },
    data() {
        return {
            search: "",
            icons: [{
                    name: "mdi-eye",
                    path: "/customField/",
                    permission: "viewCustomField",
                },
                {
                    name: "mdi-pencil",
                    path: "/customFields/",
                    permission: "updateCustomField",
                },
                {
                    name: "mdi-delete",
                    permission: "deleteCustomField",
                },
            ],
            headers: [{
                    text: "Label",
                    value: "label",
                },
                {
                    text: "Type",
                    value: "type",
                },
                {
                    text: "Values",
                    value: "values",
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right",
                },
            ],
            items: [],
            api: appConstants.CUSTOM_FIELD_API
        };
    },
    methods: {
        async deleteField(item) {
            try {
                if (
                    await this.deleteItem("Are you sure you want to delete this field ?", appConstants.CUSTOM_FIELD_API + "/" + item._id))
                    this.items.splice(this.items.indexOf(item), 1);
            } catch (error) {
                this.handleError(error);
            }
        },
        add() {
            this.$router.push("/customFields/0");
        },
        async initComponent(checkFilter) {
            this.items = [];
            try {
                if(checkFilter)
                    this.$refs.filter.checkHistory()
                this.items = await this.$refs.pagination.init(this.api)
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>

<style scoped></style>
