<template>
<v-container>
    <center>
        <v-card flat color="grey lighten-3" height="30%">
            <v-card-text>
                <center>
                    <h2>User Profile</h2>
                </center>
            </v-card-text>
            <v-row>
                <v-col>
                    <row-view label="Name" v-bind:text="profileDetails.name"></row-view>
                </v-col>
                <v-col>
                    <row-view label="Email" v-bind:text="profileDetails.username"></row-view>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <row-view label="Status" v-bind:text="profileDetails.active"></row-view>
                </v-col>
                <v-col>
                    <row-view label="Type" v-bind:text="profileDetails.type"></row-view>
                </v-col>
            </v-row>
            <!-- <row-view label="Permissions"></row-view>
            <v-layout row wrap class="ml-2">
                <v-flex v-for="(item, index) in profileDetails.permissions" :key="index" xs2>
                    <v-checkbox light :label="item" v-model="profileDetails.permissions[index]">
                    </v-checkbox>
                </v-flex>
            </v-layout> -->
        </v-card>
    </center>
</v-container>
</template>

<script>
export default {
    data() {
        return {
            profileDetails: JSON.parse(localStorage.getItem("profile")),
        };
    },
};
</script>

<style lang="scss" scoped></style>
