<template>
<div>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title>{{title}}</v-list-item-title>
            <v-list-item-subtitle v-if="subtitle" class="word-wrap">
                {{subtitle}}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="description">
                {{description}}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <slot name="actions">
                <div style="display:flex" v-if="$vuetify.breakpoint.smAndUp">
                    <template v-for="action in actions">
                        <v-btn :key="action.event" class="ml-1" fab x-small dark @click="$emit(action.event)" v-if="isAllowed(action.permission)">
                            <v-icon>{{action.name}}</v-icon>
                        </v-btn>
                    </template>
                </div>
                <v-menu bottom left v-else-if="actions.length>0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, i) in actions" :key="i" @click="$emit(item.event)">
                            <v-btn v-if="isAllowed(item.permission)" fab x-small :color="primaryColor">
                                <v-icon :color="secondaryColor">{{ item.name }}</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </slot>
        </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
</div>
</template>

<script>
import ActionButton from '../../components/ActionButton.vue'
export default {
    components: {
        ActionButton
    },
    props: {
        title: {
            type: String,
            default: () => ''
        },
        subtitle: {
            type: String,
            default: () => ''
        },
        description: {
            type: String,
            default: () => ''
        },
        actions: {
            type: Array,
            default: () => []
        },
    },
}
</script>

<style lang="scss" scoped>
.word-wrap {
        white-space:  break-spaces;
}
</style>
