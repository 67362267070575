<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-form ref="form" v-model="valid" :lazy-validation="true">
                    <center>
                        <h2>{{ formTitle }}</h2>
                    </center>
                    <alert-message :message="msg" :messageType="msgType" />
                    <v-card-text>
                        <v-row v-if="changeTemplate && id == 0">
                            <v-select class="ma-4" :items="processedTemplates" label="Select Template"
                                v-model="selectedTemplate" @change="updatedTemplate()" item-text="displayName"
                                return-object></v-select>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <customer-dropdown :disable="id != 0" v-model="operation.account"></customer-dropdown>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete @change="updateExpiryDate()" v-model="operation.process" :items="processes"
                                    item-text="display" item-value="_id" label="Process *" return-object :rules="notEmpty"
                                    clearable></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <label hidden>{{ suggestedTitle }}</label>
                            <v-text-field class="mx-3" v-model="operation.name" label="Operation Name*"
                                placeholder="Operation name" :rules="notEmpty" required clearable></v-text-field>
                        </v-row>
                        <custom-field v-if="selectedTemplate.fields.length > 0 && this.checkModule('CustomFields') != null"
                            :customFields="selectedTemplate.fields" />
                        <v-row>
                            <wysiwyg v-if="showDescription" v-model="operation.description"
                                placeholder="Enter details about operation" />
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="fileUpload ? '6' : '12'">
                                <v-row>
                                    <v-col :sm="fileUpload ? '12' : '6'">
                                        <v-radio-group :disabled="id != 0" v-model="operation.type" mandatory row
                                            label="Operation Type : ">
                                            <v-radio label="NORMAL" value="NORMAL" @click="normalSchedule()"></v-radio>
                                            <schedule-data v-if="operation.type == 'RECURSIVE'" :dialog="dialog"
                                                :newschedule="operation.schedule" :opId="id" @submit="assignSchedule"
                                                @editForm="editSchedule($event)" @close="dialog = false"
                                                v-model="operation.schedule"></schedule-data>
                                            <v-radio label="RECURSIVE" value="RECURSIVE" :disabled="resetFlag == true"
                                                @click="dialog = !dialog"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col v-for="(field, index) in autoCompleteFields" :key="index"
                                        :sm="fileUpload ? '12' : '6'">
                                        <v-autocomplete
                                            :disabled="field.label == 'Project' ? !modulePermission('Projects') : field.disabled"
                                            v-if="field.dataType == 'autoComplete'" v-model="operation[field.model]"
                                            :items="switchItems(field.items)" :item-text="field.item_text"
                                            :label="field.label" item-value="field.item_value" return-object clearable>
                                            <tool-tip-component v-if="field.label == 'Owner'"
                                                title="Person responsible to complete this operation. Creator will be default owner if not assigned"
                                                slot="append-outer" />
                                            <tool-tip-component v-if="field.label == 'Executor'"
                                                title="Person to whom all the related tasks will be assigned."
                                                slot="append-outer" />
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <date @change="updateExpiryDate" title="Start date *" :disabled="id != 0"
                                            v-model="operation.actionDate" :rules="notEmpty" clearable />
                                    </v-col>
                                    <v-col>
                                        <date @change="checkTAT" title="Expiry date" v-model="operation.expiryDate"
                                            :rules="notEmpty" clearable />
                                    </v-col>
                                </v-row>
                                <v-row v-if="operation.type == 'RECURSIVE'" class="mt-2">
                                    <v-col>{{ text }}</v-col>
                                    <v-col>
                                        <v-icon v-if="pencil || operation.schedule != null"
                                            @click="dialog = !dialog">mdi-pencil</v-icon>
                                    </v-col>

                                </v-row>
                            </v-col>
                            <v-col v-if="fileUpload" sm="6">
                                <v-chip v-for="(item, index) in operation.attachments" :key="index" close
                                    @click:close="removeDocument(index)">{{ item.fileName }}
                                </v-chip>
                                <file-upload v-model="uploadFile" :multiple="true"></file-upload>
                                <file-url v-model="operation.attachmentUrls" />
                            </v-col>
                            <v-col v-if="isManualEngineEnabled" cols="12" xs="6" sm="6" md="6">
                                <v-radio-group :disabled="id != 0" v-model="operation.executionType" mandatory row
                                    label="Process Execution : ">
                                    <v-radio label="AUTOMATIC" value="AUTOMATIC"></v-radio>
                                    <v-radio label="MANUAL" value="MANUAL"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <alert-message :message="msg" :messageType="msgType" />
                        <div align="right">
                            <form-buttons :valid="valid" @cancel="$emit('cancel')" @submit="validate()"
                                :loading="loading"></form-buttons>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import FileUpload from "@/components/FileUpload.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import Date from "@/components/Date.vue";
import SetTime from "@/components/Time.vue";
import ScheduleData from "@/components/ScheduleData.vue";
import FileUrl from '@/components/FileUrl'
import {
    RRule
} from "rrule";
import RowItem from '@/components/RowItem.vue';
import CustomerDropdown from "@/components/CustomerDropdown.vue";
import ToolTipComponent from '@/components/ToolTipComponent.vue';
import moment from "moment";
import CustomField from '@/components/CustomField'
export default {
    props: {
        id: {
            type: String,
            default: () => ''
        },
        account: {
            type: Object,
        },
        changeTemplate: {
            type: Boolean,
            default: () => false
        },
        selectedFields: {
            type: Array,
            default: () => []
        }
    },
    components: {
        FormButtons,
        AlertMessage,
        FileUpload,
        Date,
        SetTime,
        ScheduleData,
        RowItem,
        CustomerDropdown,
        FileUrl,
        ToolTipComponent,
        CustomField
    },
    data() {
        return {
            text: "",
            pencil: false,
            resetFlag: false,
            dialog: false,
            uploadFile: [],
            documentId: "",
            isRemoved: false,
            discardDocuments: [],
            media: {},
            valid: true,
            users: [],
            operation: {
                name: "",
                type: "NORMAL",
                executionType: "AUTOMATIC",
                schedule: null,
                executor: undefined,
                project: undefined,
                attachmentUrls: [],
                owner: this.getDefaultOwner(),
                account: this.account,
                attachments: []
            },
            notEmpty: [(v) => !!v || "Required"],
            formTitle: "",
            accounts: [],
            documentFile: {
                _id: "",
                fileName: "",
                url: ""
            },
            attachments: [],
            processes: [],
            services: [],
            processExecution: process.env.VUE_APP_PROCESS_EXECUTION,
            employees: [],
            projects: [],
            loading: false,
            selectedProcess: {},
            processList: [],
            templates: [],
            selectedTemplate: {
                fields: []
            },
            processedTemplates: [],
            fileUpload: false,
            showDescription: false,
            autoCompleteFields: []
        };
    },
    computed: {
        suggestedTitle() {
            if (this.operation.name == 0 && this.operation?.process?.displayName && this.operation?.account?.displayName) {
                this.operation.name = `${this.operation.process ? this.operation.process.displayName + ' of ' : ''}${this.operation.account ? this.operation.account.displayName : ''}`
            }
            return this.operation.name
        },
        isManualEngineEnabled() {
            return this.getUiFeatures().find(rec => rec == 'manualEngine') != null
        }
    },
    async mounted() {
        await this.initComponent();
        await this.initLists();
    },
    methods: {
        assignSchedule(str) {
            this.text = str;
            console.log("String=" + this.operation.schedule);
        },
        editSchedule(form) {
            this.pencil = true;
            this.resetFlag = true;
        },
        normalSchedule() {
            this.pencil = false;
            this.resetFlag = false;
        },
        removeDocument(index) {
            this.discardDocuments.push(this.operation.attachments[index])
            this.operation.attachments.splice(index, 1)
            this.isRemoved = true
        },
        async initLists() {
            this.processList = []
            if (this.id != 0) {
                this.processList = (await this.getItem(appConstants.PROCESSES_API + `/${this.operation.process._id}/versions?fields=name,status,parentId,version,workspace,pm,isTemplate&size=-1`))?.filter(rec => rec.version >= this.operation.process.value);
            } else {
                this.processList = (await this.getItem(appConstants.PROCESSES_API + "?fields=name,status,parentId,version,workspace,pm,isTemplate&size=-1&sort=+name")).data;
            }
            this.processesList = this.processList.filter(rec => rec = (!rec.isTemplate))
            this.processes = this.getProcessReferenceList(this.processList).map(rec => ({
                ...rec,
                display: rec.displayName + " (v" + rec.value + ")"
            }));
            let employeeList = (await this.getItem(appConstants.EMPLOYEES_API + "?fields=displayName,email&size=-1&sort=+displayName")).data
            this.employees = this.getReferenceList(employeeList)
            if (this.modulePermission('Projects')) {
                let projectList = (await this.getItem(appConstants.PROJECT_API + "?size=-1")).data
                this.projects = this.getReferenceList(projectList)
            }
        },
        async initComponent() {
            this.valid = true
            this.selectedTemplate.selectedFields = this.operationFields
            if (this.changeTemplate) {
                try {
                    this.templates = (await this.getItem(appConstants.TEMPLATE_API)).data
                    this.templates.forEach(rec => {
                        this.processedTemplates.push(this.createReference(rec._id, rec.title))
                    })
                } catch (error) {
                    console.log(error);
                }
            }
            this.operation.executionType = this.processExecution != "ALL" ? this.processExecution : this.operation.executionType;
            if (this.id != 0) {
                this.formTitle = "Edit Operation ";
                try {
                    this.operation = await this.getItem(appConstants.OPERATIONS_API + "/" + this.id);
                    if (this.operation.template)
                        this.selectedTemplate['selectedFields'] = this.templates.filter(rec => rec._id == this.operation.template._id)[0].selectedFields
                    if (this.operation.customFields)
                        this.selectedTemplate['fields'] = this.operation.customFields
                    if (this.operation.type == appConstants.OPERATION.TYPE.RECURSIVE) {
                        var rule = RRule.fromString(this.operation.schedule);
                        this.text = rule.toText();
                    }
                    this.checkTemplateFields()
                } catch (error) {
                    this.handleError(error);
                }
            } else {
                this.formTitle = "Add New Operation";
                this.checkTemplateFields()
                this.operation.expiryDate = moment().add(10, "days")
                this.operation.owner = await this.getDefaultOwner()
            }
        },
        checkTAT(date) {
            this.valid = true
            let expiryDate = moment(date, 'DD-MM-YYYY')
            let actionDate = moment(this.operation.actionDate, 'DD-MM-YYYY')
            if ((expiryDate.diff(actionDate, 'days')) < 0) {
                alert("Expiry Date cannot be samller than Action Date")
                this.updateExpiryDate()
                this.valid = false
            } else if ((expiryDate.diff(actionDate, 'days')) < this.selectedProcess.turnAroundTime?.value) {
                alert(`Processing time for selected process is ${this.selectedProcess.turnAroundTime.value} Day/s.Please select appropriate expiry date`)
                this.updateExpiryDate()
                this.valid = false
            }
        },
        async updateExpiryDate() {
            this.operation.owner = await this.getDefaultOwner()
            if (this.operation.process) {
                this.selectedProcess = await this.getItem(appConstants.PROCESSES_API + "/" + this.operation.process._id)
                if (this.selectedProcess.turnAroundTime?.value)
                    this.operation.expiryDate = moment(this.operation.actionDate, 'DD-MM-YYYY').add(this.selectedProcess.turnAroundTime.value, 'days')
            }
        },
        getReferenceList(data) {
            let referenceList = [];
            data.forEach((element) => {
                if (!element.status || element.status == "ACTIVE")
                    referenceList.push(
                        this.createReference(
                            element._id,
                            element.name || element.displayName,
                            element.version || element.description || element.phoneNo || element.email
                        )
                    );
            });
            return referenceList;
        },
        getProcessReferenceList(data) {
            let referenceList = [];
            data.forEach((element) => {
                if (!element.status || element.status == "ACTIVE")
                    referenceList.push(
                        this.createProcessReference(element)
                    );
            });
            return referenceList;
        },
        async validate() {
            this.msg = ''
            this.loading = true
            if (this.$refs.form.validate()) {
                this.checkTAT(this.operation.expiryDate)
                this.operation['customFields'] = this.selectedTemplate.fields
                this.operation['template'] = this.createReference(this.selectedTemplate._id, this.selectedTemplate.displayName)
                if (this.isRemoved) {
                    for (var j = 0; j < this.discardDocuments.length; j++) {
                        await this.deleteDocument(appConstants.MEDIA_API + "/" + this.discardDocuments[j]._id)
                    }
                }
                if (this.uploadFile.length != 0) {
                    for (var i = 0; i < this.uploadFile.length; i++) {
                        let formData = new FormData()
                        formData.append('file', this.uploadFile[i])
                        this.media = await this.postItem(
                            appConstants.MEDIA_API + "/upload",
                            formData, {
                            headers: {
                                'Content-type': 'multipart/form-data'
                            }
                        }
                        );
                        this.attachments.push({
                            _id: this.media._id,
                            fileName: this.media.originalFileName,
                            url: this.media.path
                        })
                    }
                    if (this.id == 0) {
                        this.operation.attachments = this.attachments
                    } else {
                        for (var k = 0; k < this.attachments.length; k++)
                            this.operation.attachments.push(this.attachments[k])
                    }
                }
                this.operation.actionDate = this.operation.type == "RECURSIVE" ? null : this.formatDateToYMD(this.operation.actionDate)
                this.operation.expiryDate = this.formatDateToYMD(this.operation.expiryDate)
                if (this.id == 0) {
                    try {
                        this.operation.orgId = JSON.parse(localStorage.getItem("orgDetails"))._id;
                        this.operation = await this.postItem(appConstants.OPERATIONS_API, this.operation);
                        this.$emit('success', this.operation)
                    } catch (error) {
                        this.handleError(error);
                        this.msg = error.response.data.details;
                    }
                } else {
                    try {
                        this.operation = await this.putItem(appConstants.OPERATIONS_API + "/" + this.operation._id, this.operation);
                        this.$emit('success', this.operation)
                    } catch (error) {
                        this.handleError(error);
                        this.msg = error.response.data.details;
                    }
                }
            }
            this.loading = false
        },
        async getDefaultOwner() {
            if (this.operation?.process?._id) {
                let index = this.processList.findIndex(rec => rec._id == this.operation.process._id)
                if (this.processList[index].pm)
                    return this.createReference(this.processList[index].pm._id, this.processList[index].pm.displayName, this.processList[index].pm.email)
            }
            let profile = this.getProfile()
            let userProfile = await this.getItem(appConstants.EMPLOYEES_API + "/" + profile.accountId);
            return this.createReference(userProfile._id, userProfile.displayName, userProfile.email)
        },
        updatedTemplate() {
            this.selectedTemplate = this.templates.find(rec => rec._id == this.selectedTemplate._id)
            this.checkTemplateFields()
            this.selectedTemplate.displayName = this.selectedTemplate.title
        },
        switchItems(itemName) {
            switch (itemName) {
                case 'employees':
                    return this.employees
                case 'projects':
                    return this.projects
            }
        },
        checkTemplateFields() {
            this.fileUpload = (this.selectedTemplate?.selectedFields?.findIndex(rec => rec.dataType == 'fileUpload') > -1)
            this.showDescription = (this.selectedTemplate?.selectedFields?.findIndex(rec => rec.dataType == 'wysiwyg') > -1)
            this.autoCompleteFields = this.selectedTemplate?.selectedFields?.filter(rec => rec.dataType == 'autoComplete')
        }
    }
}
</script>

<style lang="scss" scoped></style>
