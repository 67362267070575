<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
        <center>
      <h2>{{formTitle}}</h2>
        </center>
        <alert-message :message="msg" :messageType="msgType" />
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            class="mx-3"
            v-model="item.name"
            label="Workspace Name*"
            single-line
            :rules="notEmpty"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea
            class="mx-3"
            label="Description"
            rows="3"
            v-model="item.description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-autocomplete
            class="mx-3"
            v-model="item.members"
            :items="users"
            dense
            chips
            item-text="displayName"
            label="Members"
            return-object
            multiple
            v-if="!item.systemGenerated"
          ></v-autocomplete>
        </v-col>
      </v-row>
              <alert-message :message="msg" :messageType="msgType" />
      <v-card flat>
        <form-buttons
                :valid="valid"
                @submit="save()"
                @cancel="goBack()"
        ></form-buttons>
      </v-card>
    </v-container>
  </v-form>
</template>
<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
export default {
    components: {
    FormButtons,
    AlertMessage
  },
  data() {
    return {
      valid: true,
      users: [],
      item: {},
      notEmpty: [(v) => !!v || "Required"],
      formTitle: "",
    };
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    async initComponent() {
      this.id = this.$route.params.id;
      this.users=await this.getItem(
                  appConstants.EMPLOYEES_API + "?refModel="+true+'&size=-1'
                )
      if (this.id != 0) {
        this.formTitle = "Edit Workspace ";
        try {
          await this.getItem(appConstants.WORKSPACES_API + "/" + this.id).then(
            (response) => {
              this.item = response;
            }
          );
        } catch (error) {
          this.handleError(error);
        }
      } else {
        this.formTitle = "Add New Workspace";
      }
    },

    async save() {
      if (this.$refs.form.validate()) {
        if (this.id == 0) {
          try {
            this.item = await this.postItem(appConstants.WORKSPACES_API, this.item);
            this.$router.go(-1);
          } catch (error) {
            this.handleError(error);          
          }
        } else {
          try {
            await this.putItem(
              appConstants.WORKSPACES_API + "/" + this.item._id,
              this.item
            );
            this.$router.go(-1);
          } catch (error) {
            this.handleError(error);
          }
        }
      }
    },
  },
};
</script>
