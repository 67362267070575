<template>
<div>
    <v-autocomplete :filter="customFilter" :disabled="disable" v-model="account" :items="accounts" color="blue-grey lighten-2" label="Customer" item-text="displayName" item-value="_id" return-object>
        <template v-slot:no-data>
            <v-list-item>
                <v-btn block class="warning" @click="toggleDialog(true)">Add New Customer</v-btn>
            </v-list-item>
        </template>
        <template v-slot:item="{item}">
            <div style="width:100%">
            <simple-card :title="item.displayName" :subtitle="`Email: ${item.email || '-'} | Mob: ${item.phoneNo || '-'}`"></simple-card>
        </div>
        </template>
        <template v-slot:append-outer>
            <v-btn small title="Add new customer" class="base-inverted" @click="toggleDialog(true)"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
    </v-autocomplete>
    <dialog-box :dialog="addnew" @close="addnew=false" width="1000px">
        <customer-form @success="customerCreated" @cancel="toggleDialog(false)"></customer-form>
    </dialog-box>
</div>
</template>

<script>
import appConstants from '../utils/appConstants';
import SimpleCard from '../views/workspaces/SimpleCard.vue';
import DialogBox from './DialogBox.vue';
import UserCard from './UserCard.vue';
import CustomerForm from './CustomerForm.vue';

export default {
    components: {
        UserCard,
        SimpleCard,
        DialogBox,
        CustomerForm
    },
    props: {
        value: {
            type: Object,
            default: () => undefined
        },
        disable:{
            type:Boolean,
            default:()=>false
        }
    },
    data() {
        return {
            accounts: [],
            addnew: false
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        account:{
            get(){
                return this.value
            },
            set(newValue){
                if(newValue){
                    let ref = this.createReference(newValue._id, newValue.displayName, newValue.email)
                    this.$emit('input', ref)
                }
            }
        }
    },
    methods: {
        async init() {
            this.accounts = (
                await this.getItem(appConstants.CUSTOMERS_API + "?fields=displayName,email,phoneNo&size=-1&sort=+displayName")
            ).data
            this.account = this.value
        },
        async customerCreated(event) {
            this.accounts.push(event)
            this.account = event
            this.toggleDialog(false)
        },
        toggleDialog(event) {
            this.addnew = event
        },
        customFilter (item, queryText, itemText) {
            let displayName, email, phoneNo = false
            if(item.displayName) displayName = item.displayName.toLowerCase().includes(queryText.toLowerCase())
            if(item.email) email = item.email.toLowerCase().includes(queryText.toLowerCase())
            if(item.phoneNo) phoneNo = item.phoneNo.toLowerCase().includes(queryText.toLowerCase())
            return displayName || email || phoneNo
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
