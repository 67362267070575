<template>
<div>
    <v-alert dismissible color="red" colored-border border="left" elevation="3" v-if="message && Array.isArray(message)" :type="messageType">
        <ul v-for="item in message" :key="item">
            <li>{{ item }}</li>
        </ul>
    </v-alert>
    <v-alert dismissible color="red" border="left" colored-border elevation="3" v-if="message && !Array.isArray(message)" :type="messageType">
        <li>{{ message }}</li>
    </v-alert>
</div>
</template>

<script>
export default {
    props: {
        message: {
            type: [String,Array]
        },
        messageType: {
            type: String,
            default: () => 'error'
        },
    }
}
</script>
