<template>
    <v-flex style="background-color:#F8F9FA; padding:0 2%">
        <v-row dense>
            <v-col sm="3" cols="12">
                <widget-stats-count url="Tasks" :urlQuery="task.myTasks" color="#774EA7" widgetStyle="box" icon="mdi-calendar-check" title="My Tasks"
                    :stat="empTaskStats.activeTasks">
                </widget-stats-count>
            </v-col>
            <v-col sm="3" cols="12">
                <widget-stats-count url="Tasks" :urlQuery="task.myTaskDueToday" color="#F99F1A" widgetStyle="box" icon="mdi-bell-ring" title="My Tasks Due Today"
                    :stat="empTaskStats.taskDueToday">
                </widget-stats-count>
            </v-col>
            <v-col sm="3" cols="12">
                <widget-stats-count url="Tasks" :urlQuery="task.myOverdueTask" color="red" widgetStyle="box" icon="mdi-bell-alert" title="My Overdue Tasks"
                    :stat="empTaskStats.taskOverdue">
                </widget-stats-count>
            </v-col>
            <v-col sm="3" cols="12">
                <widget-stats-count color="#00A9F4" widgetStyle="box" icon="mdi-layers-triple-outline"
                    title="My Efficiency" :stat="`${myEfficiency}%`">
                </widget-stats-count>
            </v-col>
        </v-row>
        <br />
        <v-row dense v-if="this.profileDetails.level <= 2">
            <v-col sm="4" cols="12">
                <v-row dense no-gutters justify="space-around">
                    <v-col cols="12">
                        <widget-stats-count url="Operations" :urlQuery="operation.active" widgetStyle="simple" :dark="true" color="#d6dd22" icon="mdi-vector-link" title="Active Operations"
                            :stat="operationStats.activeOperations"></widget-stats-count>
                    </v-col>
                    <v-col cols="12" style="margin-top:2%;">
                        <widget-stats-count url="Operations" :urlQuery="operation.currentMonth" widgetStyle="simple" :dark="true" color="teal" icon="mdi-bookmark-plus" :title="`Operations Created in ${toMonthName(new Date().getMonth()+1)}`"
                            :stat="operationStats.operationsCreated"></widget-stats-count>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="8" cols="12">
                <widget-day-wise-task-count></widget-day-wise-task-count>
            </v-col>
        </v-row>
         <br>
        <v-row dense v-if="this.profileDetails.level <= 2">
            <v-col>
                <widget-stats-count url="Tasks" :urlQuery="task.activeTask" widgetStyle="simple" :dark="true" color="#774EA7" icon="mdi-calendar-check"
                    title="Active Tasks" :stat="orgTaskStats.activeTasks">
                </widget-stats-count>
            </v-col>
            <v-col>
                <widget-stats-count url="Tasks" :urlQuery="task.todayDueTask" widgetStyle="simple" :dark="true" color="#F99F1A" icon="mdi-bell-ring"
                    title="Tasks Due Today" :stat="orgTaskStats.taskDueToday">
                </widget-stats-count>
            </v-col>
            <v-col>
                <widget-stats-count url="Tasks" :urlQuery="task.overdueTask" widgetStyle="simple" :dark="true" color="red" icon="mdi-bell-alert"
                    title="Overdue Tasks" :stat="orgTaskStats.taskOverdue">
                </widget-stats-count>
            </v-col>
            <v-col>
                <widget-stats-count url="Tasks" :urlQuery="task.orphanTask" widgetStyle="simple" :dark="true" color="#00A9F4" icon="mdi-bulletin-board"
                    title="Orphan Tasks" :stat="orgTaskStats.orphanTasks">
                </widget-stats-count>
            </v-col>
            <!-- <v-col cols="12" md="3">
                <widget-stats-count widgetStyle="simple" :dark="true" color="#00A9F4" icon="mdi-layers-triple-outline"
                    title="Org Efficiency" stat="0%">
                </widget-stats-count>
            </v-col> -->
        </v-row>
        <br>
        <v-row v-if="this.profileDetails.level <= 2">
            <v-col sm="4">
                <TaskUpdatedWidget/>
            </v-col>
            <v-col sm="8">
                <chart-widget></chart-widget>
            </v-col>
        </v-row>
        <v-row dense v-if="this.profileDetails.level <= 2">
            <v-col sm="4">
                <v-card width="100%" height="100%" v-if="this.profileDetails.level <= 2">
                    <v-card-text>
                        <center>
                            <label :style="themeFont" class="subtitle">Process Wise Active Operations</label>
                        </center>
                        <v-data-table class="mt-2" :headers="headers" :items="processCount">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.processName }} <label style="color:gray">(v{{item.version}})</label></td>
                                    <td align="text-right">
                                        <router-link :to="`kanban?processId=${item.processId}`">{{ item.count }}
                                        </router-link>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col sm="8">
                <widget-employee-performance-stats v-if="this.profileDetails.level <= 2" />
            </v-col>
        </v-row>
        <br>
        <v-row v-if="this.profileDetails.level <= 2">
            <v-col sm="4">
                <document-storage-widget/>
            </v-col>
            <v-col sm="8">
                <WidgetEmployeeTaskCountLast3Month>
                </WidgetEmployeeTaskCountLast3Month>
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import ChartWidget from "../components/ChartWidget.vue";
import WidgetEmployeeTaskCountLast3Month from "../components/WidgetEmployeeTaskCountLast3Month.vue";
import WidgetStatsCount from "../components/WidgetStatsCount.vue";
import WidgetEmployeePerformanceStats from "../components/WidgetEmployeePerformanceStats.vue";
import WidgetOperationCount from "../components/WidgetOperationCount.vue";
import WidgetDayWiseTaskCount from "../components/WidgetDayWiseTaskCount.vue"
import TaskUpdatedWidget from '@/components/TaskUpdatedWidget.vue'
import DocumentStorageWidget from '../components/DocumentStorageWidget.vue';
export default {
    components: {
        ChartWidget,
        WidgetEmployeeTaskCountLast3Month,
        WidgetStatsCount,
        WidgetEmployeePerformanceStats,
        WidgetOperationCount,
        WidgetDayWiseTaskCount,
        TaskUpdatedWidget,
        DocumentStorageWidget
    },
    data() {
        return {
            profileDetails: JSON.parse(localStorage.getItem("profile")),
            processCount: [],
            orgTaskStats: {},
            empTaskStats: {},
            operationStats: {},
            myEfficiency: 0,
            headers: [{
                text: "Process Name",
                value: "processName",
            },
            {
                text: "Total",
                value: "count",
                align: "right"
            },
            ],
            task:{
                myTasks:appConstants.TASK.MY_TASKS,
                myTaskDueToday:appConstants.TASK.MY_TASK_DUE_TODAY,
                myOverdueTask:appConstants.TASK.MY_OVERDUE_TASK,
                activeTask:appConstants.TASK.ACTIVE_TASK,
                todayDueTask:appConstants.TASK.TODAY_DUE_TASK,
                overdueTask:appConstants.TASK.OVERDUE_TASK,
                orphanTask:appConstants.TASK.ORPHAN_TASK
            },
            operation:{
                active:appConstants.OPERATION.WIDGET.ACTIVE,
                currentMonth:appConstants.OPERATION.WIDGET.CURRENT_MONTH
            }
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.orgTaskStats = (await this.getItem(appConstants.STATS_TASKS_API));
            this.empTaskStats = (await this.getItem(appConstants.STATS_TASKS_API + "?empId=" + this.getProfile().accountId));
            this.employeePerformanceStats = await this.getItem(
                appConstants.STATS_API + "/employee-performance")
            let user = this.employeePerformanceStats.find(rec => rec._id == this.getProfile().accountId)
            if(user){
                this.myEfficiency = Math.round(((user.onTime+user.beforeTime)/user.total)*100)
            }
            this.processCount = await this.getItem(
                appConstants.STATS_API + "/process-count?orgId=" + this.store._id
            );
            this.operationStats = await this.getItem(
                appConstants.STATS_API + "/operation-stats"
            );
        },
    },
};
</script>