<template>
<v-card height="100%">
    <v-card-text>
        <h3 class="subtitle">{{ title }}</h3>
        <GChart class="px-2" type="ColumnChart" :data="chartData" :options="chartOptions" />
    </v-card-text>
</v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import {
    GChart
} from "vue-google-charts";
export default {
    components: {
        GChart,
    },
    data() {
        return {
            monthWiseCount: [],
            chartData: {},
            title: "Month Wise Operation Count",
            chartOptions: {
                height: 200,
                colors: ["#774EA7", "#F99F1A", "red", "#00A9F4"],
            },
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            this.chartData = [
                ["Month", "Count"]
            ];
            this.monthWiseCount = await this.getItem(
                appConstants.STATS_API + "/operation-count?orgId=" + this.store._id
            );
            this.monthWiseCount.forEach((rec) => {
                this.chartData.push([months[rec.month - 1] + " " + rec.year, rec.totalOperationCount]);
            });
        },
    },
};
</script>
