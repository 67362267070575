<template>
<div>
    <v-card flat>
        <v-card-text>
            <center>
                <p class="text-h4">Project Details</p>
            </center>
            <v-card flat>
                <v-card-text>
                    <v-row wrap>
                        <div class="col-xs-12 col-sm-6">
                            <row-view label="Name" v-bind:html="`<h3>${project.name}</h3>`"></row-view>
                            <row-view label="Workspace" v-bind:html="`<h4>${project.workspace.displayName}</h4>`"></row-view>
                            <row-view label="Customer" v-bind:html="`<h4>${project.customer.displayName}</h4>`"></row-view>
                            <row-view label="Status" v-bind:html="`<h4>${project.completed?'Completed':'In Process'}</h4>`"></row-view>
                            <view-meta-data class="pr-6" colClass="col-sm-12" :item="project"></view-meta-data>
                        </div>
                        <div class="col-xs-12 col-sm-4" v-if="operations.length>0">
                            <v-card flat>
                                <v-card-item-title class="title pl-3">Operations</v-card-item-title>
                                <v-list style="max-height: 500px" class="overflow-y-auto">
                                    <div v-for="item in operations" :key="item._id" class="my-1">
                                        <operation-responsive-card :operation="item"/>
                                    </div>
                                </v-list>
                            </v-card>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
    <v-card flat>
        <v-card-text align="right">
            <back-button @click="goBack()" />
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import BackButton from "../../components/BackButton.vue";
import OperationResponsiveCard from "@/components/OperationResponsiveCard"
export default {
    components: {
        BackButton,
        OperationResponsiveCard
    },
    data() {
        return {
            project: {
                name: '',
                workspace: {},
                customer: {}
            },
            id: 0,
            operations: []
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            try {
                this.project = await this.getItem(appConstants.PROJECT_API + "/" + this.id);
                this.operations = (await this.getItem(appConstants.OPERATIONS_API + `?fields=name,process,currentTask&conditions=completed=false,project._id=${this.project._id}&sort=+actionDate`)).data
            } catch (error) {
                this.handleError(error);
            }
        }
    }
};
</script>
